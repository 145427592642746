export const lightTheme = {
  backgroundColor: '#ebecf0',
  containerColor: '#f7f9fb',
  borderColor: '#4f457C',
  textColor: '#000000',
  alternativeTextColor: '#000000',
  subTextColor: '#4d515c',
  backgroundTextColor: '#ffffff',
  placeholderColor: '#f7f9fd',
  primaryColor: '#0029ff',
  secondaryColor: '#ff7a00',
  alternativeColor: '#0029ff',
  upColor: '#00e205',
  downColor: '#f90021',
  selectedColor: '#aea3ff3d',
  transparentColor: '#ffffff00',
  waveColor: '#c8d0e7',
  retroColor: '#0c1a6c',
  footerColor: '#0c1a6c',
  popupColor: '#13203D',
  primaryShadowColor: '#fafbff',
  secondaryShadowColor: '#a6abbd',
  blueRetro: '#0d0f3f',
}

export const darkTheme = {
  backgroundColor: '#13203D',
  containerColor: '#f7f9fb',
  borderColor: '#4f457C',
  textColor: '#ffffff',
  alternativeTextColor: '#f0eb54',
  subTextColor: '#ffffff',
  backgroundTextColor: '#ffffff',
  placeholderColor: '#f7f9fd',
  primaryColor: '#0029ff',
  secondaryColor: '#ff7a00',
  alternativeColor: '#ffffff',
  upColor: '#00e205',
  downColor: '#f90021',
  selectedColor: '#aea3ff3d',
  transparentColor: '#ffffff00',
  waveColor: '#c8d0e7',
  retroColor: '#0c1a6c',
  footerColor: '#000000',
  popupColor: '#13203D',
  primaryShadowColor: '#0c1629',
  secondaryShadowColor: '#0c1629',
  blueRetro: '#ffffff',
}