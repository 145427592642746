import { Category, Person } from './types'

export const persons: Person[] = [
  {
    name: 'Eric Raffin',
    slug: 'eric-raffin',
    category: Category.Founders,
    function: 'Chief Executive Officer',
    linkedin: 'https://www.linkedin.com/in/ericraffin/',
    image: '/images/team/EricRaffin.png',
    bio: 'Eric Raffin is an expert in product development from idea conception to scaling. His company has assisted over 150 startups with a success rate upwards of 90%. In an effort to be more impact-oriented, he rebranded his company four years ago as Matters. The rebranding coupled with a new company ethos led him to create new programs to support impact projects. Notably, designing an accelerator program for social entrepreneurs and creating a startup for corporate social responsibility internal programs.',
  },
  {
    name: 'Fred Arnoux',
    slug: 'fred-arnoux',
    category: Category.Founders,
    function: 'Chief Operations Officer',
    linkedin: 'https://www.linkedin.com/in/fredarnoux/',
    image: '/images/team/FredArnoux.png',
    bio: 'Frederic Arnoux is an engineer and holds a PhD in Design and Management Sciences from Mines Paristech. He specializes in disruptive innovation for sustainable development with a focus on energy. He is a lecturer and has authored many scientific papers and books on the subject matter. He co-founded Stim, a spin-off from one of the top engineering schools in Europe to support industry leaders in their radical innovation strategies and product development processes. Stim works predominantly with mobility,energy and banking sectors. ',
  },
  {
    name: 'Konstantin Grouzdev',
    slug: 'konstantin-grouzdev',
    category: Category.Founders,
    function: 'Chief Staff Officer',
    linkedin: 'https://www.linkedin.com/in/konstantin-grouzdev/',
    image: '/images/team/KonstantinGrouzdev.png',
    bio: 'Konstantin is an entrepreneur by nature. He started his career in digital consulting (Capgemini, IBM) and was soon driven to try something more daring. He took the lead in building a startup in Russia. In 2017, he discovered the blockchain ecosystem and has since been an active participant in the ecosystem. He recognized the potential of the infrastructure and joined SmartChain, a blockchain consultancy in 2019 to help design their vision. Today, he is the CEO of this fast-growing company that is focused on creating a global and positive impact by leveraging blockchain technology.',
  },
  {
    name: 'Jean-Christophe Bohin',
    slug: 'jean-christophe-bohin',
    category: Category.Founders,
    function: 'Chief Strategy Officer',
    linkedin: 'https://www.linkedin.com/in/jcbohin/',
    image: '/images/team/JeanChristopheBohin.png',
    bio: 'JC Bohin is an entrepreneur, advisor and investor in new ventures and services. He co-founded three startups across multiple sectors before launching startup and product studio Matters. He helped build a Tech4Good accelerator and actively participates as a mentor in incubators. He is also a founding partner in an entrepreneur focused smart-money fund for seed and early-ventures. His experience and convictions on strategy, pragmatism, hindsight and outputs are part of the DNA he seeks to infuse in every startup and initiative.',
  },
  {
    name: 'Adrien Hubert',
    slug: 'adrien-hubert',
    category: Category.Founders,
    function: 'Chief Financial Officer',
    linkedin: 'https://www.linkedin.com/in/adrien-hubert/',
    image: '/images/team/AdrienHubert.png',
    bio: 'Adrien Hubert has a degree in market finance and financial modeling from EDHEC. He previously worked in corporate banking on various blockchain projects before creating Smart-Chain. He has helped the company grow from a two person team to a strong, passionate team of forty blockchain enthusiasts. His experience in the real and virtual economy allows him to build ambitious yet sound token economics models on the market.',
  },
  {
    name: 'Vincent Katchavenda',
    slug: 'vincent-katchavenda',
    category: Category.Founders,
    function: 'Chief Community Officer',
    linkedin: 'https://www.linkedin.com/in/vkatchavenda/',
    image: '/images/team/VincentKatchavenda.png',
    bio: 'Vincent Katchavenda describes himself as a humanist in the cryptosphere. He believes in progressing while enabling others make progress. At the age of 22, he became a partner at Smartchain and has since devotedly accompanied numerous blockchain projects out of his passion for entrepreneurs and his confidence in blockchain technology. He has a strong conviction in leveraging blockchain to rebuild the economy, an economy based on mutual trust and care. His role in CardaShift is to connect the two sides of the economy, to build a bridge between centralized and decentralized finance, his adoptive family.',
  },
  {
    name: 'Tangui Friant',
    slug: 'tangui-friant',
    category: Category.Founders,
    function: 'Chief Partnerships Officer',
    linkedin: 'https://www.linkedin.com/in/tanguifriant/',
    image: '/images/team/TanguiFriant.png',
    bio: 'Tangui Friant is a serial entrepreneur. He has built companies like KEAKR and FAIR4B. Founder of the collective behind Cardashift, my role is to weave a network of field partners to animate together a humanistic economy that takes care of nature. He is the founder of the collective behind Cardashift. His role in Cardashift is to weave a network of partners in the field in order to bring together a humanist economy that takes care of nature. ',
  },
  {
    name: 'Jeremy Nicolas Martin',
    slug: 'jeremy-nicolas-martin',
    category: Category.Blockchain,
    function: 'Blockchain R&D Director',
    linkedin: 'https://www.linkedin.com/in/jeremy-n-martin/',
    image: '/images/team/JeremyNicolasMartin.png',
    bio: 'Jeremy Nicolas Martin is a cryptocurrency technology evangelist, a passionate researcher and leader in the French blockchain ecosystem. He develops innovative digital solutions for enterprises and has gained recognition as a pioneer in France for blockchcain project management. He envisions a cleaner, transparent and optimised global economy that enables a brighter future for everyone. ',
  },
  {
    name: 'Charles Augu',
    slug: 'charles-augu',
    category: Category.Blockchain,
    function: 'Blockchain Researcher',
    linkedin: 'https://www.linkedin.com/in/charles-augu-3148b4141/',
    image: '/images/team/CharlesAugu.png',
    bio: 'Charles Augu studied mathematics for two years before heading to ISEP engineering school to obtain a Master degree in Computer Science. During his final year of university, he studied blockchain technology and started developing DApps. He is currently pursuing a PhD at University Paris-Saclay and blockchain consultancy Smart-Chain. His first thesis topic titled " Blockchain maintenability and efficiency of protocol source code updates" focused on Cardano\'s blockchain protocol and it led him to build DApps on the Cardano eUTxO blockchain. Recently, he took part in the second cohort of the Plutus Pioneer Program by Cardano to strengthen his knowledge and develop smart contracts.',
  },
  {
    name: 'Adina Cazalens',
    slug: 'adina-cazalens',
    category: Category.Blockchain,
    function: 'Cardano Software Engineer',
    linkedin: 'https://www.linkedin.com/in/adina-cazalens-9b14151b0/',
    image: '/images/team/AdinaCazalens.png',
    bio: 'Adina Cazalens is a Junior blockchain engineer building on Cardano and works closely with Cardashift. She started functional programming while at high school and has increasingly grown passionate about Haskell. ',
  },
  {
    name: 'Aymeric Bethencourt',
    slug: 'aymeric-bethencourt',
    category: Category.Blockchain,
    function: 'Technical Architect',
    linkedin: 'https://www.linkedin.com/in/aymeric-bethencourt/',
    image: '/images/team/AymericBethencourt.png',
    bio: 'Aymeric Bethencourt is the CTO of SmartChain. He has 12+ years of experience in development including 4 years of blockchain development. He started his career as a military engineer and Officer in the French Navy followed by a PhD in Robotics. He then worked for the Directorate General of Armaments and French Alternative Energies and Atomic Energy Commission on exoskeletons. Following which, he decided to focus more on his passion for development and blockchain by joining Octo Technology (Part of Accenture) where he worked on multiple projects on Ethereum, Tezos, Corda, etc. He is a fervent advocate of agile methodology and full transparency with clients. Aymeric is very passionate about public blockchains and believes that one day everything will be tokenized.',
  },
  {
    name: 'Frank Hillard',
    slug: 'frank-hillard',
    category: Category.Blockchain,
    function: 'Technical Blockchain Engineer',
    linkedin: 'https://www.linkedin.com/in/frank-hillard-300b6b106/',
    image: '/images/team/FrankHillard.png',
    bio: 'Frank Hillard is the Tezos Tech Lead of SmartChain. He has more than 18 years of experience in computer science, including 5 years in blockchain technologies. Frank started as a R&D developer on PowerDesigner software at Sybase. He later moved on to various topics such as car generator, Bbox, FDJ terminals and financial assets. In 2016, Frank started to study and use blockchain technology as freelancer. He developed proof-of-concepts with various companies (especially on Ethereum) and the Madre project (in production) with Banque de France. Lately, Frank has been more involved in growing the Tezos community by providing tools and gamified learning platforms. He has received 5 grants from the Tezos foundation and continues to teach at an engineering school. ',
  },
  {
    name: 'Jules Bertrand',
    slug: 'jules-bertrand',
    category: Category.Blockchain,
    function: 'Tokenomics Expert & Product Owner',
    linkedin: 'https://www.linkedin.com/in/jules-bertrand-63b764193/',
    image: '/images/team/JulesBertrand.png',
    bio: "Jules Bertrand started by studying algorithms through a degree in computer science. During his training, he began to take an interest in cryptocurrencies because he quickly understand the potential of the technology. Having a technical profile, he researched and studied the different protocols and blockchains. He then decided to do a bachelor's degree in blockchain engineering and then started by setting up redundant infrastructures for blockchain nodes at Smart-Chain. Today, Jules is a product owner and takes care of project management. He produces the specifications according to the different needs, sets up developments and ensures the proper functioning of the product. Having the economy as a second passion, he is also involved in the implementation of tokenomics.",
  },
  {
    name: 'Harold Cunningham',
    slug: 'harold-cunningham',
    category: Category.Product,
    function: 'Chief Product Officer',
    linkedin: 'https://www.linkedin.com/in/harold-cunningham/',
    image: '/images/team/HaroldCunnigham.png',
    bio: "Harold's focus is to create products that tackle real problems and the deliver the best possible user experiences. Currently, he is the Head of Product at Matters Startup Studio. He has led the creation of many products for startups, SMEs and corporates. He has also built, grown and guided numerous product teams. Harold graduated in management and entrepreneurship. He has previously been an entrepreneur, product manager and product designer for startups as a freelancer.",
  },
  {
    name: 'Florentine Cuenot',
    slug: 'florentine-cuenot',
    category: Category.Product,
    function: 'Product Designer',
    linkedin: 'https://www.linkedin.com/in/florentine-cuenot-71541b110/',
    image: '/images/team/FlorentineCuenot.png',
    bio: "Florentine graduated from the University of Paris Dauphine with a Master's degree in Management of Technology and Innovation in partnership with Mines Paris-PSL and INSTN-CEA. She is now a Product Advisor at Matters and supports entrepreneurs/intrapreneurs on a wide range of topics in their product development process. She helps her clients build viable products that solve real user problems.",
  },
  {
    name: 'Louise Habib',
    slug: 'louise-habib',
    category: Category.Product,
    function: 'Product Manager',
    linkedin: 'https://www.linkedin.com/in/louise-habib-079597144/',
    image: '/images/team/LouiseHabib.png',
    bio: 'Louise Habib is a Product Manager & Designer. She attended a double-degree at ESPCI Paris - PSL and Mines Paris - PSL, two top French engineering schools, and graduated with a major in innovation and entrepreneurship. Following a rewarding first work experience in a startup, she is currently working at Matters ( a product & startup studio) as a Product Manager & Designer. Her current projects focus on helping entrepreneurs/intrapreneurs build their product with a strong focus on their impact and long-term viability.',
  },
  {
    name: 'David Level',
    slug: 'david-level',
    category: Category.Product,
    function: 'Product CTO',
    linkedin: 'https://www.linkedin.com/in/david-level-architecte/',
    image: '/images/team/DavidLevel.png',
    bio: 'David Level is an IT architect and CTO at Matters. He graduated from University Paris VI with a bachelor degree in application development. He started as a backend developper and later transitioned to product quality control at Matters. He performs quality/ performance audits on several IT systems and is a consultant to CAC40 companies in France. In 2018, he started learning about microservice architectures and decided to upgrade himaself as an IT architect. Today, he handles two main roles at Matters. As Head of Technology, he ensures all the projects are optimized and standardized in order to ensure a perfect collaboration and efficiency among the teams. He is also a projet director at Matters and advices clients with their MVP strategy.',
  },
  {
    name: 'Loic Chouharde',
    slug: 'loic-chouharde',
    category: Category.Product,
    function: 'Tech Lead',
    linkedin: 'https://www.linkedin.com/in/lo%C3%AFc-couharde-b60385b3/',
    image: '/images/team/LoicCouharde.png',
    bio: 'Loïc Couharde is a French technical leader and project manager. He gradauted from SUPINFO International University, a private higher education institution in computer science and quickly became an expert in web technologies.His career has been composed of various experiences in digital communication agencies and then at Matters Product & Startup Studio. He has10 years of experience in technical web expertise and project management. Today, he uses his expertise to support startups at any stage and to advise companies in their innovation and intrapreneurship approaches.',
  },
  {
    name: 'Bertrand Pangon',
    slug: 'bertrand-pangon',
    category: Category.Product,
    function: 'Product Ops',
    linkedin: 'https://www.linkedin.com/in/bertrandpgn',
    image: '/images/team/BertrandPangon.png',
    bio: 'Bertrand works as an IT Ops at Matters - Product and Startup studio. He graduated from ECE Paris engineering school and started his career as a cloud engineer helping build a public cloud infrastructure for a well-known French bank. As an AWS-certified builder and GCP plus Kubernetes practitioner, he helps clients at Matters build fast and reliable IT architecture for modern apps.  ',
  },
  {
    name: 'Pierre Trouve',
    slug: 'pierre-trouve',
    category: Category.Product,
    function: 'Product Coordinator',
    linkedin: 'https://www.linkedin.com/in/pierre-trouv%C3%A9-026a1827/',
    image: '/images/team/PierreTrouve.png',
    bio: 'Pierre Trouvé is an IT engineer specialized in project management and product coordination. With a double diploma from the engineering school ENSIIE and Manchester University in advanced computer science, he started working as a developer in 2011 at Matters. He quickly evolved as Tech Lead and then, as a Project Lead for teams of up to 25 people. His professional experience also includes consulting, coaching and teaching on agile methodologies.Nowadays, he mainly focus on sharing his experience as a Project Lead to improve the methodology, tooling and efficiency of teams.',
  },
  {
    name: 'Laure-Aline Bellenfant',
    slug: 'laure-aline-bellenfant',
    category: Category.Product,
    function: 'UX/UI',
    linkedin: 'https://www.linkedin.com/in/laure-aline-bellenfant-14890a130/',
    image: '/images/team/LaureAlineBellenfant.png',
    bio: 'Laure-Aline is a UX/UI designer. She obtained her bachelor degree in Project Management for Web and Mobile UX/UI. She is currently pursuing a Master specialising in UX. She is skilled in UX,UI, prototype development, graphics and has an immense appetite for learning and developing her competences. Having ventured into designing for the crypto space, she is intrigued by the learning curve and its unique design system.s',
  },
  {
    name: 'Jie Tu',
    slug: 'jie-tu',
    category: Category.Product,
    function: 'Artistic Director',
    linkedin: 'https://www.linkedin.com/in/jietu-designer/',
    image: '/images/team/JieTu.png',
    bio: 'Jie Tu graduated with a bachelor in digital strategy and gained professional experience as a UI/UX designer. Today, he specializes in interface/user experience design and assists a large portfolio of clients. He is experimental and is constantly upgrading his skils in visual creation (motion, 3D, branding...etc) Jie has successfully carried out projects for clients in finance, banking, insurance, consulting, investments and fine-dining. Being conscious of the business stakes while developing a project, he looks for ways to combine creativity and feasibility to deliver the best possible user experience.',
  },
  {
    name: 'Sebastien Sikorski',
    slug: 'sebastien-sikorski',
    category: Category.Growth,
    function: 'Head of Growth',
    linkedin: 'https://www.linkedin.com/in/sebastiensikorski/',
    image: '/images/team/SebastienSikorski.png',
    bio: 'Sebastien is a serial entrepreneur who has built multiple large-scale applications that has garnered more than millions of users. He is the co-founder of the French dating application, AdopteUnMec and has led the revamp of the RATP app, the public transportation application for Paris and its regions successfully. He has been accepted to the prestigious Y Combinator accelerator twice. His role at Cardashift has been to apply recipes from his entrepreneurial journey to develop Cardashift among the crypto community before introducing it to the general public. He also teaches at Sciences Po Paris.',
  },
  {
    name: 'Julie Martin',
    slug: 'julie-martin',
    category: Category.Growth,
    function: 'Public Relations',
    linkedin: 'https://www.linkedin.com/in/julie-martin0/',
    image: '/images/team/JulieMartin.png',
    bio: 'Julie has a Master in Entrepreneurship and Innovation. She developed a keen interest in exploring the crossroads between social and technological innovations. For the past four years, she has developed her blockchain knowledge and explored its concrete applications through a multitude of educational and personal projects. She also wrote her thesis on "Blockchain Technology and Common Good". Julie has been actively involved in multi-technology association KryptoSphere and has held many leadership positions in the association. Her strong appetite for new technologies led her back to university to start learning code. She recently entered 42 - the coding school to gain dual competencies in business and software development. In 2020, she joined Smart-Chain as a Communication Manager and at Cardashift she works on public relations development.',
  },
  {
    name: 'Romain Mesdon',
    slug: 'romain-mesdon',
    category: Category.Growth,
    function: 'Lead Community Manager',
    linkedin: 'https://www.linkedin.com/in/romain-mesdon-%F0%9F%8E%88-2373228b/',
    image: '/images/team/RomainMesdon.png',
    bio: 'Romain has always been fascinated by new technologies. He has a Masters in digital communication. He became interested in blockchain and the world of cryptocurrencies out of curiosity. After a first experience in the largest French-speaking media on blockchain, he knew he wanted to work in this sector. He immediately liked the Cardashift project. He is sensitive to environmental issues and is convinced that blockchain can help develop impactful products. His role at Cardashift is to lead the "Community Management" division. He animates social networks and creates a community around the project and is involved in content creation.',
  },
  {
    name: 'Emma Dastot',
    slug: 'emma-dastot',
    category: Category.Growth,
    function: 'Community Manager',
    linkedin: 'https://www.linkedin.com/in/emma-dastot-736757152/',
    image: '/images/team/EmmaDastot.png',
    bio: 'Emma Dastot comes from the cultural studies background. She studied art for a long time in Paris before specializing in digital art. New technologies became a passion and she leaned in on blockchain. She brings an outside view to the communication of projects. Her different passions enrich the message and help to popularize the subject. She is convinced that blockchain will extend to every field and has therefore given herself the mission of communicating around the technology. Emma is a community manager at Cardashift and she is also involved in content creation.',
  },
  {
    name: 'Marius Campos',
    slug: 'marius-campos',
    category: Category.Growth,
    function: 'Community Manager',
    linkedin: 'https://www.linkedin.com/in/marius-campos/',
    image: '/images/team/MariusCampos.png',
    bio: "Marius Campos is a Paris based blockchain Consultant and NFT expert. He has a Master degree in International Relations & Cyberspace from the Free Institute for the Study of International Relations. He led the university's crybersecurity association for 3 years as President & Vice-President responsible for the blockchain branch. Passionate about blockchain since 2017, he has been working with Smart-Chain since its genesis in 2018. His experience ranges from advisory to operational management. He has worked on several projects such as Lyzi, MyTVchain, Smartlink, Sandaga, and Smartsig. Marius is well renowned among his peers and has a certain influence on the French blockchain ecosystem as an evangelist. He’s deeply convinced that blockchain is the key to making progress towards the SDGs. Early investor on Cardano, he’s currently involved with Cardashift as a Community Manager.",
  },
  {
    name: 'Mebe Mouyiche',
    slug: 'mebe-mouyiche',
    category: Category.Growth,
    function: 'Community Manager',
    linkedin: 'https://www.linkedin.com/in/m%C3%A9b%C3%A9-mouyiche/',
    image: '/images/team/MebeMouyiche.png',
    bio: 'Following his passion for Blockchain and Traceability technology, Mébé Mouyiche has always been interested in how technology can positively impact production and consumption. During his studies in Supply Chain Management at ESCE, he developed a clever handling pallet that reduced transport drag and limited greenhouse gas emissions, which was named one of the most innovative projects in France 2020 by the National Packaging Council (CNE). Mébé continued his studies at Alyra, where he completed a Diploma in Blockchain Technology Project Management and delivered several projects, including one key project focused on a decentralised marketplace for post-crowdfunding ventures. Most recently, Mébé joined Smart-Chain, where he has focused his time on the development of the Cardashift platform and community. With Cardashift, Mébé is able to share his passion for creating an ecosystem that accelerates social and environmental initiatives.',
  },
  {
    name: 'Alexandre Dutarde',
    slug: 'alexandre-dutarde',
    category: Category.Growth,
    function: 'Community Manager',
    linkedin: 'https://www.linkedin.com/in/alexandredutarde/',
    image: '/images/team/AlexandreDutarde.png',
    bio: 'Alexandre has experimented with different roles in real estate, digital transformation and business development. He previously worked as a consultant and project manager before switching to Blockchain project management. He independently studied blockchain and is a believer in the principles of decentralization popularized by blockchain technologies. As a changemaker himself, he is convinced that blockchain is the key answer to accelerate transitions to a fairer economic system.',
  },
  {
    name: 'Trishala Suresh',
    slug: 'trishala-suresh',
    category: Category.ImpactAssessment,
    function: 'Lead Impact Assessment',
    linkedin: 'https://www.linkedin.com/in/trishalasuresh/',
    image: '/images/team/TrishalaSuresh.png',
    bio: 'Trishala is a graduate of Sciences Po Paris in International Public Management with a focus on Global Health and Global Risks. Prior to that she studied at Yale-NUS Singapore and George Washington University, USA. She gained her first experience in the financial inclusion space at AXA followed by the UN World Food Programme. Her passion for textile traceability, financial inclusion and new technologies led her to SmartChain, where she works on applying blockchain solutions to impact projects. At Cardashift, she is the Lead for Impact Assessment and works with the team on a variety of topics.',
  },
  {
    name: 'Colette Menard',
    slug: 'colette-menard',
    category: Category.ImpactAssessment,
    function: 'Lead Scientific Impact Assessment Expert',
    linkedin: 'https://www.linkedin.com/in/colettemenard/',
    image: '/images/team/ColetteMenard.png',
    bio: "Colette is Partner & Chief Scientific Officer at Stim and co-founder of Shift BU. Having joined the company at its creation in 2013, she quickly took charge of the development of methods and tools to drive innovation activities. Separating her activist commitments from her daily involvement at Stim quickly became impossible. This led to the first internal reflections on Stim's contribution to society. Today, convinced that Stim has a real role to play in resolving environmental issues faced by companies, she develops solutions through Stim's Shift BU .",
  },
  {
    name: 'Auriane Clostre',
    slug: 'auriane-clostre',
    category: Category.ImpactAssessment,
    function: 'Impact Assessment Expert',
    linkedin: 'https://www.linkedin.com/in/auriane-clostre/',
    image: '/images/team/AurianeClostre.png',
    bio: "Co-founder of Stim's Shift BU, Auriane has one obsession: divide the environmental footprint of companies. She puts at the service of her clients Stim's expertise in disruptive innovation and innovative design methods to help them reinvent themselves when faced with ecological emergencies. In her spare time, she runs climate colleges to educate on the mechanisms of climate change and photographs European animal species to raise awareness about the protection of Europe's biodiversity.",
  },
  {
    name: 'Simon Martin',
    slug: 'simon-martin',
    category: Category.ImpactAssessment,
    function: 'Impact Assessment',
    linkedin: 'https://www.linkedin.com/in/simonmartin2173/',
    image: '/images/team/SimonMartin.png',
    bio: 'Simon is an engineer and senior project manager at Stim. He graduated from Télécom ParisTech and Ecole Polytechnique. He studied informatics and then innovation design & management. Before joining Stim, Simon worked within two innovation departments (Dassault Système, Elsan) and contributed to the launch of a spin-off from the computer vision laboratory of ETH Zurich (Astrivis). Simon is passionate about innovation design and puts his expertise at the service of companies to tackle environmental issues. Since joining Stim in 2016, he has helped reinvent several products and services to drastically reduce their environmental impact, whether in the energy or the packaging industry.',
  },
  {
    name: 'Yannis Baala',
    slug: 'yannis-baala',
    category: Category.ImpactAssessment,
    function: 'Impact Assessment',
    linkedin: 'https://www.linkedin.com/in/yannis-baala-39b912138/',
    image: '/images/team/YannisBaala.png',
    bio: 'Yannis is passionate about exploring highly innovative topics. He joined Stim as an innovation consultant. Prior to that, he worked in strategy consulting with a focus on developing countries and also in the private equity industry. He graduated from HEC Paris, the leading European’s business school, with a Master’s degree in Innovation Management in partnership with Ecole Polytechnique. As part of the Cardashift team, he is in charge of designing the project owner’s journey and the selection process, including the impact assessment part.',
  },
  {
    name: 'Anais Bouchet',
    slug: 'anais-bouchet',
    category: Category.ImpactAssessment,
    function: 'Impact Assessment',
    linkedin: 'https://www.linkedin.com/in/ana%C3%AFs-bouchet-39ab28192/',
    image: '/images/team/AnaisBouchet.png',
    bio: "Anaïs joined Stim after an engineering degree from ISAE Supaéro and a Master's degree in Innovation from Ecole Polytechnique and HEC. Her curiosity and passion for learning new things led her to join the Cardadhift team. She was drawn to the impact-oriented vision of Cardashift and has contributed to building the impact assessment framework for the launchpad. She is also involved in content creation for Medium and relations with our Community.",
  },
  {
    name: 'Sanaa Moussaid',
    slug: 'sanaa-moussaid',
    category: Category.Advisors,
    function: 'Accounting Advisor',
    linkedin: 'https://www.linkedin.com/in/sanaa-moussaid/',
    image: '/images/team/SanaaMoussaid.png',
    bio: '',
  },
  {
    name: 'Nick Peden',
    slug: 'nick-peden',
    category: Category.Advisors,
    function: 'Founder - TRIPTK',
    linkedin: 'https://www.linkedin.com/in/nicolaspeden/',
    image: '/images/team/NickPeden.png',
    bio: 'Nicolas Peden is a brand builder, specializing in insight-led strategy and innovation. As a founder of TRIPTK — a NY-based brand transformation studio created in partnership with HAVAS — he has helped invent and reinvent some of the most iconic brands in tech, fashion and F&B today.He is now focused on partnering with enterprises seeking to strike a balance between commerce and social/environmental impact. Here are a few of his recent missions: Helping a leader in American Bourbon transition itself and the industry to be sustainableCreating the roadmap towards sustainable meat and cheese alternatives for multinational food brandsEnabling lifestyle brands power social change in developing markets through grassroots cultural engagement with otherwise marginalized demographics',
  },
  {
    name: 'Christian Adja',
    slug: 'christian-adja',
    category: Category.Advisors,
    function: 'Cardano Software Engineer',
    linkedin: 'https://www.linkedin.com/in/elloh-yves-christian-adja-375035113/',
    image: '/images/team/ChristianAdja.png',
    bio: '',
  },
  {
    name: 'Achille Agbe',
    slug: 'achille-agbe',
    category: Category.Advisors,
    function: 'Founder EIC Corporation',
    linkedin: 'https://www.linkedin.com/in/achilleaj/',
    image: '/images/team/AchilleAgbe.png',
    bio: '',
  },
  {
    name: 'Renaud Bettin',
    slug: 'renaud-bettin',
    category: Category.Advisors,
    function: 'Head of Climate Action at Sweep',
    linkedin: 'https://www.linkedin.com/in/renaud-bettin-98542017/',
    image: '/images/team/RenaudBettin.png',
    bio: 'Renaud is the Head of Climate Action at Sweep, a unique carbon management tool tailored for handling complexity.With a 360° vision and more than one experience, Renaud Bettin is a recognized expert in the field of corporate climate action. He spent 9 years at GERES, a pioneering NGO in developing carbon financing for energy access projects. In 2017, he joined Carbone 4, a leading carbon strategy consultancy, as head of the Carbon Neutrality Unit. There, he launched the Net Zero Initiative project, a framework for aligning companies with global carbon neutrality. Today, as Head of Climate Action at SWEEP, he participates in the creation of softwares that facilitate the management of companies’ climate actions.',
  },
  {
    name: 'Thibault Langlois-Berthelot',
    slug: 'thibault-langlois-berthelot',
    category: Category.Advisors,
    function: 'Founder of Kryptosphere & Decentralized Identity',
    linkedin: 'https://www.linkedin.com/in/blockchaineur/',
    image: '/images/team/ThibaultLangloisBerthelot.png',
    bio: '',
  },
  {
    name: 'Fabrice Heuvrard',
    slug: 'fabrice-heuvrard',
    category: Category.Advisors,
    function: 'Chartered Accountant Consultant',
    linkedin: 'https://www.linkedin.com/in/fabriceheuvrard/',
    image: '/images/team/FabriceHeuvrard.png',
    bio: '',
  },
  {
    name: 'Guillaume Capelle',
    slug: 'guillaume-capelle',
    category: Category.Ambassadors,
    function: 'Founder of SINGA',
    linkedin: 'https://www.linkedin.com/in/guillaume-capelle-entrepreneur-social/',
    image: '/images/team/GuillaumeCapelle.png',
    bio: 'Guillaume is the founder of SINGA, the first European network of incubators for refugees and immigrants. SINGA’s mission is to support professional activities created by or for refugees to promote their social inclusion and reveal their potential. Guillaume’s expertise on social entrepreneurship and migration are then internationally recognized. In 2022, he is one of the 150 leaders of the Business for Climate Convention.',
  },
  {
    name: 'Cassandre Domeneghetti',
    slug: 'cassandre-domeneghetti',
    category: Category.Ambassadors,
    function: 'Cardano Ambassador for France',
    linkedin: 'https://fr.linkedin.com/in/cassandre-domeneghetti-07a7b0133',
    image: '/images/team/CassandreDomeneghetti.png',
    bio: 'Cassandre is currently Ambassador at Cardano Foundation. Her role at Cardashift will consist of developing the bridge between Cardashift and the Cardano community, particularly the French-speaking one. Cassandre, in parallel with a brilliant career in the field of precious stones (Sotheby’s, Christie’s & Adam’s), has been diving in the blockchain ecosystem since early 2020. She then turned to Cardano, while they were in their early development phase. She was particularly active, by translating content in French notably, and introduced herself to the community and foundation. A few months later, she was appointed as Cardano Ambassador for France to continue her mission of developing the ecosystem in her native country.',
  },
  {
    name: 'Moussa Toure',
    slug: 'moussa-toure',
    category: Category.Ambassadors,
    function: 'Founder of Mayyan',
    linkedin: 'https://www.linkedin.com/in/moussatoure/en',
    image: '/images/team/MoussaToure.png',
    bio: 'Moussa is the founder of Mayyan, a social impact-oriented search engine. This partnership allows us to develop synergies in the detection of impact projects on the African continent and the monitoring of impact measurement.  Moussa is passionate about technology. He has been working for over 4 years in major digital companies, such as Microsoft. A year ago, he decided to put his skills at the service of a social engagement for Africa. He was particularly struck when he realized that several water access systems, on the continent, collapsed after the withdrawal of external aid. For him, there was no doubt about it: a long-term viable business model was needed. It would allow people to be self-sufficient and no longer depend on subsidies. From there, the idea of Mayyan was born: a search engine that turns every search into social impact on the African continent. More concretely, 30% of the generated revenues are dedicated to fund non-profit projects on key development areas (access to water and electricity, agriculture, health or education). ',
  },
  {
    name: 'Quentin Kayila Massamba',
    slug: 'quentin-kayila-massamba',
    category: Category.Ambassadors,
    function: 'Host - Podcast Cardano FR',
    linkedin: 'https://www.linkedin.com/in/quentin-kayila-massamba-133327aa/',
    image: '/images/team/QuentinKayilaMassamba.png',
    bio: 'Quentin is currently Ambassador for Quebec at Cardano Foundation and the host of the popular podcast Podcast Cardano FR. His role at Cardashift will consist of developing the bridge between Cardashift and the Cardano community, particularly the French-speaking one.  Quentin is pursuing a brilliant career in IT solutions architecture. He has notably worked on several large-scale projects for the Quebec government (Revenue Agency, ministère des Transports or RAMQ).For five years, he has been in the blockchain ecosystem, with a particular focus on Cardano. With a real commitment to educate and share on this new technology’s potential, he regularly leads conferences and university sessions. His impact goes beyond Canadian borders, thanks to his podcast “Podcast Cardano FR” and his contribution to the Telegram Group “Cardano FR”.',
  },
  {
    name: 'Guillaume Finck',
    slug: 'guillaume-finck',
    category: Category.Ambassadors,
    function: 'Head of International Development - Collège de Paris',
    linkedin: 'https://www.linkedin.com/in/guillaume-finck-28a2277a/',
    image: '/images/team/GuillaumeFlink.png',
    bio: 'Guillaume is the head of International Development for Collège de Paris. Collège de Paris is a network of 25 schools in France specialized in management, IT, sports or arts. The core mission is clear: excellence in higher education accessible to the greatest number. Today, they welcome nearly 10,000 students and 30,000 adults in continuing education.  Guillaume is in charge of spreading this ambition around education beyond French borders. The school now has about 20 partner universities with over 500 permanent professors worldwide. ',
  },
  {
    name: 'Jacques Berger',
    slug: 'jacques-berger',
    category: Category.Ambassadors,
    function: 'Managing Director - Action Tank Social&Business',
    linkedin: 'https://www.linkedin.com/in/jacques-berger-968060146/',
    image: '/images/team/JacquesBerger.png',
    bio: 'Jacques has been the managing director of Action Tank Social&Business since its launching in 2011. This incubator brings large corporations, ONGs, public actors and academics together to design, experiment and scale-up new inclusive business models aiming at fighting poverty in developed countries. Action Tank leverages the core competencies of its corporate partners (Bouygues Construction, Danone, Renault, Orange) to reduce the cost of key products and services (Housing, Food, Automobility, Telecommunication) for households in need. Some of the programs incubated at Action Tank now reach tens of thousands of customers with an established social impact. It also creates new hybrid value chains articulating large corporations, ONGs and public services. Jacques has a strong background in strategy consulting (BCG) and entrepreneurship.',
  },
  {
    name: 'Yvan Depierre',
    slug: 'yvan-depierre',
    category: Category.Advisors,
    function: 'CEO JUA, ex-CFO Apple France',
    linkedin: 'https://www.linkedin.com/in/yvandepierre/?originalSubdomain=es',
    image: '/images/team/YvanDepierre.png',
    bio: 'Yvan has a corporate background with over 25 years of exciting challenges within start-ups, SMB’s and listed companies in an international environment. More recently, he had been Apple France Finance Director for 9 years. He, then, jumped in the entrepreneurship world 5 years ago as the co-founder and co-manager of JUA, a very innovative and ambitious start-up designing and manufacturing CleanTech, BioTech and AgriTech with environmental, economical & social impacts. Along his career, Yvan has gained strong expertise in the fields of finance, governance & compliance, business development, management and company strategy definition.Yvan believes that we collectively need to act now on the ecological issues and welcome Cardashift’s ambitious initiative.',
  },
  {
    name: 'Joshua Akpan',
    slug: 'joshua-akpan',
    category: Category.Ambassadors,
    function: 'Founder - Liqwid Finance & Proof of Africa',
    linkedin: 'https://www.linkedin.com/in/joshua-akpan-59447282/',
    image: '/images/team/JoshuaAkpan.png',
    bio: 'Joshua is the co-founder of Proof of Africa, a mission-driven Cardano skate-pool. The purpose is to get Africans onboard on the web3 movement and drive the adoption towards Cardano. As part of this ambition, he launched the African Blockchain for Development, aiming to breed highly skilled blockchain engineers in Africa. Besides, Joshua is also the co-founder of Liqwid Finance, an open-source, algorithmic and non-custodial interest rate protocol built for lenders, borrowers and developers. Joshua has been part of the Cardano ecosystem for four years. He was, at first, fascinated by the technological potential of blockchain. The focus on Africa vehiculated by the Cardano community has resonated particularly in him. His main driver is to develop impact projects on Cardano that have the potential to change the quality of life for people, notably in Africa. ',
  },
  {
    name: 'Sebastien Lyon',
    slug: 'sebastien-lyon',
    category: Category.Ambassadors,
    function: 'Ex-Executive Director - UNICEF',
    linkedin: 'https://www.linkedin.com/in/sebastien-lyon-2704b182/?originalSubdomain=sn',
    image: '/images/team/SebastienLyon.png',
    bio: 'Sebastien’s career in humanitarian activities speaks for itself. After graduating from one of the top French business schools, ESSEC Paris, in 2003, Sebastien started his career in Kabul, Afghanistan at ACTED. Later, as ACTED’s CFO, he has supervised over 1,000 humanitarian support projects in more than 40 countries, launched a network of micro finance institutions (OXUS) and issued a charity bond to foster ACTED scale up. From 2012 to February 2021, Sebastien was the CFO and then the Executive Director at UNICEF France. Over 7 years in this position, Sebastien has advocated for child rights and raised funds for UNICEF international programs. He has fostered digital transformation and successfully launched Game chaingers, the first ever crypto fundraising campaign for UNICEF.Early believer of the potential of blockchain for sustainable development, Sebastien founded the UNICEF cryptofund, supported by ETH Foundation. Since 2019, this crypto fund has invested in 20+ Blockchain for Good projects. Since summer 2021, Sebastien has joined UNICEF Senegal as a Strategic Advisor to support its youth-led partner Consortium Jeunesse Senegal (CJS) to drive change for African youth through access to education, notably for developing entrepreneurship skills with a strong focus on digital.',
  },
  {
    name: 'Juana Attieh',
    slug: 'juana-attieh',
    category: Category.Ambassadors,
    function: 'Co-Founder - L₳LKUL',
    linkedin: 'https://www.linkedin.com/in/juanaattieh/?originalSubdomain=ca',
    image: '/images/team/JuanaAttieh.png',
    bio: 'Juana is the co-founder of L₳LKUL, a mission-driven Cardano stake pool. Meaning “for everyone” in Arabic, L₳lkul aims at facilitating the adoption of blockchain technology and decentralized systems in the Middle-East. With an engineering background (University of Waterloo), Juana is pursuing a brilliant career in product management, notably on BtoB SaaS solutions. Her interest in blockchain, and more specifically Cardano, comes from one video: Cardano’s goals for Africa, introduced by Charles Hoskinson. She was striked by the potential of this technology, she was seduced by the symbiosis between the vision and the technology. Today, she is a leader of influence in the Cardano community in the Middle-East, trying to bring her vision on blockchain to life. She also co-hosts podcast sessions on Cardano Hotel Podcast to highlight the ambitious projects of the ecosystem. ',
  },
]
