import * as React from 'react'
import { Button } from '../Button/Button.view'
import {
  GetClapStyled,
  GetClapTxt,
  GetClapButtonsContainer,
  GetClapButton,
  LearHowToGetClapContainer,
} from './GetClap.style'

interface GetClapViewProps {
  closeModal: () => void
}
export const GetClapView = ({ closeModal }: GetClapViewProps) => {
  return (
    <GetClapStyled>
      <GetClapTxt>Here are some examples of exchanges where you can get CLAP</GetClapTxt>
      <GetClapButtonsContainer>
        <GetClapButton
          href="https://exchange.sundaeswap.finance/#/swap?swap_from=cardano.ada&swap_to=db30c7905f598ed0154de14f970de0f61f0cb3943ed82c891968480a.434c4150"
          target="_blank"
          onClick={() => closeModal()}
        >
          <img src="/images/sundaeswap.png" alt="" />
        </GetClapButton>
        <GetClapButton
          href="https://app.minswap.org/fr-FR/liquidity?currencySymbolA=&tokenNameA=&currencySymbolB=db30c7905f598ed0154de14f970de0f61f0cb3943ed82c891968480a&tokenNameB=434c4150"
          target="_blank"
          onClick={() => closeModal()}
        >
          <img src="/images/minswap.png" alt="" />
        </GetClapButton>
      </GetClapButtonsContainer>
      <LearHowToGetClapContainer>
        <a href="https://form.typeform.com/to/GlWpbpdc" target="_blank" rel="noreferrer">
          <Button appearance="secondary" withShadow={false}>
            Learn how to get CLAP
          </Button>
        </a>
      </LearHowToGetClapContainer>
    </GetClapStyled>
  )
}
