import { switchMode } from 'app/App.actions'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'reducers'

import { MenuView } from './Menu.view'

export const Menu = () => {
  const isDarkMode = useSelector((state: State) => state.darkMode)
  const dispatch = useDispatch()

  const switchModeCb = (type: string) => {
    dispatch(switchMode(type))
  }
  return <MenuView switchMode={switchModeCb} isDarkMode={isDarkMode} />
}
