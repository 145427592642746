import styled from 'styled-components/macro'

export const CompaniesStyled = styled.div`
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 56px;
  margin-bottom: 160px;
  @media (max-width: 1023px) {
    padding: 0 20px;
    margin-bottom: 0px;
  }
`

export const CompaniesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const CompaniesLeft = styled.div`
  width: 44%;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 0;
  }
`
export const CompaniesRight = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-between;
  right: 0;
  height: fit-content;
  @media (max-width: 1023px) {
    display: none;
  }

  position: relative;
`
export const CompaniesCol = styled.div`
  width: 200px;
  > div {
    &:not(.horizontal) {
      &::before,
      &::after {
        background: none;
      }
    }
  }
`

export const CompaniesColFadeTop = styled.div`
  position: absolute;
  width: 100%;
  height: 100px;
  z-index: 1;
  top: -1px;
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.backgroundColor} 0%,
    ${({ theme }) => theme.backgroundColor}80 80%,
    rgba(255, 255, 255, 0) 100%
  );
`

export const CompaniesColFadeBottom = styled.div`
  position: absolute;
  width: 100%;
  height: 100px;
  z-index: 1;
  bottom: -1px;
  background: linear-gradient(
    0deg,
    ${({ theme }) => theme.backgroundColor} 0%,
    ${({ theme }) => theme.backgroundColor}80 50%,
    rgba(255, 255, 255, 0) 100%
  );
`
export const CompaniesPhotoContainer = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor};
  background-blend-mode: soft-light, normal;
  border: 2px solid rgba(255, 255, 255, 0.16);
  box-sizing: border-box;
  box-shadow: inset -5px -5px 10px ${({ theme }) => theme.primaryShadowColor},
    inset 5px 5px 10px ${({ theme }) => theme.secondaryShadowColor};
  border-radius: 40px;
  padding: 15px;
  margin-bottom: 24px;
`
export const CompaniesPhoto = styled.img`
  width: 100%;
  border-radius: 24px;
`

export const CompaniesImage = styled.img`
  width: 100%;
`

export const CompaniesMoreTitle = styled.div`
  margin-bottom: 80px;
`

export const CompaniesMoreCard = styled.div`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const CompaniesMoreButton = styled.div`
  margin-top: 40px;
  display: flex;
`
