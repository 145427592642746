import { Blog } from 'app/App.components/Blog/Blog.view'
import { Button } from 'app/App.components/Button/Button.view'
import { Footer } from 'app/App.components/Footer/Footer.view'
import { JoinCommunity } from 'app/App.components/JoinCommunity/JoinCommunity.view'
import { Title } from 'app/App.components/Title/Title.view'
import { useSelector } from 'react-redux'
import { State } from 'reducers'

// prettier-ignore
import { LaunchpadButtons, LaunchpadCenteredParagraph, LaunchpadContainer, LaunchpadCriteria, LaunchpadCriteriaPoint, LaunchpadCriterias, LaunchpadCriteriasIllustration, LaunchpadCriteriaTitle, LaunchpadeStageCardDownbar, LaunchpadIllustrationImage, LaunchpadIllustrationImageResponsive, LaunchpadIllustrationLeft, LaunchpadIllustrationRight, LaunchpadIllustrationSection, LaunchpadImpact, LaunchpadImpactButton, LaunchpadImpactDesktopImage, LaunchpadImpactMobileImage, LaunchpadLeftWave, LaunchpadProcess, LaunchpadStageCard, LaunchpadStageCardBlock, LaunchpadStageCardBlocksContainer, LaunchpadStageCardBlockSmallSubtitle, LaunchpadStageCardBlockSmallTitle, LaunchpadStageCardBlockSubtitle, LaunchpadStageCardBlockTitle, LaunchpadStageCardContainerLast, LaunchpadStageFundraisingResponsive, LaunchpadStagesContainer, LaunchpadStageTitle, LaunchpadStyled } from './Launchpad.style'

export const Launchpad = () => {
  const isDarkMode = useSelector((state: State) => state.darkMode)
  let blogArticles = [
    {
      title: 'Buy and store your first ADA!',
      link: 'https://medium.com/cardano-journal/buy-and-store-your-first-ada-cd580ef76c55',
      image: 'https://miro.medium.com/max/1400/0*YLZJhqhEZ2mA_3Er',
      description:
        'Cardashift will exclusively accept ADA for its upcoming ICO. It’s a proof of our commitment to the Cardano ecosystem. This tutorial aims at enlarging the ADA holders community with new change makers coming from other horizons.',
    },
    {
      title: 'CLAP: A Utility token to power the Cardashift ecosystem',
      link: 'https://cardashift.medium.com/clap-a-utility-token-to-power-the-cardashift-ecosystem-7bf3fb862cc',
      image: 'https://miro.medium.com/max/1400/0*JmZPCqD0jUyOdTrp',
      description:
        'CLAP utility tokens are at the core of the Cardashift product ecosystem. They give it the fuel to survive and thrive.',
    },
    {
      title: 'The Cardashift voting system: Rules, rewards, and game theory',
      link: 'https://cardashift.medium.com/the-cardashift-voting-system-rules-rewards-and-game-theory-b5cae57c813c',
      image: 'https://miro.medium.com/max/1400/1*urDkbZwLkEyQbLP0LhI4sg.jpeg',
      description:
        'Our goal is clear: to move towards decentralized governance. That’s why, at the outset, we have set up a voting system to include our community in the project selection process.',
    },
    {
      title: 'Boosting early-stage impact investments: the role of a decentralized ecosystem',
      link: 'https://medium.com/cardano-journal/boosting-early-stage-impact-investments-the-role-of-a-decentralized-ecosystem-4c98736fd221',
      image: 'https://miro.medium.com/max/1400/1*nVKPf9mhWY2-1LzfcVU6MA.png',
      description:
        'We aim to build an impact-oriented and decentralized ecosystem for sourcing, funding and accelerating projects that will solve important real-world issues.',
    },
  ]
  return (
    <LaunchpadContainer>
      <LaunchpadStyled>
        <LaunchpadIllustrationSection>
          <LaunchpadIllustrationLeft>
            <Title>Cardashift Launchpad</Title>
            <img src="/images/cardano-complete.svg" alt="Cardano" />
          </LaunchpadIllustrationLeft>
          <LaunchpadIllustrationRight>
            <LaunchpadIllustrationImage src="/images/launchpad.png" alt="" />
            <LaunchpadIllustrationImageResponsive src="/images/launchpad_responsive.png" alt="" />
          </LaunchpadIllustrationRight>
        </LaunchpadIllustrationSection>
        <LaunchpadCenteredParagraph>
          Cardashift aims to create the first launchpad for impactful projects. We offer our community to invest in
          promising ventures, backed by a best-in-class startup program to transform them into impactful businesses.
          <br />
          <br />
          The launchpad is built upon the Cardano blockchain and powered by the CLAP token
        </LaunchpadCenteredParagraph>
        <LaunchpadButtons>
          <a href="https://launchpad.cardashift.com/" target="_blank" rel="noreferrer">
            <Button appearance="secondary">Launch App</Button>
          </a>
          <a href="https://form.typeform.com/to/xHPIDHzr" target="_blank" rel="noreferrer">
            <Button appearance="tertiary" withIcon={false}>
              Submit your project
            </Button>
          </a>
        </LaunchpadButtons>
      </LaunchpadStyled>
      <LaunchpadCriteriasIllustration>
        <LaunchpadLeftWave isDarkMode={isDarkMode} />
        <LaunchpadStyled>
          <LaunchpadCriterias>
            <div>
              <img src={isDarkMode ? '/images/criterias-dark.png' : '/images/criterias-light.png'} alt="" />
            </div>
            <div>
              <LaunchpadCriteriaTitle>
                <Title label="Cardashift investment thesis">3 criterias to match</Title>
              </LaunchpadCriteriaTitle>
              <LaunchpadCriteria>
                <LaunchpadCriteriaPoint style={{ backgroundColor: '#ff5456' }} />
                Tangible and positive impact
              </LaunchpadCriteria>
              <LaunchpadCriteria>
                <LaunchpadCriteriaPoint style={{ backgroundColor: '#38d7ae' }} />
                Early stage
              </LaunchpadCriteria>
              <LaunchpadCriteria>
                <LaunchpadCriteriaPoint style={{ backgroundColor: '#ffa11e' }} />
                Demonstrable high business potential
              </LaunchpadCriteria>
            </div>
          </LaunchpadCriterias>
          <LaunchpadImpact>
            <Title label="Cardashift impact thesis">Strong focus on tangible and positive impact projects</Title>
            <p>
              We prioritize themes linked to vital human needs using well-known impact taxonomies built by the Global
              Impact Investing (GIIN) and the Sustainability Accounting Standards Board (SASB).
              <br />
              <br />7 themes, 7 goals, and 7 emergencies that Cardashift wants to address.
            </p>
            <LaunchpadImpactDesktopImage
              src={isDarkMode ? '/images/impact-desktop-dark.png' : '/images/impact-desktop-light.png'}
              alt=""
            />
            <LaunchpadImpactMobileImage
              src={isDarkMode ? '/images/impact-mobile-dark.png' : '/images/impact-mobile-light.png'}
              alt=""
            />
            <LaunchpadImpactButton>
              <a href="https://form.typeform.com/to/xHPIDHzr" target="_blank" rel="noreferrer">
                <Button appearance="primary">Submit your application</Button>
              </a>
            </LaunchpadImpactButton>
          </LaunchpadImpact>
          <LaunchpadProcess>
            <Title label="Cardashift support process">From selection to acceleration</Title>
            <p>We create the environment to meticulously assess, derisk and track impact ventures</p>
            <img src={isDarkMode ? '/images/bar-dark.png' : '/images/bar-light.png'} alt="" />
          </LaunchpadProcess>
          <LaunchpadStagesContainer>
            <LaunchpadStageCard isDarkMode={isDarkMode}>
              <LaunchpadStageTitle>STAGE 1 - SELECTION PROCESS</LaunchpadStageTitle>
              <LaunchpadStageCardBlocksContainer>
                <LaunchpadStageCardBlock height={61} isDarkMode={isDarkMode}>
                  <LaunchpadStageCardBlockTitle>Screening</LaunchpadStageCardBlockTitle>
                  <LaunchpadStageCardBlockSubtitle>
                    To keep only best potential projects
                  </LaunchpadStageCardBlockSubtitle>
                </LaunchpadStageCardBlock>
                <LaunchpadStageCardBlock height={61} isDarkMode={isDarkMode}>
                  <LaunchpadStageCardBlockTitle>Cardano Community</LaunchpadStageCardBlockTitle>
                  <LaunchpadStageCardBlockSubtitle>Involved in the selection process</LaunchpadStageCardBlockSubtitle>
                </LaunchpadStageCardBlock>
                <LaunchpadStageCardBlock height={61} isDarkMode={isDarkMode}>
                  <LaunchpadStageCardBlockTitle>Due diligence</LaunchpadStageCardBlockTitle>
                </LaunchpadStageCardBlock>
                <LaunchpadeStageCardDownbar color={'blue'}></LaunchpadeStageCardDownbar>
              </LaunchpadStageCardBlocksContainer>
            </LaunchpadStageCard>
            <LaunchpadStageCard isDarkMode={isDarkMode}>
              <LaunchpadStageTitle>STAGE 2 - PRE-FUNDING SUPPORT</LaunchpadStageTitle>
              <LaunchpadStageCardBlocksContainer>
                <LaunchpadStageCardBlock height={49} isDarkMode={isDarkMode}>
                  <LaunchpadStageCardBlockTitle>Business & Service Design</LaunchpadStageCardBlockTitle>
                </LaunchpadStageCardBlock>
                <LaunchpadStageCardBlock height={49} isDarkMode={isDarkMode}>
                  <LaunchpadStageCardBlockTitle>Blockchain & Tokenomics</LaunchpadStageCardBlockTitle>
                </LaunchpadStageCardBlock>
                <LaunchpadStageCardBlock height={49} isDarkMode={isDarkMode}>
                  <LaunchpadStageCardBlockTitle>Communication</LaunchpadStageCardBlockTitle>
                </LaunchpadStageCardBlock>
                <LaunchpadStageCardBlock height={49} isDarkMode={isDarkMode}>
                  <LaunchpadStageCardBlockTitle>Impact</LaunchpadStageCardBlockTitle>
                </LaunchpadStageCardBlock>
                <LaunchpadStageCardBlock height={49} isDarkMode={isDarkMode}>
                  <LaunchpadStageCardBlockTitle>Governance & organization</LaunchpadStageCardBlockTitle>
                </LaunchpadStageCardBlock>
                <LaunchpadeStageCardDownbar color={'orange'}></LaunchpadeStageCardDownbar>
              </LaunchpadStageCardBlocksContainer>
            </LaunchpadStageCard>
            <LaunchpadStageFundraisingResponsive>FundRaising</LaunchpadStageFundraisingResponsive>
            <LaunchpadStageCard isDarkMode={isDarkMode}>
              <LaunchpadStageTitle>STAGE 3 - ACCELERATION SUPPORT</LaunchpadStageTitle>
              <LaunchpadStageCardContainerLast marginTop={70}>
                <LaunchpadStageCardBlock height={60} isDarkMode={isDarkMode}>
                  <LaunchpadStageCardBlockSmallTitle>Business roadmap</LaunchpadStageCardBlockSmallTitle>
                  <LaunchpadStageCardBlockSmallSubtitle>
                    & Go To Market Strategy follow up
                  </LaunchpadStageCardBlockSmallSubtitle>
                </LaunchpadStageCardBlock>
                <LaunchpadStageCardBlock height={60} isDarkMode={isDarkMode}>
                  <LaunchpadStageCardBlockSmallSubtitle>Assist</LaunchpadStageCardBlockSmallSubtitle>
                  <LaunchpadStageCardBlockSmallTitle>Impact</LaunchpadStageCardBlockSmallTitle>
                  <LaunchpadStageCardBlockSmallSubtitle>Monitoring</LaunchpadStageCardBlockSmallSubtitle>
                </LaunchpadStageCardBlock>
                <LaunchpadStageCardBlock height={60} isDarkMode={isDarkMode}>
                  <LaunchpadStageCardBlockSmallSubtitle>
                    Coach <LaunchpadStageCardBlockSmallTitle>Leaders</LaunchpadStageCardBlockSmallTitle>
                  </LaunchpadStageCardBlockSmallSubtitle>

                  <LaunchpadStageCardBlockSmallSubtitle>
                    & assist blockchain adoption
                  </LaunchpadStageCardBlockSmallSubtitle>
                </LaunchpadStageCardBlock>
                <LaunchpadeStageCardDownbar color={'darkblue'}></LaunchpadeStageCardDownbar>
              </LaunchpadStageCardContainerLast>
              <LaunchpadStageCardContainerLast marginTop={70}>
                <LaunchpadStageCardBlock height={60} isDarkMode={isDarkMode}>
                  <LaunchpadStageCardBlockSmallTitle>Service Design</LaunchpadStageCardBlockSmallTitle>
                </LaunchpadStageCardBlock>
                <LaunchpadStageCardBlock height={60} isDarkMode={isDarkMode}>
                  <LaunchpadStageCardBlockSmallTitle>Product Design</LaunchpadStageCardBlockSmallTitle>
                </LaunchpadStageCardBlock>
                <LaunchpadStageCardBlock height={60} isDarkMode={isDarkMode}>
                  <LaunchpadStageCardBlockSmallTitle>Product Delivery</LaunchpadStageCardBlockSmallTitle>
                </LaunchpadStageCardBlock>
                <LaunchpadeStageCardDownbar color={'darkblue'}></LaunchpadeStageCardDownbar>
              </LaunchpadStageCardContainerLast>
              <LaunchpadStageCardContainerLast marginTop={0}>
                <LaunchpadStageCardBlock height={60} isDarkMode={isDarkMode}>
                  <LaunchpadStageCardBlockSmallTitle>
                    Communication <br /> / Branding
                  </LaunchpadStageCardBlockSmallTitle>
                </LaunchpadStageCardBlock>
                <LaunchpadStageCardBlock height={60} isDarkMode={isDarkMode}>
                  <LaunchpadStageCardBlockSmallTitle>
                    Business
                    <br />
                    Development &<br />
                    Growth
                  </LaunchpadStageCardBlockSmallTitle>
                </LaunchpadStageCardBlock>
                <LaunchpadStageCardBlock height={60} isDarkMode={isDarkMode}>
                  <LaunchpadStageCardBlockSmallTitle>Blockchain</LaunchpadStageCardBlockSmallTitle>
                </LaunchpadStageCardBlock>
                <LaunchpadeStageCardDownbar color={'darkblue'}></LaunchpadeStageCardDownbar>
              </LaunchpadStageCardContainerLast>
            </LaunchpadStageCard>
          </LaunchpadStagesContainer>
          <LaunchpadImpactButton>
            <a href="https://form.typeform.com/to/xHPIDHzr" target="_blank" rel="noreferrer">
              <Button appearance="primary">Launchpad application</Button>
            </a>
          </LaunchpadImpactButton>
        </LaunchpadStyled>
      </LaunchpadCriteriasIllustration>
      <LaunchpadStyled>
        <Blog withSubTitle={true} label="Deep dive" articles={blogArticles} />
      </LaunchpadStyled>
      <JoinCommunity />
      <Footer />
    </LaunchpadContainer>
  )
}
