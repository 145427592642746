import { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { State } from 'reducers'

import { MoreAlign, MoreLink, MoreStyled, MoreTitle } from './More.style'

interface MoreProps {
  title: string
  link: string
  children: ReactNode
}

export const More = ({ title, link, children }: MoreProps) => {
  const isDarkMode = useSelector((state: State) => state.darkMode)

  return (
    <MoreStyled isDarkMode={isDarkMode}>
      <MoreAlign>
        <MoreTitle>{title}</MoreTitle>
        <MoreLink href={link} target="_blank">
          <img src="/images/open.png" alt="Open" />
        </MoreLink>
      </MoreAlign>
      <p>{children}</p>
    </MoreStyled>
  )
}
