import { SWITCH_MODE_REQUEST } from 'app/App.actions'

export type DarkModeState = boolean

const darkModeInitialState: DarkModeState = false

export function darkMode(state = darkModeInitialState, action: any) {
  switch (action.type) {
    case SWITCH_MODE_REQUEST: {
      if(action.mode === 'light'){
        return false;
      }else{
        return true;
      }
    }
    default:
      return state
  }
}
