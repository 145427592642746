import styled, { css } from 'styled-components/macro'

export const ButtonStyled = styled.span<{ appearance: string; width: string; withShadow: boolean }>`
  ${({ appearance, theme }) => {
    let elementBackgroundColor = theme.primaryColor
    let textColor = theme.backgroundTextColor
    let fontWeight = 600
    let border = true
    switch (appearance) {
      case 'primary':
        fontWeight = 500
        elementBackgroundColor = theme.primaryColor
        textColor = theme.backgroundTextColor
        break
      case 'secondary':
        elementBackgroundColor = theme.secondaryColor
        textColor = theme.backgroundTextColor
        break
      case 'tertiary':
        elementBackgroundColor = '#ebecf0'
        textColor = '#000000'
        border = false
        break
      default:
        elementBackgroundColor = theme.primaryColor
        textColor = theme.backgroundTextColor
    }

    let borderVal
    if (border === true) {
      borderVal = '2px solid rgba(255, 255, 255, 0.4)'
    } else {
      borderVal = '2px solid rgba(255,255,255,0)'
    }

    return css`
      border: ${borderVal};
      background: linear-gradient(135.37deg, rgba(0, 0, 0, 0.4) 4.29%, rgba(255, 255, 255, 0.4) 95.6%),
        ${elementBackgroundColor};
      color: ${textColor};
      font-weight: ${fontWeight};
      svg {
        fill: ${textColor};
      }
    `
  }}

  height: 50px;
  background-blend-mode: soft-light, normal;
  box-sizing: border-box;
  ${({ withShadow, theme }) =>
    withShadow &&
    `
      box-shadow: -10px -10px 20px ${theme.primaryShadowColor},
        10px 10px 20px ${theme.secondaryShadowColor};
    `}
  border-radius: 40px;

  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  line-height: 46px;
  padding: 0 20px !important;
  margin: 0 !important;
  max-width: 280px;
  width: ${(props) => props.width};
  > svg {
    margin-left: 11px;
    width: 24px;
    height: 24px;
  }

  > img {
    margin-left: 11px;
    width: 32px;
    height: 32px;
  }
  @media (max-width: 500px) {
    padding: 0 10px !important;
    font-size: 13px;
  }
`
