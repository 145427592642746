import { showModal } from 'app/App.components/Modal/Modal.actions'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'reducers'

import { PressView } from './Press.view'

export const Press = () => {
  const isDarkMode = useSelector((state: State) => state.darkMode)
  const dispatch = useDispatch()

  const showModalCb = (title: string, children: JSX.Element) => {
    dispatch(showModal(title, children))
  }
  return <PressView isDarkMode={isDarkMode} showModal={showModalCb} />
}
