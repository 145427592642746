import { Footer } from 'app/App.components/Footer/Footer.view'
import { Team as TeamList } from 'app/App.components/Team/Team.view'
import { Title } from 'app/App.components/Title/Title.view'
import { Category, Person } from 'common/types'
import { useState } from 'react'

import { persons } from '../../common/persons'
//prettier-ignore
import { TeamAnchor, TeamAnchorContainer, TeamCategories, TeamCategoriesResponsive, TeamCategoriesResponsiveArrow, TeamCategoriesResponsiveContainer, TeamCategory, TeamStyled, TeamTitle } from './Team.style'

export const Team = () => {
  const [selectedList, setSelectedList] = useState(1)

  const scroll = (anchor: string) => document.getElementById(anchor)?.scrollIntoView({ behavior: 'smooth' })

  const handleChange = (selected: number) => {
    setSelectedList(selected)

    switch (selected) {
      case 1:
        scroll('founders')
        break
      case 2:
        scroll('blockchain')
        break
      case 3:
        scroll('impactAssessment')
        break
      case 4:
        scroll('growth')
        break
      case 5:
        scroll('product')
        break
      case 6:
        scroll('advisors')
        break
      case 7:
        scroll('ambassadors')
        break
      default:
        scroll('founders')
    }
  }

  return (
    <div>
      <TeamStyled>
        <TeamTitle>
          <Title label="Team" fontSize={[48, 26]}>
            35+ humans with diverse competencies
            <br />
            joining Cardano Community
          </Title>
        </TeamTitle>
        <TeamCategories>
          <TeamCategory active={selectedList === 1} onClick={() => handleChange(1)}>
            Founders&nbsp;&&nbsp;executives
          </TeamCategory>
          <TeamCategory active={selectedList === 2} onClick={() => handleChange(2)}>
            Blockchain
          </TeamCategory>
          <TeamCategory active={selectedList === 3} onClick={() => handleChange(3)}>
            Impact&nbsp;Assessment
          </TeamCategory>
          <TeamCategory active={selectedList === 4} onClick={() => handleChange(4)}>
            Growth,&nbsp;Marketing&nbsp;&&nbsp;Communication
          </TeamCategory>
          <TeamCategory active={selectedList === 5} onClick={() => handleChange(5)}>
            Product
          </TeamCategory>
          <TeamCategory active={selectedList === 6} onClick={() => handleChange(6)}>
            Advisors
          </TeamCategory>
          <TeamCategory active={selectedList === 7} onClick={() => handleChange(7)}>
            Ambassadors
          </TeamCategory>
        </TeamCategories>
        <TeamCategoriesResponsiveContainer>
          <TeamCategoriesResponsive onChange={(e) => handleChange(parseInt(e.target.value))}>
            <option value="1">Founders & executives</option>
            <option value="2">Blockchain</option>
            <option value="3">Impact Assessment</option>
            <option value="4">Growth, Marketing & Communication</option>
            <option value="5">Product</option>
            <option value="6">Advisors</option>
            <option value="7">Ambassadors</option>
          </TeamCategoriesResponsive>
          <TeamCategoriesResponsiveArrow src="/icons/arrow-down.svg" alt="" />
        </TeamCategoriesResponsiveContainer>
        <TeamAnchorContainer>
          <TeamAnchor id="founders" />
          <TeamList
            name="Founders & executives"
            persons={persons.filter((person: Person) => person.category === Category.Founders)}
          />
        </TeamAnchorContainer>
        <TeamAnchorContainer>
          <TeamAnchor id="blockchain" />
          <TeamList
            name="Blockchain"
            persons={persons.filter((person: Person) => person.category === Category.Blockchain)}
          />
        </TeamAnchorContainer>
        <TeamAnchorContainer>
          <TeamAnchor id="impactAssessment" />
          <TeamList
            name="Impact Assessment"
            persons={persons.filter((person: Person) => person.category === Category.ImpactAssessment)}
          />
        </TeamAnchorContainer>
        <TeamAnchorContainer>
          <TeamAnchor id="growth" />
          <TeamList
            name="Growth, Marketing and Communication"
            persons={persons.filter((person: Person) => person.category === Category.Growth)}
          />
        </TeamAnchorContainer>
        <TeamAnchorContainer>
          <TeamAnchor id="product" />
          <TeamList name="Product" persons={persons.filter((person: Person) => person.category === Category.Product)} />
        </TeamAnchorContainer>
        <TeamAnchorContainer>
          <TeamAnchor id="advisors" />
          <TeamList
            name="Advisors"
            persons={persons.filter((person: Person) => person.category === Category.Advisors)}
          />
        </TeamAnchorContainer>
        <TeamAnchorContainer>
          <TeamAnchor id="ambassadors" />
          <TeamList
            name="Ambassadors"
            persons={persons.filter((person: Person) => person.category === Category.Ambassadors)}
          />
        </TeamAnchorContainer>
      </TeamStyled>
      <Footer />
    </div>
  )
}
