import { Person } from 'common/types'
import { Link } from 'react-router-dom'

import { AlternativeLink, PersonContainer, Persons, TeamName } from './Team.style'

interface TeamProps {
  name?: string
  selectedSlug?: string
  onSelect?: (slug: string) => void
  persons: Array<Person>
}

export const Team = ({ name, selectedSlug, onSelect, persons }: TeamProps) => {
  const PersonElement = ({ person }: { person: Person }) => {
    return (
      <PersonContainer isSelected={selectedSlug === person.slug}>
        <Link key={person.slug} to={`/team/${person.slug}`}>
          <img src={person.image} alt={person.name} />
        </Link>
        <div>
          <span>{person.name}</span>
          <span>{person.function}</span>
          <a href={person.linkedin} target="_blank" rel="noreferrer">
            <img src="/icons/light-linkedin.svg" alt="Linkedin" />
          </a>
        </div>
      </PersonContainer>
    )
  }

  return (
    <div>
      {name ? <TeamName>{name}</TeamName> : null}
      <Persons>
        {onSelect
          ? persons.map((person) =>
              selectedSlug === person.slug ? (
                <PersonElement key={person.slug} person={person} />
              ) : (
                <AlternativeLink key={person.slug} onClick={() => onSelect(person.slug)}>
                  <PersonElement person={person} />
                </AlternativeLink>
              ),
            )
          : persons.map((person) => <PersonElement person={person} />)}
      </Persons>
    </div>
  )
}
