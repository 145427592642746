import { Title } from 'app/App.components/Title/Title.view'
import Marquee from 'react-marquee-master'
import { Link } from 'react-router-dom'

import { Button } from '../Button/Button.view'
import { More } from '../More/More.view'
// prettier-ignore
import { CompaniesCol, CompaniesColFadeBottom, CompaniesColFadeTop, CompaniesContainer, CompaniesLeft, CompaniesMoreButton, CompaniesMoreCard, CompaniesMoreTitle, CompaniesPhoto, CompaniesPhotoContainer, CompaniesRight, CompaniesStyled } from './Companies.style'

export const Companies = () => {
  let elements1 = [
    <CompaniesPhotoContainer>
      <CompaniesPhoto src="/images/team/EricRaffin.png" alt="" />
    </CompaniesPhotoContainer>,
    <CompaniesPhotoContainer>
      <CompaniesPhoto src="/images/team/FredArnoux.png" alt="" />
    </CompaniesPhotoContainer>,
    <CompaniesPhotoContainer>
      <CompaniesPhoto src="/images/team/KonstantinGrouzdev.png" alt="" />
    </CompaniesPhotoContainer>,
    <CompaniesPhotoContainer>
      <CompaniesPhoto src="/images/team/JeanChristopheBohin.png" alt="" />
    </CompaniesPhotoContainer>,
    <CompaniesPhotoContainer>
      <CompaniesPhoto src="/images/team/AdrienHubert.png" alt="" />
    </CompaniesPhotoContainer>,
    <CompaniesPhotoContainer>
      <CompaniesPhoto src="/images/team/EricRaffin.png" alt="" />
    </CompaniesPhotoContainer>,
    <CompaniesPhotoContainer>
      <CompaniesPhoto src="/images/team/FredArnoux.png" alt="" />
    </CompaniesPhotoContainer>,
    <CompaniesPhotoContainer>
      <CompaniesPhoto src="/images/team/KonstantinGrouzdev.png" alt="" />
    </CompaniesPhotoContainer>,
    <CompaniesPhotoContainer>
      <CompaniesPhoto src="/images/team/JeanChristopheBohin.png" alt="" />
    </CompaniesPhotoContainer>,
    <CompaniesPhotoContainer>
      <CompaniesPhoto src="/images/team/AdrienHubert.png" alt="" />
    </CompaniesPhotoContainer>,
  ]

  let elements2 = [
    <CompaniesPhotoContainer>
      <CompaniesPhoto src="/images/team/AdinaCazalens.png" alt="" />
    </CompaniesPhotoContainer>,
    <CompaniesPhotoContainer>
      <CompaniesPhoto src="/images/team/CharlesAugu.png" alt="" />
    </CompaniesPhotoContainer>,
    <CompaniesPhotoContainer>
      <CompaniesPhoto src="/images/team/SebastienSikorski.png" alt="" />
    </CompaniesPhotoContainer>,
    <CompaniesPhotoContainer>
      <CompaniesPhoto src="/images/team/JeremyNicolasMartin.png" alt="" />
    </CompaniesPhotoContainer>,
    <CompaniesPhotoContainer>
      <CompaniesPhoto src="/images/team/AymericBethencourt.png" alt="" />
    </CompaniesPhotoContainer>,
    <CompaniesPhotoContainer>
      <CompaniesPhoto src="/images/team/AdinaCazalens.png" alt="" />
    </CompaniesPhotoContainer>,
    <CompaniesPhotoContainer>
      <CompaniesPhoto src="/images/team/CharlesAugu.png" alt="" />
    </CompaniesPhotoContainer>,
    <CompaniesPhotoContainer>
      <CompaniesPhoto src="/images/team/SebastienSikorski.png" alt="" />
    </CompaniesPhotoContainer>,
  ]

  let elements3 = [
    <CompaniesPhotoContainer>
      <CompaniesPhoto src="/images/team/HaroldCunnigham.png" alt="" />
    </CompaniesPhotoContainer>,
    <CompaniesPhotoContainer>
      <CompaniesPhoto src="/images/team/FlorentineCuenot.png" alt="" />
    </CompaniesPhotoContainer>,
    <CompaniesPhotoContainer>
      <CompaniesPhoto src="/images/team/LouiseHabib.png" alt="" />
    </CompaniesPhotoContainer>,
    <CompaniesPhotoContainer>
      <CompaniesPhoto src="/images/team/DavidLevel.png" alt="" />
    </CompaniesPhotoContainer>,
    <CompaniesPhotoContainer>
      <CompaniesPhoto src="/images/team/LoicCouharde.png" alt="" />
    </CompaniesPhotoContainer>,
    <CompaniesPhotoContainer>
      <CompaniesPhoto src="/images/team/HaroldCunnigham.png" alt="" />
    </CompaniesPhotoContainer>,
    <CompaniesPhotoContainer>
      <CompaniesPhoto src="/images/team/FlorentineCuenot.png" alt="" />
    </CompaniesPhotoContainer>,
    <CompaniesPhotoContainer>
      <CompaniesPhoto src="/images/team/LouiseHabib.png" alt="" />
    </CompaniesPhotoContainer>,
    <CompaniesPhotoContainer>
      <CompaniesPhoto src="/images/team/DavidLevel.png" alt="" />
    </CompaniesPhotoContainer>,
    <CompaniesPhotoContainer>
      <CompaniesPhoto src="/images/team/LoicCouharde.png" alt="" />
    </CompaniesPhotoContainer>,
  ]
  return (
    <CompaniesStyled>
      <CompaniesContainer>
        <CompaniesLeft>
          <CompaniesMoreTitle>
            <Title label="40+ Humans involved" fontSize={[48, 26]}>
              Cardashift Core Companies
            </Title>
          </CompaniesMoreTitle>
          <CompaniesMoreCard>
            <More title="Matters" link="https://matters.tech/en">
              Product and start-up studio helping new businesses to succeed and scale their idea from inception all the
              way through to finished product/service. With over 14 years of experience, Matters has assisted over 150
              startups with a success rate upwards of 90% and with a cumulative value of +1bn$.
            </More>
          </CompaniesMoreCard>
          <CompaniesMoreCard>
            <More title="Smart-Chain" link="https://smart-chain.fr/en">
              Firm focused on Blockchain research and innovation with a specialization in PoS protocols. Passionate
              about Cardano and his scientific approach, Smartchain has an expertise in scalability of PoS protocols,
              Payments, ZK-proof, MPC, Cert&Traceability use cases, and governance and incentive systems.
            </More>
          </CompaniesMoreCard>
          <CompaniesMoreCard>
            <More title="Stim" link="https://www.wearestim.com/en">
              A spin-off from Mines Paristech, a top-level European engineering university, Stim specialises in radical
              innovation exploration and acceleration for environmental shift. For the past 7 years Stim has been
              developing disruptive innovation strategies and products for industry leaders in energy, food, and
              mobility.
            </More>
          </CompaniesMoreCard>
          <CompaniesMoreButton>
            <Link to="/team">
              <Button appearance="primary">View our team</Button>
            </Link>
          </CompaniesMoreButton>
        </CompaniesLeft>
        <CompaniesRight>
          <CompaniesCol>
            <CompaniesColFadeTop />
            <Marquee marqueeItems={elements1} minHeight={1350} direction="down" delay={30}></Marquee>
            <CompaniesColFadeBottom />
          </CompaniesCol>
          <CompaniesCol>
            <Marquee marqueeItems={elements2} minHeight={1350} direction="up" delay={30}></Marquee>
          </CompaniesCol>
          <CompaniesCol>
            <Marquee marqueeItems={elements3} minHeight={1350} direction="down" delay={30}></Marquee>
          </CompaniesCol>
        </CompaniesRight>
      </CompaniesContainer>
    </CompaniesStyled>
  )
}
