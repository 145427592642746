import Axios from 'axios'

enum ApiStatus {
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
}
interface ApiResponse<T> {
  STATUS: ApiStatus
  MESSAGE: string
  DATA: T
}

interface IcoPhase {
  phase: number
  kyc: number
  funding: number
}

export namespace IcoApi {
  export const getParams = () => {
    const apiUrl = process.env.REACT_APP_ENV === 'dev' ? 'https://api-dev.cardashift.com/api/ico/params/' : 'https://api.cardashift.com/api/ico/params/'
    return Axios.get<ApiResponse<IcoPhase>>(apiUrl)
  }
}
