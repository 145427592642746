import { CardContainer, CardStyled } from './Card.style'
import { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { State } from 'reducers'

interface CardProps {
  rotate?: 'left' | 'right'
  borderRadius?: number
  children: ReactNode
  clickCallback?: () => void
}

export const Card = ({ rotate, borderRadius = 30, children, clickCallback = () => {} }: CardProps) => {
  const isDarkMode = useSelector((state: State) => state.darkMode)

  return (
    <CardContainer onClick={() => clickCallback()} isDarkMode={isDarkMode} rotate={rotate} borderRadius={borderRadius}>
      <CardStyled isDarkMode={isDarkMode}>{children}</CardStyled>
    </CardContainer>
  )
}
