import styled from 'styled-components/macro'

export const MoreStyled = styled.div<{ isDarkMode: boolean }>`
  background-color: ${({ theme }) => theme.backgroundColor};
  background-blend-mode: soft-light, normal;
  border: ${({ isDarkMode }) => (!isDarkMode ? '1px solid rgba(255, 255, 255, 0.4)' : 'none')};
  box-sizing: border-box;
  box-shadow: -5px -5px 10px ${({ theme }) => theme.primaryShadowColor},
    5px 5px 10px ${({ theme }) => theme.secondaryShadowColor};
  border-radius: 8px;
  padding: 20px;
`

export const MoreAlign = styled.div`
  display: flex;
`

export const MoreTitle = styled.div`
  font-weight: bold;
  font-size: 21px;
  color: ${({ theme }) => theme.subTextColor};
  margin-bottom: 16px;
`

export const MoreLink = styled.a`
  background: linear-gradient(135.37deg, rgba(0, 0, 0, 0.4) 4.29%, rgba(255, 255, 255, 0.4) 95.6%),
    ${({ theme }) => theme.backgroundColor};
  background-blend-mode: soft-light, normal;
  box-shadow: -10px -10px 20px ${({ theme }) => theme.primaryShadowColor},
    10px 10px 20px ${({ theme }) => theme.secondaryShadowColor};
  margin-left: 16px;
  margin-top: -4px;

  height: 32px;
  width: 32px;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 32px;
  }
`
