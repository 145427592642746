import { useSelector } from 'react-redux'
import { State } from 'reducers'
import { FooterCol, FooterStyled } from './Footer.style'

export const Footer = () => {
  const isDarkMode = useSelector((state: State) => state.darkMode)
  return (
    <FooterStyled>
      <FooterCol isDarkMode={isDarkMode}>
        <img src="/images/dark-logo.png" alt="Cardashift" />
      </FooterCol>
      <FooterCol isDarkMode={isDarkMode}>
        <span>Empower the visionary ventures that will create a sustainable and prosperous future.</span>
        <br />
        <br />
        <a
          href="https://storage.googleapis.com/terraform-csp-prod-public-uploads-vbcklz/docs/whitepaper-v1.pdf"
          target="_blank"
          rel="noreferrer"
        >
          White Paper
        </a>
        &nbsp;&nbsp;&nbsp;
        <a
          href="https://storage.googleapis.com/terraform-csp-prod-public-uploads-vbcklz/docs/overview-v1.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Overview
        </a>
        &nbsp;&nbsp;&nbsp;
        <a
          href="https://storage.googleapis.com/terraform-csp-prod-public-uploads-vbcklz/docs/slide-deck-v2.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Slide Deck
        </a>
        &nbsp;&nbsp;&nbsp;
        <a
          href="https://storage.googleapis.com/terraform-csp-prod-public-uploads-vbcklz/docs/terms-v1.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Terms and Conditions
        </a>
      </FooterCol>
      <FooterCol isDarkMode={isDarkMode}>
        <div>
          <a href="https://discord.gg/hTSWWButZR" target="_blank" rel="noreferrer">
            <img src="/icons/dark-discord.svg" alt="Discord" />
          </a>
          <a href="https://cardashift.medium.com/" target="_blank" rel="noreferrer">
            <img src="/icons/dark-medium.svg" alt="Medium" />
          </a>
          <a href="https://twitter.com/cardashift" target="_blank" rel="noreferrer">
            <img src="/icons/dark-twitter.svg" alt="Twitter" />
          </a>
          <a href="https://www.linkedin.com/company/cardashift/" target="_blank" rel="noreferrer">
            <img src="/icons/dark-linkedin.svg" alt="Linkedin" />
          </a>
        </div>
        <div>&copy;&nbsp;Cardashift.&nbsp;All&nbsp;rights&nbsp;reserved.</div>
      </FooterCol>
    </FooterStyled>
  )
}
