import styled, { createGlobalStyle } from 'styled-components/macro'

export const DisableBody = createGlobalStyle`
  body{
    overflow: hidden;
  }
`
export const ModalStyled = styled.div`
  display: none;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(10px);

  position: fixed;

  top: 0px;

  z-index: 401;
  &.showing {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const ModalBody = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  @media (max-width: 1023px) {
    display: block;
    width: calc(100% - 60px);
  }

  @media (max-width: 1023px) {
    display: block;
    width: calc(100% - 30px);
  }
`

export const ModalImgContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  padding: 0 57px;
  background: black;
  @media (max-width: 1023) {
    display: none;
  }
`
export const ModalImg = styled.img`
  width: 100%;
`

export const ModalContainer = styled.div`
  width: fit-content;

  padding: 21px;
  background: rgba(18, 30, 52, 0.7);
  border-radius: 8px;
  @media (max-width: 1023px) {
    left: 30px;
    padding: 24px 20px;
    width: 100%;
  }
`

export const ModalFirstLineContainer = styled.div`
  position: relative;
`

export const ModalTitle = styled.p`
  font-family: Nizzoli;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 130%;

  color: #f8fafc;
  text-align: center;
  margin: 0;

  margin-top: 19px;
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 22px;
  }
`

export const ModalCross = styled.span`
  position: absolute;
  top: 21px;
  right: 21px;
  cursor: pointer;

  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  color: #f8fafc;

  width: 14px;
  height: 14px;
`
