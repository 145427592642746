import styled from 'styled-components/macro'

export const CommunityStyled = styled.div`
  background-image: url('/images/end-bubbles.png');
  background-position: top left;
  background-repeat: no-repeat;
  background-size: auto 700px;
  display: flex;
  justify-content: flex-end;
  margin-top: 100px;
  padding-bottom: 120px;

  @media (max-width: 700px) {
    margin-top: 0;
    background-image: url('/images/end-bubbles-mobile.png');
    background-size: 200px;
    padding: 120px 20px 0px 20px;
  }

  @media (max-width: 360px) {
    padding: 120px 10px 0px 10px;
  }
  position: relative;
`

export const CommunityContainer = styled.div`
  max-width: 1240px;
  margin: 100px auto;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 1023px) {
    margin-bottom: 200px;
  }
`

export const CommunityBubbles = styled.img`
  position: absolute;
  right: 0;
  z-index: -1;
  bottom: 87px;
`

export const CommunityEnding = styled.div`
  width: 60%;

  @media (max-width: 900px) {
    width: 100%;
    text-align: center;
  }
`

export const CommunityParagraph = styled.p`
  line-height: 24px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;

  text-align: left;

  @media (max-width: 1023px) {
    line-height: 24px;
    text-align: center;
  }
`

export const CommunityEndingButtons = styled.div`
  margin-top: 40px;
`

export const CommunityTitleButtons = styled.div`
  margin-top: 35px;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 10px;

  @media (max-width: 800px) {
    grid-template-columns: auto;
    grid-gap: 20px;
    text-align: center;
    margin: 35px auto;

    > a {
      margin: auto;

      &:last-child {
        display: none;
      }
    }
  }
`

export const CommunitySocial = styled.div`
  margin-top: 50px;
`

export const CommunityTitle = styled.p`
  font-family: Nizzoli;
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 62px;
  text-align: left;
  color: ${({ theme }) => theme.textColor};
  @media (max-width: 1023px) {
    text-align: center;
    font-size: 26px;
    line-height: 34px;
  }
`
