import { useSelector } from 'react-redux'
import { State } from 'reducers'
import { ClapTableStyled, ClapTableLabel, ClapTableValue } from './ClapTable.style'

interface ClapTableProps {
  data: Array<{ label: string; value: string }>
  appearance?: 'primary' | 'secondary' | 'tertiary'
}

export const ClapTable = ({ data, appearance = 'tertiary' }: ClapTableProps) => {
  const isDarkMode = useSelector((state: State) => state.darkMode)

  return (
    <ClapTableStyled isDarkMode={isDarkMode}>
      {data.map(({ label, value }) => (
        <div key={label}>
          <ClapTableLabel dangerouslySetInnerHTML={{ __html: label }} />
          <ClapTableValue dangerouslySetInnerHTML={{ __html: value }} appearance={appearance} />
        </div>
      ))}
    </ClapTableStyled>
  )
}
