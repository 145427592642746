import * as React from 'react'
import { GetClapView } from './GetClap.view'
import { hideModal } from 'app/App.components/Modal/Modal.actions'
import { useDispatch } from 'react-redux'

export const GetClap = () => {
  const dispatch = useDispatch()

  const closeModal = () => {
    dispatch(hideModal())
  }
  return <GetClapView closeModal={closeModal} />
}
