import { useSelector } from 'react-redux'
import { State } from 'reducers'
import { UtilityStyled, UtilityIcon, UtilityTitle, UtilityLegend, UtilityPoint } from './Utility.style'

interface UtilityProps {
  icon: string
  title: string
  legend?: string
  points: Array<string>
}

export const Utility = ({ icon, title, legend, points }: UtilityProps) => {
  const isDarkMode = useSelector((state: State) => state.darkMode)

  return (
    <UtilityStyled isDarkMode={isDarkMode}>
      <UtilityTitle>
        <UtilityIcon src={icon} alt="" />
        <div>
          {title}
          {legend && <UtilityLegend> - {legend}</UtilityLegend>}
        </div>
      </UtilityTitle>
      {points.map((point) => (
        <UtilityPoint key={point}>{point}</UtilityPoint>
      ))}
    </UtilityStyled>
  )
}
