import { IcoApi } from 'api/ico.api'

export const RECAPTCHA_REQUEST = 'RECAPTCHA_REQUEST'
export const SWITCH_MODE_REQUEST = 'SWITCH_MODE_REQUEST'
export const GET_ICO_STATE = 'GET_ICO_STATE'

export const recaptchaRequest = () => (dispatch: any) => {
  dispatch({
    type: RECAPTCHA_REQUEST,
  })
}

export const switchMode = (type: string) => (dispatch: any) => {
  dispatch({
    type: SWITCH_MODE_REQUEST,
    mode: type,
  })
}

let loaded = false
export const getIcoState = () => (dispatch: any) => {
  if (!loaded) {
    IcoApi.getParams().then((res) => {
      let stateApi = res.data.DATA
      let state = 0

      if (stateApi.phase === 1 && stateApi.kyc === 1 && stateApi.funding === 0) {
        state = 1
      } else if (stateApi.phase === 1 && stateApi.kyc === 0 && stateApi.funding === 0) {
        state = 2
      } else if (stateApi.phase === 1 && stateApi.kyc === 0 && stateApi.funding === 1) {
        state = 3
      } else if (stateApi.phase === 2 && stateApi.kyc === 0 && stateApi.funding === 0) {
        state = 5.5
      } else if (stateApi.phase === 2 && stateApi.kyc === 1 && stateApi.funding === 0) {
        state = 5
      } else if (stateApi.phase === 2 && stateApi.kyc === 0 && stateApi.funding === 1) {
        state = 6
      } else if (stateApi.phase === 3 && stateApi.kyc === 0 && stateApi.funding === 0) {
        state = 7
      }

      if (state !== 0) {
        loaded = true
      }

      dispatch({
        state: state,
        type: GET_ICO_STATE,
      })
    })
  }
}
