import styled, { css } from 'styled-components/macro'

export const ClapTableStyled = styled.div<{ isDarkMode: boolean }>`
  background: ${({ theme }) => theme.backgroundColor};
  border: ${({ isDarkMode }) => (!isDarkMode ? '1px solid rgba(255, 255, 255, 0.4)' : 'none')};
  box-sizing: border-box;
  box-shadow: -10px -10px 20px ${({ theme }) => theme.primaryShadowColor},
    10px 10px 20px ${({ theme }) => theme.secondaryShadowColor};
  border-radius: 16px;
  padding: 36px 30px;
  min-width: 450px;

  div {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid ${({ theme }) => theme.backgroundTextColor};

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  @media (max-width: 900px) {
    min-width: initial;
  }
`

export const ClapTableLabel = styled.span`
  color: ${({ theme }) => theme.subTextColor};
`

export const ClapTableValue = styled.span<{ appearance: string }>`
  font-weight: bold;
  text-align: right;
  ${({ appearance, theme }) => {
    let color = theme.textColor

    switch (appearance) {
      case 'primary':
        color = theme.alternativeColor
        break
      case 'secondary':
        color = theme.secondaryColor
        break
      case 'tertiary':
        color = theme.textColor
        break
      default:
        color = theme.textColor
    }

    return css`
      color: ${color};
    `
  }}
`
