import styled from 'styled-components/macro'

export const TitleContainer = styled.div<{ centered: boolean }>`
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
`

export const TitleLabel = styled.span<{ labelAbove: boolean }>`
  display: block;
  color: ${({ theme }) => theme.alternativeColor};
  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;
  margin-top: ${(props) => (!props.labelAbove ? '12px' : '0')};
  margin-bottom: ${(props) => (props.labelAbove ? '12px' : '0')};
`

export const TitleElement = styled.h2<{ fontSize: [number, number] }>`
  color: ${({ theme }) => theme.textColor};
  font-family: 'Nizzoli';
  font-weight: 900;
  font-size: ${({ fontSize }) => fontSize[0]}px;
  line-height: 64px;
  margin-bottom: 32px;

  @media (max-width: 1023px) {
    font-size: ${({ fontSize }) => fontSize[1]}px;
    line-height: 34px;
  }
`
