import { Footer } from 'app/App.components/Footer/Footer.view'
import { JoinCommunity } from 'app/App.components/JoinCommunity/JoinCommunity.view'
import { Title } from 'app/App.components/Title/Title.view'
import { useSelector } from 'react-redux'
import { State } from 'reducers'

// prettier-ignore
import { CommunityContainer, CommunityResponsiveBr, CommunityResponsiveBubbles, CommunitySection, CommunitySectionElement, CommunitySectionGrid, CommunitySectionGridFlags, CommunitySectionImage, CommunitySocial, CommunitySocialGrid, CommunityStyled, CommunityTitle } from './Community.style'

export const Community = () => {
  const isDarkMode = useSelector((state: State) => state.darkMode)

  return (
    <CommunityContainer>
      <CommunityResponsiveBubbles src="/images/community-top-bubbles.png" alt="" />
      <CommunityStyled>
        <CommunityTitle>
          <Title label="Community" centered fontSize={[48, 26]}>
            Eager to join the Cardashift community?
          </Title>
          <p>
            Choose your preferred channels to begin your Cardashift journey, learn more about the project, talk to the
            team and contribute to the ecosystem
          </p>
        </CommunityTitle>
        <CommunitySocialGrid>
          <CommunitySocial
            href="https://discord.gg/hTSWWButZR"
            target="_blank"
            rel="noreferrer"
            isDarkMode={isDarkMode}
          >
            <img src={isDarkMode ? '/icons/community/discord-dark.svg' : '/icons/community/discord-light.svg'} alt="" />
            <span>Discord</span>
          </CommunitySocial>
          <CommunitySocial
            href="https://cardashift.medium.com/"
            target="_blank"
            rel="noreferrer"
            isDarkMode={isDarkMode}
          >
            <img src={isDarkMode ? '/icons/community/medium-dark.svg' : '/icons/community/medium-light.svg'} alt="" />
            <span>Medium</span>
          </CommunitySocial>
          <CommunitySocial
            href="https://twitter.com/cardashift"
            target="_blank"
            rel="noreferrer"
            isDarkMode={isDarkMode}
          >
            <img src={isDarkMode ? '/icons/community/twitter-dark.svg' : '/icons/community/twitter-light.svg'} alt="" />
            <span>Twitter</span>
          </CommunitySocial>
          <CommunitySocial
            href="https://www.linkedin.com/company/cardashift/"
            target="_blank"
            rel="noreferrer"
            isDarkMode={isDarkMode}
          >
            <img
              src={isDarkMode ? '/icons/community/linkedin-dark.svg' : '/icons/community/linkedin-light.svg'}
              alt=""
            />
            <span>Linkedin</span>
          </CommunitySocial>
          <CommunitySocial
            href="https://www.reddit.com/r/Cardashift/"
            target="_blank"
            rel="noreferrer"
            isDarkMode={isDarkMode}
          >
            <img src={isDarkMode ? '/icons/community/reddit-dark.svg' : '/icons/community/reddit-light.svg'} alt="" />
            <span>Reddit</span>
          </CommunitySocial>
        </CommunitySocialGrid>
        <CommunitySection>
          <CommunitySectionImage
            src={isDarkMode ? '/icons/community/achievements-dark.svg' : '/icons/community/achievements-light.svg'}
            alt=""
          />
          <Title>Achievements</Title>
          <CommunitySectionGrid>
            <CommunitySectionElement isDarkMode={isDarkMode}>
              <img
                src={isDarkMode ? '/icons/community/flash-sale-dark.png' : '/icons/community/flash-sale-light.png'}
                alt=""
              />
              <span>$10M raised - ICO</span>
              <a
                href="https://cardashift.medium.com/cardashift-raises-10m-to-foster-impact-investing-through-cardano-blockchain-9834c4415c36"
                target="_blank"
                rel="noreferrer"
              >
                Read
              </a>
            </CommunitySectionElement>
            <CommunitySectionElement isDarkMode={isDarkMode}>
              <img
                src={isDarkMode ? '/icons/community/cardano-360-dark.png' : '/icons/community/cardano-360-light.png'}
                alt=""
              />
              <span>Cardano 360</span>
              <a href="https://www.youtube.com/watch?v=yaa8Vys1Sms" target="_blank" rel="noreferrer">
                View
              </a>
            </CommunitySectionElement>
            <CommunitySectionElement isDarkMode={isDarkMode}>
              <img
                src={isDarkMode ? '/icons/community/catalyst-dark.png' : '/icons/community/catalyst-light.png'}
                alt=""
              />
              <span>Project Catalyst</span>
              <a href="https://www.youtube.com/watch?v=EPVlAZqsu5Q" target="_blank" rel="noreferrer">
                View
              </a>
            </CommunitySectionElement>
            <CommunitySectionElement isDarkMode={isDarkMode}>
              <img
                src={isDarkMode ? '/icons/community/tokenomia-dark.png' : '/icons/community/tokenomia-light.svg'}
                alt=""
              />
              <span>Tokenomia</span>
              <a href="https://github.com/smart-chain-fr/tokenomia" target="_blank" rel="noreferrer">
                Read
              </a>
            </CommunitySectionElement>
          </CommunitySectionGrid>
        </CommunitySection>
        <CommunitySection>
          <CommunitySectionImage
            src={
              isDarkMode
                ? '/icons/community/cardashift-community-dark.svg'
                : '/icons/community/cardashift-community-light.svg'
            }
            alt=""
          />
          <Title>Cardashift international community</Title>
          <CommunitySectionGridFlags>
            <CommunitySectionElement isDarkMode={isDarkMode}>
              <img src="/icons/community/flags/english.svg" alt="" />
              <span>Official channel - English</span>
              <a href="https://discord.gg/GVn8VWtP6m" target="_blank" rel="noreferrer">
                Join
              </a>
            </CommunitySectionElement>
            <CommunitySectionElement isDarkMode={isDarkMode}>
              <img src="/icons/community/flags/french.svg" alt="" />
              <span>
                France
                <CommunityResponsiveBr>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp; &nbsp; &nbsp;
                </CommunityResponsiveBr>
              </span>
              <a href="https://discord.gg/4quAEkTjXF" target="_blank" rel="noreferrer">
                Join
              </a>
            </CommunitySectionElement>
            <CommunitySectionElement isDarkMode={isDarkMode}>
              <img src="/icons/community/flags/italian.svg" alt="" />
              <span>Italy</span>
              <a href="https://discord.gg/28yGxjgVch" target="_blank" rel="noreferrer">
                Join
              </a>
            </CommunitySectionElement>
            <CommunitySectionElement isDarkMode={isDarkMode}>
              <img src="/icons/community/flags/spain.svg" alt="" />
              <span>Spain</span>
              <a href="https://discord.gg/dMcnRNpW5H" target="_blank" rel="noreferrer">
                Join
              </a>
            </CommunitySectionElement>
            <CommunitySectionElement isDarkMode={isDarkMode}>
              <img src="/icons/community/flags/portuguese.png" alt="" />
              <span>Portuguese</span>
              <a href="https://discord.gg/nsbfQy2YqE" target="_blank" rel="noreferrer">
                Join
              </a>
            </CommunitySectionElement>
            <CommunitySectionElement isDarkMode={isDarkMode}>
              <img src="/icons/community/flags/german.svg" alt="" />
              <span>Germany</span>
              <a href="https://discord.gg/6HvA8QCh5m" target="_blank" rel="noreferrer">
                Join
              </a>
            </CommunitySectionElement>
            <CommunitySectionElement isDarkMode={isDarkMode}>
              <img src="/icons/community/flags/turkey.svg" alt="" />
              <span>Turkey</span>
              <a href="https://discord.gg/eapfm885Uw" target="_blank" rel="noreferrer">
                Join
              </a>
            </CommunitySectionElement>
            <CommunitySectionElement isDarkMode={isDarkMode}>
              <img src="/icons/community/flags/philippines.svg" alt="" />
              <span>Philippines</span>
              <a href="https://discord.gg/GQSMcZZVXt" target="_blank" rel="noreferrer">
                Join
              </a>
            </CommunitySectionElement>
            <CommunitySectionElement isDarkMode={isDarkMode}>
              <img src="/icons/community/flags/russian.png" alt="" />
              <span>Russia</span>
              <a href="https://discord.gg/25vgG9cvdw" target="_blank" rel="noreferrer">
                Join
              </a>
            </CommunitySectionElement>
            <CommunitySectionElement isDarkMode={isDarkMode}>
              <img src="/icons/community/flags/india.svg" alt="" />
              <span>India</span>
              <a href="https://discord.gg/HYKVCP9YW4" target="_blank" rel="noreferrer">
                Join
              </a>
            </CommunitySectionElement>
            <CommunitySectionElement isDarkMode={isDarkMode}>
              <img src="/icons/community/flags/arabic.svg" alt="" />
              <span>Arabic</span>
              <a href="https://discord.gg/q8duYR78sB" target="_blank" rel="noreferrer">
                Join
              </a>
            </CommunitySectionElement>
            <CommunitySectionElement isDarkMode={isDarkMode}>
              <img src="/icons/community/flags/china.svg" alt="" />
              <span>China</span>
              <a href="https://discord.gg/3G9FQZn6Z6" target="_blank" rel="noreferrer">
                Join
              </a>
            </CommunitySectionElement>
          </CommunitySectionGridFlags>
        </CommunitySection>
        <CommunitySection>
          <CommunitySectionImage
            src={isDarkMode ? '/icons/community/documentation-dark.svg' : '/icons/community/documentation-light.svg'}
            alt=""
          />
          <Title>Documentation</Title>
          <CommunitySectionGrid>
            <CommunitySectionElement isDarkMode={isDarkMode}>
              <img
                src={isDarkMode ? '/icons/community/whitepaper-dark.svg' : '/icons/community/whitepaper-light.svg'}
                alt=""
              />
              <span>White paper</span>
              <a href="/cardashift_whitepaper.pdf" target="_blank">
                Read
              </a>
            </CommunitySectionElement>
            <CommunitySectionElement isDarkMode={isDarkMode}>
              <img
                src={isDarkMode ? '/icons/community/onepager-dark.svg' : '/icons/community/onepager-light.svg'}
                alt=""
              />
              <span>One pager</span>
              <a href="/cardashift_overview.pdf" target="_blank">
                Read
              </a>
            </CommunitySectionElement>
            <CommunitySectionElement isDarkMode={isDarkMode}>
              <img src={isDarkMode ? '/icons/community/deck-dark.svg' : '/icons/community/deck-light.svg'} alt="" />
              <span>Slide deck</span>
              <a href="/cardashift_deck.pdf" target="_blank">
                Read
              </a>
            </CommunitySectionElement>
            <CommunitySectionElement isDarkMode={isDarkMode}>
              <img src={isDarkMode ? '/icons/community/faq-dark.svg' : '/icons/community/faq-light.svg'} alt="" />
              <span>FAQ</span>
              <a href="https://cardashift.gitbook.io/cardashift/" target="_blank" rel="noreferrer">
                Read
              </a>
            </CommunitySectionElement>
          </CommunitySectionGrid>
        </CommunitySection>
      </CommunityStyled>
      <JoinCommunity />
      <CommunitySection noMarginTop={true}>
        <Footer />
      </CommunitySection>
    </CommunityContainer>
  )
}
