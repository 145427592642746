import { Footer } from 'app/App.components/Footer/Footer.view'
import { Title } from 'app/App.components/Title/Title.view'
import { Category, Person } from 'common/types'
import { Team } from 'app/App.components/Team/Team.view'
import { Link, useParams } from 'react-router-dom'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { State } from 'reducers'
import { persons } from '../../common/persons'

// prettier-ignore
import { BiographyStyled, BiographyCategoriesResponsiveContainer, BiographyCategoriesResponsive,BiographyCategoriesResponsiveArrow, BiographyContainer, BiographyCol, BiographyBack, BiographyIcons, BiographyIcon, BiographyParagraph, BiographyImageContainer, BiographyCategories, BiographyCategory, BiographyTeam } from './Biography.style'

export const Biography = () => {
  // const location = useLocation()
  // const { state } = location
  let { slug } = useParams<{ slug?: string }>()

  const [selectedCategory, setSelectedCategory] = useState<Category>(Category.Founders)
  const [selectedSlug, setSelectedSlug] = useState<string>(slug || 'eric-raffin')

  const [selectedList, setSelectedList] = useState<Person[]>(
    slug
      ? persons.filter(
          (person: Person) =>
            person.category === persons.filter((person: Person) => person.slug === slug)?.[0]?.category,
        )
      : persons.filter((person: Person) => person.category === Category.Founders),
  )

  const isDarkMode = useSelector((state: State) => state.darkMode)
  const selected = selectedList.find((person) => person.slug === selectedSlug)

  const handleChangeList = (category: Category) => {
    const list = persons.filter((person: Person) => person.category === category)
    setSelectedCategory(category)
    setSelectedList(list)
    setSelectedSlug(list?.[0]?.slug)
  }

  return (
    <div>
      <BiographyStyled>
        {selected ? (
          <BiographyContainer>
            <BiographyCol>
              <BiographyBack>
                <Link to="/team">
                  <BiographyIcon isDarkMode={isDarkMode}>
                    <img src={isDarkMode ? '/icons/dark-back.svg' : '/icons/light-back.svg'} alt="Back" />
                  </BiographyIcon>
                </Link>
              </BiographyBack>
              <BiographyImageContainer isMobile>
                <img src={selected.image} alt={selected.name} />
              </BiographyImageContainer>
              <Title label={selected.function} labelAbove={false}>
                {selected.name}
              </Title>
              <BiographyIcons>
                <a href={selected.linkedin} target="_blank" rel="noreferrer">
                  <BiographyIcon isDarkMode={isDarkMode}>
                    <img src={isDarkMode ? '/icons/dark-linkedin.svg' : '/icons/light-linkedin.svg'} alt="Linkedin" />
                  </BiographyIcon>
                </a>
              </BiographyIcons>
              <BiographyParagraph dangerouslySetInnerHTML={{ __html: selected.bio }} />
            </BiographyCol>
            <BiographyCol>
              <BiographyImageContainer>
                <img src={selected.image} alt={selected.name} />
              </BiographyImageContainer>
            </BiographyCol>
          </BiographyContainer>
        ) : null}
        <BiographyCategories>
          <BiographyCategory
            active={selectedCategory === Category.Founders}
            onClick={() => handleChangeList(Category.Founders)}
          >
            Founders & executives
          </BiographyCategory>
          <BiographyCategory
            active={selectedCategory === Category.Blockchain}
            onClick={() => handleChangeList(Category.Blockchain)}
          >
            Blockchain
          </BiographyCategory>
          <BiographyCategory
            active={selectedCategory === Category.ImpactAssessment}
            onClick={() => handleChangeList(Category.ImpactAssessment)}
          >
            Impact Assessment
          </BiographyCategory>
          <BiographyCategory
            active={selectedCategory === Category.Growth}
            onClick={() => handleChangeList(Category.Growth)}
          >
            Growth, Marketing & Communication
          </BiographyCategory>
          <BiographyCategory
            active={selectedCategory === Category.Product}
            onClick={() => handleChangeList(Category.Product)}
          >
            Product
          </BiographyCategory>
          <BiographyCategory
            active={selectedCategory === Category.Advisors}
            onClick={() => handleChangeList(Category.Advisors)}
          >
            Advisors
          </BiographyCategory>
          <BiographyCategory
            active={selectedCategory === Category.Ambassadors}
            onClick={() => handleChangeList(Category.Ambassadors)}
          >
            Ambassadors
          </BiographyCategory>
        </BiographyCategories>

        <BiographyCategoriesResponsiveContainer>
          <BiographyCategoriesResponsive
            onChange={(e) => handleChangeList(e.target.value as Category)}
            value={selectedCategory}
          >
            <option value={Category.Founders}>Founders & executives</option>
            <option value={Category.Blockchain}>Blockchain</option>
            <option value={Category.ImpactAssessment}>Impact Assessment</option>
            <option value={Category.Growth}>Growth, Marketing & Communication</option>
            <option value={Category.Product}>Product</option>
            <option value={Category.Advisors}>Advisors</option>
          </BiographyCategoriesResponsive>
          <BiographyCategoriesResponsiveArrow src="/icons/arrow-down.svg" alt="" />
        </BiographyCategoriesResponsiveContainer>
        <BiographyTeam>
          <Team
            persons={selectedList}
            selectedSlug={selectedSlug}
            onSelect={(id) => {
              setSelectedSlug(id)
              window.scrollTo({ behavior: 'smooth', top: 0 })
            }}
          />
        </BiographyTeam>
      </BiographyStyled>
      <Footer />
    </div>
  )
}
