import { Button } from 'app/App.components/Button/Button.view'
import { Social } from 'app/App.components/Social/Social.view'

import {
  CommunityContainer,
  CommunityEnding,
  CommunityEndingButtons,
  CommunityParagraph,
  CommunitySocial,
  CommunityStyled,
  CommunityTitle,
  CommunityTitleButtons,
  CommunityBubbles,
} from './JoinCommunity.style'

export const JoinCommunity = () => (
  <CommunityStyled>
    <CommunityContainer>
      <CommunityBubbles src="/images/eager-bubbles.png" alt="" />
      <CommunityEnding>
        <CommunityTitle>Eager to join the Cardashift community?</CommunityTitle>
        <CommunityParagraph>
          Choose your preferred channels to begin your Cardashift journey, learn more about the project, talk to the
          team and contribute to the ecosystem
        </CommunityParagraph>
        <CommunityEndingButtons>
          <CommunityTitleButtons>
            <a href="https://discord.gg/hTSWWButZR" target="_blank" rel="noreferrer">
              <Button appearance="secondary" icon="discord">
                Join us on Discord
              </Button>
            </a>
            <a href="/cardashift_whitepaper.pdf" target="_blank" rel="noreferrer">
              <Button appearance="tertiary" withIcon={false}>
                Discover our white paper
              </Button>
            </a>
          </CommunityTitleButtons>
          <CommunitySocial>
            <Social />
          </CommunitySocial>
        </CommunityEndingButtons>
      </CommunityEnding>
    </CommunityContainer>
  </CommunityStyled>
)
