import styled from 'styled-components/macro'

export const SocialStyled = styled.div<{ isDarkMode: boolean }>`
  display: grid;
  grid-template-columns: repeat(4, 44px);
  grid-gap: 20px;

  a {
    background: ${({ theme }) => theme.backgroundColor};
    background-blend-mode: soft-light, normal;
    border: ${({ isDarkMode }) => (!isDarkMode ? '2px solid rgba(255, 255, 255, 0.4)' : 'none')};
    box-sizing: boder-box;
    box-shadow: -10px -10px 20px ${({ theme }) => theme.primaryShadowColor},
      10px 10px 20px ${({ theme }) => theme.secondaryShadowColor};
    border-radius: 8px;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    width: 44px;
    height: 44px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 1023px) {
    margin: 50px auto 0 auto;
    text-align: center;
    width: 300px;
    justify-content: center;
  }
`
