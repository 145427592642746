import { InformationStyled, InformationLineContainer, InformationLine, InformationContent } from './Informations.style'
import { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { State } from 'reducers'

interface InformationProps {
  label: string
  title: string
  appearance: 'primary' | 'secondary' | 'tertiary'
  children: ReactNode
}

export const Information = ({ label, title, appearance, children }: InformationProps) => {
  const isDarkMode = useSelector((state: State) => state.darkMode)

  return (
    <InformationStyled isDarkMode={isDarkMode}>
      <InformationLineContainer>
        <InformationLine appearance={appearance} />
      </InformationLineContainer>
      <InformationContent appearance={appearance}>
        <span>{label}</span>
        <div>{title}</div>
        <p>{children}</p>
      </InformationContent>
    </InformationStyled>
  )
}
