import styled, { css } from 'styled-components/macro'

export const BiographyStyled = styled.div`
  margin-top: 200px;
  margin-bottom: 100px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 56px;
  max-width: 1440px;

  @media (max-width: 900px) {
    margin-top: 140px;
    padding: 0 20px;
  }
`

export const BiographyContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  padding-left: 133px;

  @media (max-width: 900px) {
    display: block;
    padding-left: 0px;
  }
`

export const BiographyCol = styled.div`
  flex: 50%;
  &:last-child {
    display: flex;
    justify-content: center;
  }
`

export const BiographyBack = styled.div`
  margin-bottom: 20px;
  display: flex;

  img {
    width: 14px;
  }
`

export const BiographyIcons = styled.div`
  margin-top: 16px;
  display: inline-block;
`

export const BiographyIcon = styled.span<{ isDarkMode: boolean }>`
  background: ${({ theme }) => theme.backgroundColor};
  background-blend-mode: soft-light, normal;
  border: ${({ isDarkMode }) => (!isDarkMode ? '2px solid rgba(255, 255, 255, 0.4)' : 'none')};
  box-sizing: border-box;
  box-shadow: -10px -10px 20px ${({ theme }) => theme.primaryShadowColor},
    10px 10px 20px ${({ theme }) => theme.secondaryShadowColor};
  border-radius: 8px;
  display: flex;
  padding: 8px;
  width: 36px;
  height: 36px;
`

export const BiographyParagraph = styled.p`
  margin-top: 40px;
  margin-bottom: 0px;
`

export const BiographyImageContainer = styled.div<{ isMobile?: boolean }>`
  display: ${({ isMobile }) => (isMobile ? 'none' : 'block')};
  margin-top: ${({ isMobile }) => (isMobile ? '20px' : '0')};
  margin-bottom: ${({ isMobile }) => (isMobile ? '20px' : '0')};
  background: ${({ theme }) => theme.backgroundColor};
  background-blend-mode: soft-light, normal;
  border: 2px solid rgba(255, 255, 255, 0.16);
  box-sizing: border-box;
  box-shadow: inset -5px -5px 10px ${({ theme }) => theme.primaryShadowColor},
    inset 5px 5px 10px ${({ theme }) => theme.secondaryShadowColor};
  border-radius: 40px;
  padding: 15px;
  max-width: 400px;

  @media (max-width: 1023px) {
    width: calc(100vw - 40px);
    height: calc(100vw - 40px);
    max-width: unset;
    padding: 0px;
  }

  img {
    width: 100%;
    object-fit: cover;
    object-position: top;
    border-radius: 24px;
    height: ${({ isMobile }) => (isMobile ? '450px' : '100%')};

    @media (max-width: 1023px) {
      width: calc(100vw - 70px);
      height: calc(100vw - 70px);
      transform: translate(15px, 15px);
    }
  }

  @media (max-width: 900px) {
    display: ${({ isMobile }) => (isMobile ? 'block' : 'none')};
  }
`

export const BiographyCategories = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 100px;

  @media (max-width: 1200px) {
    display: block;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

export const BiographyCategoriesResponsiveContainer = styled.div`
  position: relative;
  display: none;
  @media (max-width: 1023px) {
    display: block;
  }
`

export const BiographyCategoriesResponsive = styled.select`
  margin-top: 32px;
  margin-bottom: 16px;
  height: 64px;
  width: 100%;
  color: #000000;
  background: linear-gradient(135.37deg, rgba(0, 0, 0, 0.4) 4.29%, rgba(255, 255, 255, 0.4) 95.6%), #ebecf0;
  background-blend-mode: soft-light, normal;
  /* Neumorphic light/Stroke/White */

  border: 2px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: inset -5px -5px 10px #fafbff, inset 5px 5px 10px rgba(166, 171, 189, 0.5);
  border-radius: 8px;

  padding: 0px 16px 0px 26px;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
`

export const BiographyCategoriesResponsiveArrow = styled.img`
  width: 20px;
  height: 12px;
  position: absolute;
  top: 60px;
  right: 26px;
`

export const BiographyCategory = styled.div<{ active: boolean }>`
  padding: 10px 20px;
  cursor: pointer;

  ${(props) =>
    props.active &&
    css`
      font-weight: 500;
      background: ${({ theme }) => theme.backgroundColor};
      background-blend-mode: soft-light, normal;
      box-shadow: inset -5px -5px 10px ${({ theme }) => theme.primaryShadowColor},
        inset 5px 5px 10px ${({ theme }) => theme.secondaryShadowColor};
      border-radius: 20px;
    `};
`

export const BiographyTeam = styled.div`
  margin-top: 40px;
`
