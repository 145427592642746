import styled, { createGlobalStyle, css } from 'styled-components/macro'

export const DisableBody = createGlobalStyle`
  body{
    overflow: hidden;
  }
`
export const MenuStyled = styled.div`
  font-size: 14px;
  position: fixed;
  width: 100%;
  top: 20px;
  z-index: 400;
`

export const MenuBar = styled.div<{ showing: boolean; isDarkMode: boolean }>`
  margin: 0 auto;
  padding: 0 13px;
  max-width: 1328px;
  position: relative;
  text-align: center;
  height: ${(props) => (props.showing ? '510px' : '80px')};
  z-index: 1;
  display: grid;
  grid-template-columns: 225px repeat(2, auto) 250px;
  gap: 10px;
  font-weight: 500;
  overflow: hidden;
  transition: height 0.2s ease-in-out;
  background-color: ${({ theme }) => theme.backgroundColor};
  border: ${({ isDarkMode }) => (!isDarkMode ? '2px solid rgba(255, 255, 255, 0.4)' : 'none')};
  box-sizing: border-box;
  box-shadow: -10px -10px 20px ${({ theme }) => theme.primaryShadowColor},
    10px 10px 20px ${({ theme }) => theme.secondaryShadowColor};
  border-radius: 40px;

  > a {
    margin: 13px 0;
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 1023px) {
    grid-template-columns: auto;
    grid-template-rows: 80px fit-content(0px) 35px 50px;
  }

  @media (max-width: 1440px) {
    margin: 0px 56px;
  }

  @media (max-width: 1023px) {
    margin: 0px 16px;

    > a {
      margin: 0;
    }
  }
`

export const MenuLogoContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const MenuLogo = styled.img`
  height: 40px;
  margin: 5px 0px 5px 10px;
  width: 215px;
  display: block;

  @media (max-width: 320px) {
    width: 185px;
    height: auto;
  }
`

export const MenuNav = styled.div`
  position: relative;
  text-align: center;
  height: 80px;
  z-index: 1;
  display: grid;
  grid-template-columns: 115px 78px 104px 77px 74px 117px 150px;
  gap: 10px;

  > a,
  div {
    line-height: 80px;
    color: ${({ theme }) => theme.subTextColor};
    font-weight: 600;
    cursor: pointer;
    z-index: 2;
  }

  @media (max-width: 1400px) {
    grid-template-columns: repeat(6, 90px);

    > a:nth-child(8),
    a:nth-child(9) {
      display: none;
    }
  }

  @media (max-width: 1279px) {
    grid-template-columns: repeat(4, 90px);

    > a:nth-child(6),
    a:nth-child(7),
    a:nth-child(8),
    a:nth-child(9) {
      display: none;
    }
  }

  @media (max-width: 1023px) {
    grid-template-columns: auto;
    grid-template-rows: repeat(6, 40px);
    height: auto;
    > a,
    div {
      line-height: 40px;
    }
    > a:nth-child(6),
    a:nth-child(7),
    a:nth-child(8),
    a:nth-child(9) {
      display: block;
    }
  }
`

export const MenuSelector = styled.div<{ selected: string }>`
  position: absolute;
  top: 20px;
  left: 0px;
  width: 90px;
  height: 40px;
  background: ${({ theme }) => theme.backgroundColor};
  background-blend-mode: soft-light, normal;
  box-shadow: inset -5px -5px 10px ${({ theme }) => theme.primaryShadowColor},
    inset 5px 5px 10px ${({ theme }) => theme.secondaryShadowColor};
  border-radius: 20px;
  transform: translateX(0px);
  transition: all 500ms ease-in-out;

  ${(props) => {
    let position = 0
    let width = 0

    switch (props.selected) {
      case '':
        return css`
          display: none;
        `
      case '#home':
        return css`
          display: none;
        `
      case 'launchpad':
        position = 0
        width = 115
        break
      case 'tokenomics':
        position = 122
        width = 82
        break
      case 'roadmap':
        position = 210
        width = 104
        break
      case 'team':
        position = 324
        width = 77
        break
      case 'press':
      case 'community':
        position = 496
        width = 117
        break
      default:
        position = 0
    }

    return css`
      transform: translateX(${position}px);
      width: ${width}px;
    `
  }}

  @media (max-width: 1023px) {
    margin: 0;
    width: 100%;
    transform: translateY(200px);

    ${(props) => {
      let position = 0
      switch (props.selected) {
        case '':
          position = 0
          break
        case '#home':
          position = 0
          break
        case 'launchpad':
          position = -20
          break
        case 'tokenomics':
          position = 30
          break
        case 'roadmap':
          position = 80
          break
        case 'team':
          position = 130
          break
        case 'press':
        case 'community':
          position = 230
          break
        default:
          position = 0
      }

      return css`
        transform: translateY(${position}px);
      `
    }}
  }
`

export const MenuDiscordLink = styled.a`
  > span {
    height: unset;
  }

  @media (max-width: 1023px) {
    justify-content: center !important;
  }
`

export const MenuDocs = styled.div<{ isDarkMode: boolean }>`
  position: absolute;
  top: 90px;
  right: calc(50vw - 160px);
  padding: 22px 32px;
  display: grid;
  grid-template-rows: repeat(4, 30px);
  grid-gap: 10px;
  background-color: ${({ theme }) => theme.backgroundColor};
  background-blend-mode: soft-light, normal;
  border: ${({ isDarkMode }) => (!isDarkMode ? '2px solid rgba(255, 255, 255, 0.4)' : 'none')};
  box-sizing: border-box;
  box-shadow: -10px -10px 20px ${({ theme }) => theme.primaryShadowColor},
    10px 10px 20px ${({ theme }) => theme.secondaryShadowColor};
  border-radius: 20px;
  z-index: 2;

  > a {
    font-weight: 500;
    line-height: 30px;
  }

  @media (max-width: 1400px) {
    right: calc(50vw - 340px);
  }

  @media (max-width: 1023px) {
    left: 0;
    right: 0;
    margin: auto;
    width: 250px;
    top: 220px;
  }
`

export const MenuCommunity = styled(MenuDocs)<{ isDarkMode: boolean }>`
  grid-template-rows: repeat(3, 30px);
  right: calc(50vw - 210px);

  @media (max-width: 1023px) {
    width: 150px;
    top: 280px;
  }
`

export const MenuSwitchContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 22px 14px 22px;

  @media (max-width: 1023px) {
    justify-content: center;
  }
`

export const MenuSwitchImg = styled.img<{ pointer?: boolean }>`
  cursor: ${(props) => (props.pointer === undefined ? 'pointer' : '')};
`
