import { useEffect, useRef, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'

import { Button } from '../Button/Button.view'
import { Hamburger } from './Hamburger/Hamburger.view'
import { DisableBody, MenuBar, MenuCommunity, MenuDiscordLink, MenuDocs, MenuLogo, MenuLogoContainer, MenuNav, MenuSelector, MenuStyled, MenuSwitchContainer, MenuSwitchImg } from './Menu.style'

interface MenuViewProps {
  switchMode: (type: string) => void
  isDarkMode: boolean
}

export const MenuView = ({ switchMode, isDarkMode }: MenuViewProps) => {
  const [showing, setShowing] = useState(false)
  const [docsOpen, setDocsOpen] = useState(false)
  const [communityOpen, setCommunityOpen] = useState(false)

  const history = useHistory()
  const { pathname, hash } = useLocation()

  let selected = pathname.replace('/', '') || hash
  if (selected.includes('team')) {
    selected = 'team'
  }

  useEffect(() => {
    if (!hash) {
      window.scrollTo({ behavior: 'smooth', top: 0 })
    }
    setShowing(false)
    setDocsOpen(false)
    setCommunityOpen(false)
  }, [pathname, hash])

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      // Alert if clicked on outside of element
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDocsOpen(false)
          setCommunityOpen(false)
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  const wrapperDocs = useRef(null)
  const wrapperCommunity = useRef(null)
  useOutsideAlerter(wrapperDocs)
  useOutsideAlerter(wrapperCommunity)
  return (
    <MenuStyled>
      {showing && <DisableBody />}
      {docsOpen && (
        <MenuDocs isDarkMode={isDarkMode} ref={wrapperDocs}>
          <a
            href="https://storage.googleapis.com/terraform-csp-prod-public-uploads-vbcklz/docs/whitepaper-v1.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Whitepaper
          </a>
          <a
            href="https://storage.googleapis.com/terraform-csp-prod-public-uploads-vbcklz/docs/overview-v1.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Overview
          </a>
          <a
            href="https://storage.googleapis.com/terraform-csp-prod-public-uploads-vbcklz/docs/slide-deck-v2.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Slide Deck
          </a>
          <a
            href="https://storage.googleapis.com/terraform-csp-prod-public-uploads-vbcklz/docs/terms-v1.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Terms and Conditions
          </a>
        </MenuDocs>
      )}
      {communityOpen && (
        <MenuCommunity isDarkMode={isDarkMode} ref={wrapperCommunity}>
          <Link to="/community">Join</Link>
          <a href="https://cardashift.medium.com" rel="noreferrer" target="_blank">
            Blog
          </a>
          <Link to="/press">Press</Link>
        </MenuCommunity>
      )}
      <MenuBar showing={showing} isDarkMode={isDarkMode}>
        <MenuLogoContainer>
          <MenuLogo
            alt="Cardashift"
            src={isDarkMode ? '/images/dark-logo.png' : '/images/light-logo.png'}
            onClick={() => {
              history.push('/')
              setShowing(false)
            }}
          />
        </MenuLogoContainer>
        <MenuNav>
          <MenuSelector selected={selected} />
          <Link to="/launchpad">Launchpad</Link>
          <Link to="/tokenomics">CLAP</Link>
          <Link to="/roadmap">Roadmap</Link>
          <Link to="/team">Team</Link>
          <div onClick={() => setDocsOpen(!docsOpen)}>Docs</div>
          <div onClick={() => setCommunityOpen(!docsOpen)}>Community</div>
        </MenuNav>
        <MenuSwitchContainer>
          <MenuSwitchImg
            src={isDarkMode ? '/images/logo-mode-light-dark.svg' : '/images/logo-mode-light.svg'}
            alt=""
            onClick={() => {
              switchMode('light')
            }}
          />
          <MenuSwitchImg
            src={isDarkMode ? '/images/logo-mode-separator-dark.svg' : '/images/logo-mode-separator.svg'}
            alt=""
            pointer={false}
          />
          <MenuSwitchImg
            src={isDarkMode ? '/images/logo-mode-dark-dark.svg' : '/images/logo-mode-dark.svg'}
            alt=""
            onClick={() => {
              switchMode('dark')
            }}
          />
        </MenuSwitchContainer>
        <MenuDiscordLink href="https://launchpad.cardashift.com" target="_blank" rel="noreferrer">
          <Button appearance="secondary">Launch app</Button>
        </MenuDiscordLink>
        <Hamburger
          showing={showing}
          setShowing={() => {
            setShowing(!showing)
            setDocsOpen(false)
          }}
        />
      </MenuBar>
    </MenuStyled>
  )
}
