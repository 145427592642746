import styled, { css } from 'styled-components/macro'

export const CardContainer = styled.div<{ rotate?: string; isDarkMode: boolean; borderRadius: number }>`
  cursor: pointer;
  background-image: ${({ isDarkMode }) =>
    !isDarkMode
      ? 'radial-gradient(80.69% 209.15% at 108.28% 112.69%, #ffffffbb 0%, #ffffff00 100%), radial-gradient(80.38% 222.89% at -13.75% -12.47%, #ffffff 50%, #b6bac0 100%)'
      : 'radial-gradient(80.69% 209.15% at 108.28% 112.69%, #ffffffbb 0%, #ffffff00 100%), radial-gradient(80.38% 222.89% at -13.75% -12.47%, #ffffff 20%, #0e1015 100%)'};
  padding: 2px;
  display: flex;
  position: relative;
  border-radius: ${({ borderRadius }) => borderRadius}px;

  ${({ rotate }) => {
    if (rotate === 'left') {
      return css`
        transform: perspective(160px) rotateY(0.5deg);

        > div > div {
          transform: perspective(160px) rotateY(-0.5deg);
        }
      `
    }

    if (rotate === 'right') {
      return css`
        transform: perspective(160px) rotateY(-0.5deg);
        margin-bottom: 10px;

        > div > div {
          transform: perspective(160px) rotateY(0.5deg);
        }
      `
    }

    return css``
  }};
`

export const CardStyled = styled.div<{ isDarkMode: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: ${({ isDarkMode }) => (!isDarkMode ? 'rgb(235, 236, 240)' : 'rgba(18, 30, 52, 0.8)')};
`
