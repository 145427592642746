import styled from 'styled-components/macro'

export const ArticleStyled = styled.a<{ isDarkMode: boolean }>`
  background-color: ${({ theme }) => theme.backgroundColor};
  background-blend-mode: soft-light, normal;
  border: ${({ isDarkMode }) =>
    !isDarkMode ? '2px solid rgba(255, 255, 255, 0.4)' : '2px solid rgba(255, 255, 255, 0.05)'};
  box-sizing: border-box;
  box-shadow: -10px -10px 20px ${({ theme }) => theme.primaryShadowColor},
    10px 10px 20px ${({ theme }) => theme.secondaryShadowColor};
  border-radius: 8px;
  padding: 10px;

  &:hover {
    opacity: 1;
  }

  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
  }

  p {
    margin: 0 10px;
    opacity: 0.7;
    color: ${({ isDarkMode, theme }) => (!isDarkMode ? theme.subTextColor : theme.textColor)};
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */

    letter-spacing: 0.02em;
  }
`

export const ArticleTitle = styled.div<{ isDarkMode: boolean }>`
  color: ${({ isDarkMode }) => (!isDarkMode ? '#0d0f3f' : 'white')};
  padding: 20px 10px;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
`
