import { Blog } from 'app/App.components/Blog/Blog.view'
import { Button } from 'app/App.components/Button/Button.view'
import { Card } from 'app/App.components/Card/Card.view'
import { Footer } from 'app/App.components/Footer/Footer.view'
import { JoinCommunity } from 'app/App.components/JoinCommunity/JoinCommunity.view'
import { Title } from 'app/App.components/Title/Title.view'

// prettier-ignore
import {
  PressContainer,
  PressTopBubbles,
  PressFirstContainer,
  PressInquiryBubbles,
  PressInquiryBubblesResponsive,
  PressInquirySubText,
  PressTopBubblesResponsive,
  PressInquiryButtonRow,
  PressInquiryCardContainer,
  PressInquiryForm,
  PressInquiryTitle,
  PressStyled,
  PressVideoContainer,
  PressVideosGrid,
  SubTitle,
  PressBubblesContainer,
} from './Press.style'
import { PressVideo } from './PressVideo/PressVideo.view'

interface PressViewProps {
  isDarkMode: boolean
  showModal: (title: string, children: JSX.Element) => void
}

export const PressView = ({ isDarkMode, showModal }: PressViewProps) => {
  let blogArticles = [
    {
      title: 'Cardashift, le premier launchpad communautaire sur Cardano (ADA)',
      link: 'https://coinacademy.fr/defi/cardashift-le-premier-launchpad-communautaire-sur-cardano-ada/',
      image: '/images/articles/coin_academy.png',
      description:
        'Cardashift est le premier launchpad communautaire basé sur la blockchain Cardano (ADA) qui vise à financer et accélérer de nouveaux projets résolvant des problèmes sociétaux et environnementaux du monde entier.',
    },
    {
      title: 'Cardashift: le launchpad sur Cardano qui répond aux enjeux auxquels le monde fait face',
      link: 'https://fr.cryptonews.com/news/cardashift-le-launchpad.htm',
      image: '/images/articles/crypto_news.png',
      description:
        "Dans l'entreprenariat, le nerf de la guerre demeure bien souvent le même: l’argent. Quels que soient leurs domaines d’activité, la grande majorité des startups a besoin de trouver du financement pour développer leurs produits et leurs services.",
    },
    {
      title: 'Cardashift – The Community-Run Launchpad That Fosters Sustainable Development',
      link: 'https://cryptodaily.io/cardashift-the-community-run-launchpad-that-fosters-sustainable-development/',
      image: '/images/articles/crypto_daily.png',
      description:
        'Cardashift empowers changemakers through an ecosystem of products and services anchored by the $CLAP utility token and designed to promote sustainable development in all of its dimensions, including social and environmental.',
    },
    {
      title: 'Cardashift Is Building a Cardano-Powered Launchpad For Sustainable Impact',
      link: 'https://decrypt.co/90348/cardashift-is-building-a-cardano-powered-launchpad-for-sustainable-impact',
      image: '/images/articles/decrypt.png',
      description:
        'From tokenized factories to aquaculture, Cardashift will support projects in the digital and physical spheres.',
    },
  ]

  let videos = [
    {
      title: 'TELESUD',
      subTitle: 'Business Models "La blockchain est aux avant postes de l’innovation en Afrique" ',
      link: 'https://www.youtube.com/watch?v=zu25MsPGOFY',
    },
    {
      title: 'BlockchainHEC',
      subTitle: '101 Cardashift x Blockchain HEC with Tangui Friand and Yannis Baala',
      link: 'https://www.youtube.com/watch?v=lfmPUU509T4',
    },
    {
      title: 'Cardano Daily',
      subTitle: 'Livestream AMA with Cardashift',
      link: 'https://www.youtube.com/watch?v=2yCYPtqMjqo',
    },
    {
      title: 'Podcast Cardano FR ',
      subTitle: 'Podcast Cardano FR #130 : CARDASHIFT - DEBUG THE WORLD',
      link: 'https://www.youtube.com/watch?v=oVbAR7Mp930',
    },
    {
      title: 'Maria Carmo 369  ',
      subTitle:
        'SuCatalyst Fund 7 Accelerator & Mentors. Project Cardashift Trishala Adwaine Impact Assessment Leadbtitle',
      link: 'https://www.youtube.com/watch?v=EPVlAZqsu5Q',
    },
    {
      title: 'Maria Carmo 369  ',
      subTitle: 'Cardashift Launchpad CEO Frederic Arnoux Social and Environmental impact #cardashift #cardano #ada',
      link: 'https://www.youtube.com/watch?v=tD4iWslL6mU',
    },
    {
      title: 'Cardanistas',
      subTitle: 'Cardashift - Live para Tirar Dúvidas sobre o projeto (PT)',
      link: 'https://www.youtube.com/watch?v=dVpyWgcBifU',
    },
    {
      title: 'IOHK',
      subTitle: 'Cardano360 - January 2022',
      link: 'https://www.youtube.com/watch?v=yaa8Vys1Sms',
    },
    {
      title: 'Learn Cardano',
      subTitle: 'CardaShift, Impact Launchpad IDO platform built on Cardano',
      link: 'https://www.youtube.com/watch?v=5sXv3i46Lf0',
    },
  ]
  return (
    <PressContainer>
      <PressTopBubbles src="/images/press-top-bubbles.png" alt="" />
      <PressTopBubblesResponsive src="/images/press-top-bubbles-responsive.png" alt="" />
      <PressBubblesContainer>
        <PressStyled>
          <PressFirstContainer>
            <Title label="CARDASHIFT MEDIA FEATURE" fontSize={[48, 26]}>
              Press
            </Title>
            <SubTitle>Keep track of Cardashift’s latest media features around the world</SubTitle>
          </PressFirstContainer>
          <Blog articles={blogArticles} withTitle={false} withViewMore={false} />
          <PressVideoContainer>
            <Title fontSize={[48, 26]}>Videos</Title>
            <PressVideosGrid>
              {videos.map((item) => {
                return (
                  <PressVideo isDarkMode={isDarkMode} title={item.title} subTitle={item.subTitle} link={item.link} />
                )
              })}
            </PressVideosGrid>
          </PressVideoContainer>
          <PressInquiryCardContainer>
            <Card>
              <PressInquiryForm>
                <PressInquiryTitle>Press Inquiry</PressInquiryTitle>
                <PressInquirySubText>
                  Want to share our story or learn more?
                  <br />
                  Reach out to our team today.
                </PressInquirySubText>
                <PressInquiryButtonRow>
                  <Button
                    appearance={'primary'}
                    icon="arrow"
                    clickCallback={() =>
                      window.open('https://forms.monday.com/forms/6454730e1f95e7ce8315979226dc8d27?r=use1', '_blank')
                    }
                  >
                    Submit a request
                  </Button>
                </PressInquiryButtonRow>
              </PressInquiryForm>
            </Card>
          </PressInquiryCardContainer>
        </PressStyled>
        <PressInquiryBubbles src="/images/press-inquiry-bubbles.png" alt="" />
        <PressInquiryBubblesResponsive src="/images/press-inquiry-bubbles-responsive.png" alt="" />
      </PressBubblesContainer>
      <JoinCommunity />
      <Footer />
    </PressContainer>
  )
}
