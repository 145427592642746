import styled, { css } from 'styled-components/macro'

export const InformationStyled = styled.div<{ isDarkMode: boolean }>`
  background-color: ${({ theme }) => theme.backgroundColor};
  background-blend-mode: soft-light, normal;
  border: ${({ isDarkMode }) => (!isDarkMode ? '1px solid rgba(255, 255, 255, 0.4)' : 'none')};
  box-sizing: border-box;
  box-shadow: inset -5px -5px 10px ${({ theme }) => theme.primaryShadowColor},
    inset 5px 5px 10px ${({ theme }) => theme.secondaryShadowColor};
  border-radius: 8px;
  flex: calc(100% / 3 - 20px);
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 900px) {
    flex: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
`

export const InformationLineContainer = styled.div`
  padding: 6px;
`

export const InformationLine = styled.div<{ appearance: string }>`
  ${({ appearance, theme }) => {
    let elementBackgroundColor = theme.primaryColor

    switch (appearance) {
      case 'primary':
        elementBackgroundColor = theme.primaryColor
        break
      case 'secondary':
        elementBackgroundColor = theme.secondaryColor
        break
      case 'tertiary':
        elementBackgroundColor = theme.alternativeTextColor
        break
      default:
        elementBackgroundColor = theme.primaryColor
    }

    return css`
      background: ${elementBackgroundColor};
    `
  }}

  height: 4px;
  border-radius: 8px;
`

export const InformationContent = styled.div<{ appearance: string }>`
  padding: 20px;
  span {
    ${({ appearance, theme }) => {
      let elementBackgroundColor = theme.primaryColor

      switch (appearance) {
        case 'primary':
          elementBackgroundColor = theme.primaryColor
          break
        case 'secondary':
          elementBackgroundColor = theme.secondaryColor
          break
        case 'tertiary':
          elementBackgroundColor = theme.alternativeTextColor
          break
        default:
          elementBackgroundColor = theme.primaryColor
      }

      return css`
        color: ${elementBackgroundColor};
      `
    }}

    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    display: inline-block;
    margin-bottom: 6px;
  }

  div {
    font-weight: bold;
    font-size: 18px;
  }
`
