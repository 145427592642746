import { ReactNode } from 'react'

import { BannerLegend, BannerStyled, BannerText } from './Banner.style'

interface BannerProps {
  children: ReactNode
  legend: string
}

export const Banner = ({ children, legend }: BannerProps) => (
  <BannerStyled>
    <video autoPlay loop muted playsInline preload="auto" poster="/video/bloopbloop.png">
      <source src="/videos/earth.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <div>
      <BannerText>{children}</BannerText>
      <BannerLegend>{legend}</BannerLegend>
    </div>
  </BannerStyled>
)
