import { Article } from 'app/App.components/Article/Article.view'
import { Banner } from 'app/App.components/Banner/Banner.view'
import { Blog } from 'app/App.components/Blog/Blog.view'
import { Button } from 'app/App.components/Button/Button.view'
import { Card } from 'app/App.components/Card/Card.view'
import { Companies } from 'app/App.components/Companies/Companies.view'
import { Footer } from 'app/App.components/Footer/Footer.view'
import { FundingStages } from 'app/App.components/FundingStages/FundingStages.view'
import { GetClap } from 'app/App.components/GetClap/GetClap.controller'
import { JoinCommunity } from 'app/App.components/JoinCommunity/JoinCommunity.view'
import { Partners } from 'app/App.components/Partners/Partners.view'
import { Social } from 'app/App.components/Social/Social.view'
import { Title } from 'app/App.components/Title/Title.view'
import { Link } from 'react-router-dom'

// prettier-ignore
import { HomeBackBubbles, HomeBackBubbles2, HomeBanner, HomeCard, HomeCardContainer, HomeCardIllustration, HomeContainer, HomeFirstContainer, HomeIndicator, HomeParagraph, HomePartners, HomePartnersTitle, HomePressArticlesContainer, HomePressContainer, HomeSocial, HomeStyled, HomeTitle, HomeTitleButtons, ImageHome, ImageHomeResponsive, ImgCompanies } from './Home.style'

interface HomeViewProps {
  isDarkMode: boolean
  showModal: (title: string, children: JSX.Element) => void
}

export const HomeView = ({ isDarkMode, showModal }: HomeViewProps) => {
  let blogArticles = [
    {
      title: 'Cardashift raises $10M to foster impact investing through Cardano blockchain',
      link: 'https://cardashift.medium.com/cardashift-raises-10m-to-foster-impact-investing-through-cardano-blockchain-9834c4415c36',
      image: 'https://miro.medium.com/max/1400/1*TKwz9OpdZCb69cq7isWqng.png',
      description:
        'After securing $1.5M in funding during an early Private Sale last October, just 3 months after its creation, Cardashift is thrilled to announce the successful closing of its ICO',
    },
    {
      title: 'Cardashift Flash Sale BLASTED: 3M ADA in less than 10 minutes',
      link: 'https://cardashift.medium.com/cardashift-flash-sale-blasted-3m-ada-in-less-than-10-minutes-d79c08631f8b',
      image: 'https://miro.medium.com/max/1400/1*d8S_OLyis2AOa6kOSkvPwA.png',
      description:
        'The Flash Sale was closed once we received the awaited 1.1M ADA, but ADAs continued to flood in due to the transaction time. Only a few minutes were necessary to exceed the initial expectation.',
    },
    {
      title: 'Orange DAO, a Y Combinator alumni DAO, becomes a key investor of Cardashift',
      link: 'https://cardashift.medium.com/orange-dao-a-y-combinator-alumni-dao-becomes-a-key-investor-of-cardashift-818e38ed2619',
      image: 'https://miro.medium.com/max/1400/1*80fDnITveUYPHfmbpBy7KA.png',
      description:
        'Cardashift’s team is thrilled to announce Orange DAO as a key investor. It’s a step forward in fulfilling our mission to foster sustainable development with products and services powered by blockchain technologies.',
    },
    {
      title: 'Cardashift appoints Yvan Depierre, CEO of JUA & ex-CFO of Apple France, as Advisor',
      link: 'https://cardashift.medium.com/cardashift-appoints-yvan-depierre-ceo-of-jua-ex-cfo-of-apple-france-as-advisor-884d2347e37',
      image: 'https://miro.medium.com/max/1400/1*FJ23rVoGt1upc7lxMlb6ng.jpeg',
      description: 'Cardashift is delighted to announce the appointment of Yvan Depierre as Advisor.',
    },
  ]

  return (
    <HomeContainer>
      {window.innerWidth >= 800 && (
        <video
          key={isDarkMode ? '/videos/bloop-dark2.png' : '/videos/bloop-light2.png'}
          autoPlay
          loop
          muted
          playsInline
          preload="auto"
          poster={isDarkMode ? '/videos/bloop-dark2.png' : '/videos/bloop-light2.png'}
        >
          <source src={isDarkMode ? '/videos/bloop-dark.mp4' : '/videos/bloop-light.mp4'} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}

      <HomeStyled>
        <HomeTitle>Debug the world</HomeTitle>
        <HomeParagraph>
          Cardashift is a community-run launchpad that raises funds, builds and accelerates startups that are solving
          social and environmental issues.
          <br />
          <br />
          CLAP utility token bring Cardashift's ecosystem to life.
        </HomeParagraph>
        <HomeTitleButtons>
          <a href="https://dao.cardashift.com/home" target="_blank" rel="noreferrer">
            <Button appearance="secondary">Vote for our Roadmap</Button>
          </a>
          <Button appearance="tertiary" icon="logo-clap.png" clickCallback={() => showModal('GET CLAP', <GetClap />)}>
            Get CLAP
          </Button>
        </HomeTitleButtons>
        <HomeSocial>
          <Social />
        </HomeSocial>
        <HomeIndicator>
          Powered by <img src={isDarkMode ? '/images/cardano-dark.svg' : '/images/cardano-light.svg'} alt="Cardano" />
        </HomeIndicator>
      </HomeStyled>

      <HomeStyled>
        <HomeFirstContainer>
          <Title label="Cardashift in 1'" fontSize={[48, 26]}>
            Get a glimpse of Cardashift
          </Title>
          <video
            key={isDarkMode ? '/videos/bloop-dark.png' : '/videos/bloop-light.png'}
            poster="/videos/pres-cardashift.png"
            controls
          >
            <source src="/videos/pres-cardashift.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <HomeBackBubbles2 src="/images/home-background-bubbles.png" alt="" />
        </HomeFirstContainer>
      </HomeStyled>
      <HomeCardIllustration isDarkMode={isDarkMode}>
        <HomeBackBubbles src="/images/home-back-bubbles.png" alt="" />
        <HomeStyled>
          <HomeCardContainer align="left">
            <Card rotate="left">
              <HomeCard>
                <Title label="Bringing rigor to dreams" fontSize={[48, 26]}>
                  Assess, derisk, and support (&nbsp;tokenized&nbsp;) impact ventures
                </Title>
                <ImageHome src="/images/home-card-content.png" alt=""></ImageHome>
                <ImageHomeResponsive src="/images/home-card-content-responsive.svg" alt=""></ImageHomeResponsive>
                <section>
                  <Link to="/launchpad">
                    <Button appearance="primary">Learn&nbsp;more&nbsp;about&nbsp;Cardashift&nbsp;Launchpad</Button>
                  </Link>
                </section>
              </HomeCard>
            </Card>
          </HomeCardContainer>
          <HomeCardContainer align="right">
            <Card rotate="right">
              <HomeCard>
                <Title label="Facilitating mass adoption" fontSize={[48, 26]}>
                  Build the tools for visionaries to create new economic paradigms
                </Title>
                <p>
                  Cardashift will release several products to make the community and accelerated projects
                  self-sufficient and sustainable: DeX, Universal Wallet, STO platform, DAO launcher, etc. - token
                  powers this new ecosystem and is key to enhancing community-driven governance while empowering token
                  holders with several advantages.
                </p>
                <section>
                  <Link to="/roadmap">
                    <Button appearance="primary">Discover&nbsp;our&nbsp;Product&nbsp;Roadmap</Button>
                  </Link>
                  <Link to="/tokenomics">
                    <Button appearance="tertiary" icon="arrow_white">
                      See&nbsp;CLAP&nbsp;tokenomics
                    </Button>
                  </Link>
                </section>
              </HomeCard>
            </Card>
          </HomeCardContainer>
        </HomeStyled>
      </HomeCardIllustration>
      <HomeStyled disableRelative={true}>
        <FundingStages />
        <HomePartnersTitle>
          <Title label="Creating a multidisciplinary environment" fontSize={[48, 26]}>
            Meet Cardashift Partners
          </Title>
        </HomePartnersTitle>
        <HomePartners>
          <Partners />
        </HomePartners>
      </HomeStyled>
      <Companies />
      <ImgCompanies src={isDarkMode ? '/images/companies-dark.png' : '/images/companies-light.png'} />
      <HomePressContainer>
        <Title label="PRESS" fontSize={[48, 26]}>
          Latest Mentions
        </Title>
        <HomePressArticlesContainer>
          <Article
            image="/images/press1.png"
            link="https://coinacademy.fr/defi/cardashift-le-premier-launchpad-communautaire-sur-cardano-ada/"
            title="Coin Academy"
          >
            Cardashift, le premier launchpad communautaire sur Cardano (ADA)
          </Article>
          <Article
            image="/images/press2.png"
            link="https://fr.cryptonews.com/news/cardashift-le-launchpad.htm"
            title="Crypto News"
          >
            Cardashift: le launchpad sur Cardano qui répond aux enjeux auxquels le monde fait face
          </Article>
          <Article
            image="/images/press3.png"
            link="https://cryptodaily.io/cardashift-the-community-run-launchpad-that-fosters-sustainable-development/"
            title="Crypto Daily"
          >
            Cardashift – The Community-Run Launchpad That Fosters Sustainable Development
          </Article>
          <Article
            image="/images/press4.png"
            link="https://decrypt.co/90348/cardashift-is-building-a-cardano-powered-launchpad-for-sustainable-impact"
            title="Decrypt"
          >
            Cardashift Is Building a Cardano-Powered Launchpad For Sustainable Impact
          </Article>
        </HomePressArticlesContainer>
      </HomePressContainer>
      <HomeBanner>
        <Banner legend="Charles Hoskinson - founder of Cardano">
          “We’re Cardano, and if you’ll permit us, we would like to change the world”
        </Banner>
      </HomeBanner>
      <HomeStyled>
        <Blog articles={blogArticles} />
      </HomeStyled>
      <JoinCommunity />
      <Footer />
    </HomeContainer>
  )
}
