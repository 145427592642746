import styled from 'styled-components/macro'

export const TeamName = styled.div`
  font-family: 'Nizzoli';
  font-weight: 900;
  font-size: 24px;
  margin-bottom: 20px;
`

export const Persons = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: 1100px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

export const PersonContainer = styled.div<{ isSelected?: boolean }>`
  background-color: ${({ theme }) => theme.backgroundColor};
  background-blend-mode: soft-light, normal;
  border: 2px solid rgba(255, 255, 255, 0.16);
  box-sizing: border-box;
  box-shadow: inset -5px -5px 10px ${({ theme }) => theme.primaryShadowColor},
    inset 5px 5px 10px ${({ theme }) => theme.secondaryShadowColor};
  border-radius: 40px;
  color: ${({ theme }) => theme.backgroundTextColor};
  opacity: ${({ isSelected }) => (isSelected ? '0.5' : '1')};
  position: relative;
  padding: 10px;
  margin-bottom: 20px;
  img {
    object-fit: cover;
    object-position: top;
    width: 100%;
    height: 100%;
    border-radius: 24px;
  }

  div:last-child {
    position: absolute;
    font-size: 12px;
    bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;

    span {
      display: block;
      font-weight: bold;
      margin-bottom: 6px;
      text-shadow: 1px 1px 1px black;

      &:first-child {
        font-weight: normal;
        margin-bottom: 2px;
      }
    }

    a {
      display: flex;
      height: 24px;
      width: 24px;
      padding: 4px;
      border-radius: 4px;
      background-color: ${({ theme }) => theme.backgroundTextColor};

      img {
        width: 100%;
      }
    }
  }
`

export const AlternativeLink = styled.div`
  cursor: pointer;
`
