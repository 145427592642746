import { Biography } from 'pages/Biography/Biography.view'
// import { BloopTest } from 'pages/BloopTest/BloopTest.view'
import { Community } from 'pages/Community/Community.view'
import { Home } from 'pages/Home/Home.controller'
import { Launchpad } from 'pages/Launchpad/Launchpad.view'
import { Press } from 'pages/Press/Press.controller'
import { Roadmap } from 'pages/Roadmap/Roadmap.view'
import { Team } from 'pages/Team/Team.view'
import { Tokenomics } from 'pages/Tokenomics/Tokenomics.controller'
import { useSelector } from 'react-redux'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { State } from 'reducers'
import { ThemeProvider } from 'styled-components/macro'
import { GlobalStyle } from 'styles'
import { darkTheme, lightTheme } from 'styles/theme'

import { Menu } from './App.components/Menu/Menu.controller'
import { Modal } from './App.components/Modal/Modal.controller'
import { Popup } from './App.components/Popup/Popup.controller'
import { Toaster } from './App.components/Toaster/Toaster.controller'
import { configureStore } from './App.store'

export const store = configureStore({})

export const AppRouter = () => {
  const isDarkMode = useSelector((state: State) => state.darkMode)

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <GlobalStyle />
      <Router>
        <Menu />
        <Popup />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/team" component={Team} />
          <Route exact path="/team/:slug" component={Biography} />
          <Route exact path="/roadmap" component={Roadmap} />
          <Route exact path="/launchpad" component={Launchpad} />
          <Route exact path="/tokenomics" component={Tokenomics} />
          <Route exact path="/community" component={Community} />
          {/* <Route exact path="/bloop-test" component={BloopTest} /> */}
          <Route exact path="/press" component={Press} />
        </Switch>
        <Toaster />
        <Modal />
      </Router>
    </ThemeProvider>
  )
}
