import styled, { css } from 'styled-components/macro'

export const LaunchpadContainer = styled.div`
  margin-top: 140px;

  @media (max-width: 1023px) {
    margin-top: 0px;
  }
`

export const LaunchpadStyled = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding: 0 56px;
  max-width: 1440px;

  @media (max-width: 1023px) {
    padding: 0 20px;
  }
`

export const LaunchpadIllustrationImage = styled.img`
  width: 100%;
  @media (max-width: 1023px) {
    display: none;
  }
`
export const LaunchpadIllustrationImageResponsive = styled.img`
  display: none;
  @media (max-width: 1023px) {
    display: block;
    width: calc(100% + 20px);
    margin-top: 23px;
  }
`
export const LaunchpadIllustrationSection = styled.section`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`

export const LaunchpadIllustrationLeft = styled.div`
  width: 30%;
  margin-top: 200px;

  div {
    margin-bottom: 20px;
  }
  h2 {
    font-family: Nizzoli;
    font-style: normal;
    font-weight: 900;
    font-size: 65px;
    line-height: 130%;
  }

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 150px;
    h2 {
      font-size: 39px;
    }
  }
`
export const LaunchpadIllustrationRight = styled.div`
  width: 60%;

  @media (max-width: 1023px) {
    width: 100%;
  }
`

export const LaunchpadCenteredParagraph = styled.p`
  margin-top: 100px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 765px;
  text-align: center;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
`

export const LaunchpadButtons = styled.p`
  display: flex;
  justify-content: center;

  a:last-child {
    margin-left: 16px;
  }

  @media (max-width: 500px) {
    display: block;

    a:last-child {
      display: inline-block;
      margin-left: 0;
      margin-top: 20px;
    }

    a {
      width: 100%;
      max-width: 100%;
    }

    a > span {
      width: 100%;
      max-width: 100%;
      display: flex;
      justify-content: center;
    }

    a > span > svg {
      margin: 0px 0px 0px 11px;
    }
  }
`

export const LaunchpadLeftWave = styled.div<{ isDarkMode: boolean }>`
  background-image: ${({ isDarkMode }) =>
    isDarkMode ? "url('/images/dark-left-waves.png')" : "url('/images/light-left-waves.png')"};
  background-repeat: no-repeat;
  position: absolute;
  z-index: -1;

  background-size: auto 100%;
  width: 90vw;
  height: 800px;
  top: -450px;

  @media (max-width: 1023px) {
    background-size: auto 100%;
    width: 90vw;
    height: 500px;
    top: -300px;
  }
`
export const LaunchpadCriteriasIllustration = styled.div`
  position: relative;
`

export const LaunchpadCriterias = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 140px;
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
  position: relative;
  right: -100px;

  img {
    max-width: 323px;
  }

  @media (max-width: 1100px) {
    position: initial;
    right: 0;
  }

  @media (max-width: 1023px) {
    flex-direction: column-reverse;
    margin-top: 97px;

    img {
      margin-top: 9px;
    }
  }
`

export const LaunchpadCriteriaTitle = styled.div`
  margin-bottom: 32px;
`

export const LaunchpadCriteria = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
`

export const LaunchpadCriteriaPoint = styled.div`
  height: 9px;
  width: 9px;
  border-radius: 20px;
  margin-right: 16px;
  border: 2px solid rgba(255, 255, 255, 0.4);
`

export const LaunchpadImpact = styled.section`
  margin-top: 140px;

  p {
    line-height: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    /* identical to box height, or 27px */

    color: ${({ theme }) => theme.subTextColor};
  }
`

export const LaunchpadImpactDesktopImage = styled.img`
  width: 100%;

  @media (max-width: 600px) {
    display: none;
  }
`

export const LaunchpadImpactMobileImage = styled.img`
  width: 100%;
  display: none;

  @media (max-width: 600px) {
    display: initial;
  }
`

export const LaunchpadImpactButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`

export const LaunchpadProcess = styled.section`
  margin-top: 140px;

  p {
    line-height: 24px;
    margin-top: 20px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
  }

  img {
    margin-top: 20px;
    width: 100%;
  }

  @media (max-width: 800px) {
    img {
      display: none;
    }
  }
`

export const LaunchpadStagesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  margin-top: 50px;

  @media (max-width: 768px) {
    grid-template-columns: 100%;
    grid-template-rows: repeat(3, auto);
  }
`

export const LaunchpadStageCard = styled.div<{ isDarkMode: boolean }>`
  border-radius: 8px;
  padding: 20px 20px 50px 20px;
  position: relative;

  ${({ isDarkMode }) => {
    if (isDarkMode) {
      return css`
        background: #13203D;
        box-shadow: inset -4px -4px 4px rgba(58, 68, 93, 0.5), inset 4px 4px 4px #060c18;
      `
    } else {
      return css`
        background: linear-gradient(317.7deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.4) 105.18%), #ebecf0;
        background-blend-mode: soft-light, normal;

        border: 0.438063px solid rgba(255, 255, 255, 0.4);
        box-shadow: inset -2.19031px -2.19031px 4.38063px #fafbff, inset 2.19031px 2.19031px 4.38063px #a6abbd;
      `
    }
  }}

  @media (max-width: 768px) {
    padding: 20px 9px 50px 9px;
  }
`

export const LaunchpadStageTitle = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 16px;
  text-transform: uppercase;

  color: ${({ theme }) => theme.textColor};
  margin: 0px;

  @media (max-width: 768px) {
    padding: 0px 8px;
  }
`

export const LaunchpadStageCardBlocksContainer = styled.div`
  margin-top: 33px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 0px 11px;
  }
`
export const LaunchpadStageCardBlock = styled.div<{ height: number; isDarkMode: boolean }>`
  padding: 9px 0px;
  border-radius: 8px;
  margin-bottom: 12px;

  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: ${({ height }) => `${height}px`};

  ${({ isDarkMode }) => {
    if (isDarkMode) {
      return css`
        background: #13203D;
        box-shadow: 5px 5px 15px rgba(5, 12, 27, 0.6);
      `
    } else {
      return css`
        border: 1px solid rgba(255, 255, 255, 0.4);
        background: linear-gradient(135.37deg, rgba(0, 0, 0, 0.4) 4.29%, rgba(255, 255, 255, 0.4) 95.6%), #ebecf0;
        background-blend-mode: soft-light, normal;
        box-shadow: -5px -5px 10px #fafbff, 5px 5px 10px #a6abbd;
      `
    }
  }}
`
export const LaunchpadStageCardBlockTitle = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: ${({ theme }) => theme.textColor};
  margin: 0;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`
export const LaunchpadStageCardBlockSubtitle = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  color: ${({ theme }) => theme.subTextColor};
  margin: 0;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`

export const LaunchpadeStageCardDownbar = styled.div<{ color: string }>`
  height: 8px;

  ${({ color }) => {
    let background = ''
    switch (color) {
      case 'orange':
        background = 'linear-gradient(135.37deg, rgba(0, 0, 0, 0.4) 4.29%, rgba(255, 255, 255, 0.4) 95.6%), #FF7A00'
        break
      case 'blue':
        background = '#0029FF'
        break
      case 'darkblue':
        background = '#0C1A6C'
        break
      default:
    }

    return css`
      background: ${background};
      background-blend-mode: soft-light, normal;
    `
  }}
  border-radius: 4px;
  width: calc(100% - 34px);
  position: absolute;
  bottom: 8px;
  left: 17px;
`

export const LaunchpadStageCardContainerLast = styled.div<{ marginTop: number }>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 12px;

  margin-top: ${({ marginTop }) => `${marginTop}px`};

  @media (max-width: 768px) {
    margin-top: ${({ marginTop }) => `${marginTop / 2}px`};
  }
`

export const LaunchpadStageCardBlockSmallTitle = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;

  @media (max-width: 768px) {
    font-size: 10px;
  }

  @media (max-width: 320px) {
    font-size: 9px;
  }
`
export const LaunchpadStageCardBlockSmallSubtitle = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;

  @media (max-width: 768px) {
    font-size: 10px;
  }

  @media (max-width: 320px) {
    font-size: 9px;
  }
`

export const LaunchpadStageFundraisingResponsive = styled.div`
  display: none;
  color: ${({ theme }) => theme.backgroundTextColor};

  @media (max-width: 768px) {
    background: linear-gradient(135.37deg, rgba(0, 0, 0, 0.4) 4.29%, rgba(255, 255, 255, 0.4) 95.6%), #ff7a00;
    background-blend-mode: soft-light, normal;
    border-radius: 8px;
    padding: 10px 0px;
    display: block;
    text-align: center;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 130%;
  }
`
