import styled, { css } from 'styled-components/macro'

export const FundingStagesStyled = styled.div`
  width: 100%;
`
export const FundingStagesTitleContainer = styled.div<{ smallTitle: boolean }>`
  margin-top: 200px;
  display: flex;

  justify-content: space-between;

  @media (max-width: 900px) {
    display: block;
    margin-top: 70px;
  }

  > div > h2 {
    margin-bottom: ${(props) => (props.smallTitle ? '0' : '32px')};
  }
`

export const FundingStagesImageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin-top: 20px;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`

export const FundingStagesImg = styled.img`
  width: 100%;
`

export const FundingStagesShape = styled.img`
  position: absolute;
  right: 0;
  height: 600px;
  transform: translateY(-500px);
  @media (max-width: 1439px) {
    right: 0;
    height: 500px;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

export const FundingStagesButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 40px;
  a {
    display: flex;

    &:nth-child(2) {
      margin-left: 20px;
    }
  }

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: center;

    a {
      span {
        max-width: fit-content;
        margin: auto !important;
      }

      &:nth-child(2) {
        margin-top: 20px;
        margin-left: 0;
      }
    }
  }
`

export const FundingStageCard = styled.div<{ isDarkMode: boolean }>`
  border-radius: 8px;
  padding: 20px;
  position: relative;

  ${({ isDarkMode }) => {
    if (isDarkMode) {
      return css`
        background: #13203D;
        box-shadow: inset -4px -4px 4px rgba(58, 68, 93, 0.5), inset 4px 4px 4px #060c18;
      `
    } else {
      return css`
        background: linear-gradient(317.7deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.4) 105.18%), #ebecf0;
        background-blend-mode: soft-light, normal;

        border: 0.438063px solid rgba(255, 255, 255, 0.4);
        box-shadow: inset -2.19031px -2.19031px 4.38063px #fafbff, inset 2.19031px 2.19031px 4.38063px #a6abbd;
      `
    }
  }}

  @media (max-width: 768px) {
    padding: 20px 9px;
  }
`

export const FundingStageTitle = styled.p`
  display: flex;
  grid-gap: 15px;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 30.76px;
  line-height: 33.84px;

  text-align: center;
  text-transform: uppercase;

  color: #000000;

  color: ${({ theme }) => theme.textColor};
  margin: 0px;

  @media (max-width: 768px) {
    padding: 0px 8px;

    font-size: 20.76px;
    line-height: 22.83px;

    > img {
      width: 30px;
    }
  }
`

export const FundingStageSubtitle = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 19.97px;
  line-height: 22px;

  text-align: center;
  text-transform: uppercase;

  color: ${({ theme }) => theme.textColor};
  margin: 10px 0 0 0;

  @media (max-width: 768px) {
    font-size: 15.97px;
    line-height: 17.56px;

    margin: 5px 0 0 0;
  }
`

export const FundingStageCardBlocksContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 0px 11px;
    margin-top: 15px;
  }
`

export const FundingStageCardBlock = styled.div<{ height: number; isDarkMode: boolean }>`
  padding: 9px 0px;
  border-radius: 8px;
  margin-bottom: 12px;

  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: ${({ height }) => `${height}px`};

  ${({ isDarkMode }) => {
    if (isDarkMode) {
      return css`
        background: #13203D;
        box-shadow: 5px 5px 15px rgba(5, 12, 27, 0.6);
      `
    } else {
      return css`
        border: 1px solid rgba(255, 255, 255, 0.4);
        background: linear-gradient(135.37deg, rgba(0, 0, 0, 0.4) 4.29%, rgba(255, 255, 255, 0.4) 95.6%), #ebecf0;
        background-blend-mode: soft-light, normal;
        box-shadow: -5px -5px 10px #fafbff, 5px 5px 10px #a6abbd;
      `
    }
  }}
`
export const FundingStageCardBlockTitle = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.textColor};
  margin: 0;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 17px;
  }
`
export const FundingStageCardBlockSubtitle = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.subTextColor};
  margin: 0;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 17px;
  }
`

export const FundingStageCardDownbar = styled.div<{ color: string }>`
  height: 8px;

  ${({ color }) => {
    let background = ''
    switch (color) {
      case 'orange':
        background = 'linear-gradient(135.37deg, rgba(0, 0, 0, 0.4) 4.29%, rgba(255, 255, 255, 0.4) 95.6%), #FF7A00'
        break
      case 'blue':
        background = '#0029FF'
        break
      case 'darkblue':
        background = '#0C1A6C'
        break
      default:
    }

    return css`
      background: ${background};
      background-blend-mode: soft-light, normal;
    `
  }}
  border-radius: 4px;
  width: calc(100% - 34px);
  position: absolute;
  bottom: 8px;
  left: 17px;
`
