import styled from 'styled-components/macro'

export const PressVideoContainer = styled.div<{ isDarkMode: boolean }>`
  background: ${({ isDarkMode }) =>
    isDarkMode ? '#13203D' : 'linear-gradient(135deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), #ebecf0'};
  border: 2px solid ${({ isDarkMode }) => (isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(255, 255, 255, 0.4)')};
  background-blend-mode: soft-light, normal;
  box-sizing: border-box;
  box-shadow: ${({ isDarkMode }) =>
    isDarkMode
      ? '-5px -5px 10px rgba(8, 21, 45, 0.6), 5px 5px 10px rgba(5, 12, 27, 0.6)'
      : '-10px -10px 20px #fafbff, 10px 10px 20px #a6abbd'};
  border-radius: 8px;
  padding: 20px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);

  cursor: pointer;

  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
  }
`

export const PressVideoLeft = styled.div``
export const PressVideoRight = styled.div``
export const PressVideoTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
  /* or 25px */

  /* Retro/Bleu */

  color: ${({ theme }) => theme.blueRetro};
`
export const PressVideoSubTitle = styled.div`
  margin-top: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  letter-spacing: 0.02em;

  /* Retro/Bleu */

  color: ${({ theme }) => theme.blueRetro};

  opacity: 0.7;
`
export const PressVideoThumbnail = styled.img`
  width: 100%;
  border-radius: 8px;

  @media (max-width: 1023px) {
    margin-top: 40px;
  }
`
