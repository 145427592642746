import styled from 'styled-components/macro'

export const HomeContainer = styled.div`
  /* background-image: url('/images/right-bubbles.png');
  background-position: top right;
  background-repeat: no-repeat;
  background-size: auto 1000px; */
  position: relative;
  overflow: hidden;

  > video {
    width: 2000px;
    position: absolute;
    top: -100px;
    right: calc(50vw - 1300px);

    @media (max-width: 800px) {
      right: calc(50vw - 1000px);
    }
  }

  @media (max-width: 700px) {
    background-image: url('/images/top-bubbles.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: -80px;
  }
`

export const HomeStyled = styled.div<{ disableRelative?: boolean }>`
  position: ${(props) => (props.disableRelative ? 'block' : 'relative')};
  margin-left: auto;
  margin-right: auto;
  padding: 0 56px;
  &:nth-child(1) {
    padding: 0 56px 0 80px;

    @media (max-width: 1023px) {
      padding: 0 20px;
    }
  }
  max-width: 1440px;

  > p {
    max-width: 530px;
    font-size: 16px;
  }

  @media (max-width: 1023px) {
    > p {
      text-align: center;
      margin: auto;
    }

    padding: 0 20px;
  }
`

export const HomeBackBubbles = styled.img`
  position: absolute;
  z-index: -1;
  right: calc(50vw - 700px);
  top: 300px;
  @media (max-width: 1023px) {
    display: none;
  }
`

export const HomeBackBubbles2 = styled.img`
  position: absolute;
  z-index: -1;
  right: 30px;
  top: 200px;
  @media (max-width: 1023px) {
    display: none;
  }
`

export const HomeTitle = styled.h1`
  padding-top: 150px;
  font-family: 'Nizzoli';
  font-weight: bold;
  font-size: 96px;
  max-width: 500px;
  line-height: 80px;

  @media (max-width: 1023px) {
    text-align: center;
    font-size: 65px;
    max-width: 100%;
    line-height: 65px;
    margin: 0 auto 20px auto;
    padding-top: 250px;
  }
`

export const HomeParagraph = styled.p`
  line-height: 24px;
  margin: 24px 0 0 0;
`

export const HomeTitleButtons = styled.div`
  margin-top: 35px;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 10px;

  @media (max-width: 1023px) {
    grid-template-columns: auto;
    grid-gap: 20px;
    text-align: center;
    margin: 35px auto;

    > a,
    span {
      margin: auto;
      max-width: unset;
      width: 100%;
    }

    svg {
      margin: 0 0 0 10px;
    }
  }
`

export const HomeSocial = styled.div`
  margin-top: 50px;
`

export const HomeIndicator = styled.div`
  margin-top: 40px;
  display: flex;
  font-weight: bold;
  align-items: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.subTextColor};

  > img {
    margin-left: 12px;
  }

  @media (max-width: 1023px) {
    display: block;
    margin: 70px auto;
    text-align: center;

    > img {
      vertical-align: bottom;
    }
  }
`

export const HomeCardIllustration = styled.div<{ isDarkMode: boolean }>`
  background-image: ${({ isDarkMode }) =>
    isDarkMode ? "url('/images/dark-left-waves.png')" : "url('/images/light-left-waves.png')"};
  background-position: left 0 top 250px;
  background-repeat: no-repeat;
  background-size: auto 800px;
  overflow: hidden;
  padding-bottom: 10px;
  position: relative;

  margin-top: 30px;
`

export const HomeCardContainer = styled.div<{ align: string }>`
  float: ${({ align }) => (align === 'left' ? 'left' : 'right')};
  max-width: 846px;
  margin-top: 150px;

  @media (max-width: 1023px) {
    margin-top: 70px;
  }

  > div {
    margin: ${({ align }) => (align === 'left' ? '0 0 0 10px' : '0 10px 0 0')};
  }
`

export const HomeCard = styled.div`
  padding: 40px;

  img {
    margin-top: 6px;
    width: 100%;
  }

  p {
    margin: 26px 0 36px 0;
    line-height: 24px;
  }

  span:first-child {
    max-width: fit-content;
  }

  section {
    margin-top: 32px;
    display: flex;

    a {
      &:nth-child(2) {
        margin-left: 20px;
      }
    }
  }

  @media (max-width: 1023px) {
    padding: 40px 0px;
    p,
    h2,
    span,
    img {
      padding: 0px 24px;
    }
    section {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      a {
        display: flex;

        &:nth-child(2) {
          margin-top: 20px;
          margin-left: 0;
        }
      }
    }
  }
`

export const ImageHome = styled.img`
  @media (max-width: 1023px) {
    display: none;
  }
`
export const ImageHomeResponsive = styled.img`
  display: none;
  @media (max-width: 1023px) {
    display: block;
  }
`
export const HomePartnersTitle = styled.div`
  margin-top: 140px;
  margin-bottom: 40px;

  @media (max-width: 1023px) {
    h2,
    span {
      text-align: left;
    }
  }
`

export const HomePartners = styled.div`
  margin-bottom: 100px;
`

export const HomeBanner = styled.div`
  margin-top: 120px;
  margin-bottom: 120px;
`

export const ImgCompanies = styled.img`
  display: none;
  width: calc(100% - 20px);
  margin-left: 20px;

  @media (max-width: 767px) {
    display: block;
  }
`

export const HomePressContainer = styled.div`
  max-width: 1440px;
  padding: 0 56px;
  width: 100%;
  margin: auto;
  @media (max-width: 1023px) {
    padding: 0 20px;
  }
`
export const HomePressArticlesContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;

  @media (max-width: 1023px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`

export const HomeFirstContainer = styled.div`
  display: flex;
  margin-top: 200px;
  align-items: flex-start;
  flex-direction: column;
  > video {
    width: 70%;
    max-width: 836px;
    border-radius: 20px;
  }

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: flex-start;
    > video {
      width: 100%;
    }
  }
`
