//prettier-ignore
import {
  RoadmapContainer,
  RoadmapStyled,
  RoadmapTitle,
  RoadmapElements,
  DesktopRoadmap,
  MobileRoadmap,
  RoadmapResponsiveShapes,
} from './Roadmap.style'
import { Information } from 'app/App.components/Information/Information.view'
import { JoinCommunity } from 'app/App.components/JoinCommunity/JoinCommunity.view'
import { Title } from 'app/App.components/Title/Title.view'
import { Footer } from 'app/App.components/Footer/Footer.view'
import { State } from 'reducers'
import { useSelector } from 'react-redux'

export const Roadmap = () => {
  const isDarkMode = useSelector((state: State) => state.darkMode)

  return (
    <RoadmapContainer isDarkMode={isDarkMode}>
      <RoadmapStyled>
        <RoadmapResponsiveShapes
          src={isDarkMode ? '/images/roadmap-right-waves-dark.png' : '/images/roadmap-right-waves-light.png'}
        />
        <RoadmapTitle id="roadmap">
          <Title label="Roadmap" fontSize={[48, 26]}>
            Cardashift Eras
          </Title>
        </RoadmapTitle>
        <RoadmapElements>
          <Information label="First Era" title="Develop tech enablers" appearance="primary">
            The objective here is two-fold:
            <br />
            <br />
            1. Publish a Launchpad accessible to anyone.
            <br />
            This will be achieved in two steps: Launchpad v1.0 for crypto enthusiasts, and v2.0 for non crypto users
            (B2C).
            <br />
            <br />
            2. Release key enablers for our community, for projects, and for Cardashift (such as DEX, Staking, Wallet,
            Farming…) because they are essential to make our ecosystem self-sufficient and sustainable.
            <br />
            <br />
            By the end of 2022, we will create an ecosystem of products and services that enables projects to secure
            funding and generate impact at scale.
          </Information>
          <Information
            label="Second Era"
            title="Go Mainstream and develop new products & services"
            appearance="secondary"
          >
            The 2nd era aim is to design and develop new products and services for the B2C, while assisting launchpad
            projects to launch and scale. It means providing Lending & Insurance solutions, letting entrepreneurs fund
            their ventures through STOs, or being able to deploy an on(off-)chain governance with a DAO Launcher.
          </Information>
          <Information label="Third Era" title="Systemic shifts" appearance="tertiary">
            The goal for the 3rd era is to clear systematic blockages with a view to generating new ecosystems, which
            are radically different from the existing one. Our teams will deliver strategic insights to guide community
            efforts.
          </Information>
        </RoadmapElements>
        <DesktopRoadmap>
          <img src={isDarkMode ? '/images/roadmap-dark.png' : '/images/roadmap-light.png'} alt="Roadmap" />
        </DesktopRoadmap>
        <MobileRoadmap>
          <img
            src={isDarkMode ? '/images/dark-mobile-roadmap.png' : '/images/light-mobile-roadmap.png'}
            alt="Roadmap"
          />
        </MobileRoadmap>
      </RoadmapStyled>
      <JoinCommunity />
      <Footer />
    </RoadmapContainer>
  )
}
