import { Article } from '../Article/Article.view'
import { Button } from '../Button/Button.view'
import { Title } from '../Title/Title.view'
import { BlogArticles, BlogArticlesButton, BlogArticlesTitle, BlogStyled } from './Blog.style'

interface ArticleObj {
  title: string
  link: string
  image: string
  description: string
}

interface BlogProps {
  withLabel?: boolean
  withViewMore?: boolean
  withSubTitle?: boolean
  withTitle?: boolean
  label?: string
  title?: string
  articles: ArticleObj[]
}

export const Blog = ({
  withLabel = true,
  withViewMore = true,
  withSubTitle = true,
  withTitle = true,
  label = 'Latest articles',
  title = 'From the blog',
  articles = [],
}: BlogProps) => {
  let articlesToShow = []

  for (let i = 0; i < articles.length; i++) {
    articlesToShow.push(
      <Article title={articles[i].title} link={articles[i].link} image={articles[i].image} key={i}>
        {articles[i].description}
      </Article>,
    )
  }

  return (
    <BlogStyled>
      {withTitle && (
        <BlogArticlesTitle>
          <Title label={withLabel ? label : undefined} fontSize={label === 'Deep dive' ? [32, 26] : [48, 26]}>
            {title}
          </Title>
          {withSubTitle && <p>Specially curated to help you understand our launchpad</p>}
        </BlogArticlesTitle>
      )}
      <BlogArticles>{articlesToShow}</BlogArticles>
      {withViewMore && (
        <BlogArticlesButton>
          <a href="https://cardashift.medium.com" target="_blank" rel="noreferrer">
            <Button appearance="tertiary" icon="arrow_white">
              View more
            </Button>
          </a>
        </BlogArticlesButton>
      )}
    </BlogStyled>
  )
}
