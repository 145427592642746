import { ArticleStyled, ArticleTitle } from './Article.style'
import { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { State } from 'reducers'

interface ArticleProps {
  image: string
  title: string
  link: string
  children: ReactNode
}

export const Article = ({ image, title, link, children }: ArticleProps) => {
  const isDarkMode = useSelector((state: State) => state.darkMode)

  return (
    <ArticleStyled href={link} target="_blank" isDarkMode={isDarkMode}>
      <img src={image} alt={title} />
      <ArticleTitle isDarkMode={isDarkMode}>{title}</ArticleTitle>
      <p>{children}</p>
    </ArticleStyled>
  )
}
