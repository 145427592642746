import styled from 'styled-components/macro'

import { Button } from '../Button/Button.view'

export const PopupStyled = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 400px;
  z-index: 10;

  @media (max-width: 800px) {
    display: none;
  }
`

export const PopupContainer = styled.div`
  padding: 32px 24px;

  > svg {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  > p {
    font-weight: 900;
    font-size: 16px;
    line-height: 130%;
    color: #ffffff;
    margin: 6px 0 10px 0;
  }

  > pre {
    font-family: Nizzoli;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 130%;
    color: #ffffff;
    margin-top: 13px;
  }

  > a > span {
    width: fit-content;
    max-width: unset;
    margin-top: 24px !important;
    box-shadow: unset;
  }
`

export const PopupBg = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.backgroundTextColor};
  background-color: ${({ theme }) => theme.popupColor};
  opacity: 0.7;
  border-radius: 8px;
  backdrop-filter: blur(20px);
`

export const PopupButton = styled(Button)``
