import styled from 'styled-components/macro'

export const TokenomicsContainer = styled.div`
  margin-top: 200px;

  @media (max-width: 1023px) {
    margin-top: 120px;
  }
`

export const TokenomicsStyled = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding: 0 56px;
  max-width: 1440px;

  @media (max-width: 1023px) {
    padding: 0 20px;
  }
`

export const TokenomicsIllustrationSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > section > img {
    width: 100%;
    max-width: 282px;
  }

  section {
    &:nth-child(2) {
      max-width: 500px;
      @media (min-width: 1100px) {
        margin: 0 88px;
      }
    }

    @media (max-width: 1023px) {
      div > span,
      h2 {
        text-align: left;
      }

      div > span {
        margin-top: 20px;
      }

      &:first-child,
      &:last-child {
        display: none;
      }

      &:nth-child(2) {
        max-width: 100%;
      }
    }
  }
`

export const TokenomicsImageResponsive = styled.img`
  display: none;
  @media (max-width: 1023px) {
    display: block;
    width: 100%;
    max-width: unset !important;
  }
`
export const TokenomicsCenteredParagraph = styled.p`
  text-align: center;
  margin-top: 16px;
  line-height: 24px;

  @media (max-width: 1023px) {
    text-align: left;
  }
`

export const TokenomicsButtons = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 1023px) {
    a > span,
    span {
      max-width: unset;
      width: 100%;

      svg {
        margin: 0px 0px 0px 11px;
      }
    }

    > span {
      margin-top: 20px !important;
    }
  }
  @media (max-width: 550px) {
    flex-direction: column;

    > a {
      width: 100%;
      display: flex;
      justify-content: center;

      &:last-child {
        margin-top: 20px;
      }
    }
  }
`

export const TokenomicsCenteredSection = styled.div`
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 170px;
  text-align: center;

  @media (max-width: 1023px) {
    text-align: left;
    margin-top: 100px;

    div > span,
    h2 {
      text-align: left;
    }
  }
`

export const TokenomicsSectionIcon = styled.img`
  margin-bottom: 16px;
`

export const TokenomicsGrid = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const TokenomicsLaunchpadSection = styled.div`
  margin-top: 170px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 70px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
  }

  > div:last-child {
    text-align: right;
  }
`

export const TokenomicsLaunchpadTitle = styled.div`
  margin-top: 10px;
  margin-bottom: 16px;
`

export const TokenomicsLaunchpadParagraph = styled.p`
  margin-bottom: 36px;
  line-height: 24px;

  @media (max-width: 1023px) {
    margin-bottom: 0px;
  }
`

export const TokenomicsLaunchpadImage = styled.img`
  width: 100%;
  max-width: 550px;
  margin-right: 20px;
  @media (max-width: 900px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`

export const TokenomicsLaunchpadInformation = styled.div<{ isDarkMode: boolean }>`
  font-size: 14px;
  margin-top: 16px;
  background-color: ${({ theme }) => theme.backgroundColor};
  background-blend-mode: soft-light, normal;
  border: ${({ isDarkMode }) => (!isDarkMode ? '1px solid rgba(255, 255, 255, 0.4)' : 'none')};
  box-sizing: border-box;
  box-shadow: -5px -5px 10px ${({ theme }) => theme.primaryShadowColor},
    5px 5px 10px ${({ theme }) => theme.secondaryShadowColor};
  border-radius: 12px;
  padding: 20px;

  span {
    font-weight: 700;
    font-size: 16px;
    display: block;
    margin-bottom: 16px;
  }
`

export const TokenomicsClassicButton = styled.div`
  margin-top: 40px;
  display: flex;

  @media (max-width: 1023px) {
    display: none;
  }
`

export const TokenomicsClassicButtonResponsive = styled.div`
  display: none;
  @media (max-width: 1023px) {
    display: block;
    margin: auto;
    margin-top: 28px;

    a > span {
      width: fit-content;
    }
  }
`

export const TokenomicsTokenIllustration = styled.div<{ isDarkMode: boolean }>`
  background-image: ${({ isDarkMode }) =>
    isDarkMode ? "url('/images/tokenomics-left-waves-dark.png')" : "url('/images/tokenomics-left-waves-light.png')"};
  background-position: top left;
  background-repeat: no-repeat;
  background-size: auto 600px;
  padding-top: 270px;
  margin-top: -100px;
  @media (max-width: 1023px) {
    background-image: none;
  }
`

export const TokenomicsTokenTitle = styled.div`
  text-align: center;

  img {
    margin-bottom: 0px;
  }

  div > span,
  h2 {
    text-align: center;
  }
  @media (max-width: 1023px) {
    text-align: left;

    div > span,
    h2 {
      text-align: left;
    }
  }
`

export const TokenomicsTokenSection = styled.div`
  margin-top: 70px;
  display: grid;
  grid-template-columns: 100px repeat(2, 1fr) 100px;
  grid-gap: 70px;

  img {
    width: 100%;
  }

  @media (max-width: 1023px) {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 0px;
    img {
      display: block;
      max-width: 550px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`

export const TokenomicsTokenDesktop = styled.div`
  @media (max-width: 900px) {
    display: none;
  }
`

export const TokenomicsTokenMobile = styled.div`
  display: none;

  @media (max-width: 900px) {
    display: block;
  }
`

export const TokenomicsVestingIllustration = styled.div`
  padding-top: 170px;

  @media (max-width: 1023px) {
    padding-top: 0px;
  }
`

export const FirstSectionVesting = styled.div`
  width: 30%;
`
export const TokenomicsVestingSection = styled.div`
  margin-top: 120px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  section {
    flex: 0 40%;

    &:first-child {
      img {
        margin-bottom: 4px;
      }
    }

    &:last-child {
      flex: 0 calc(50% - 70px);
      margin-left: 70px;

      img {
        width: 100%;
      }
    }
  }

  @media (max-width: 900px) {
    display: block;
    section:last-child {
      margin-top: 40px;
      margin-left: 0;
      img {
        display: block;
        max-width: 550px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
`

export const TokenomicsVestingImage = styled.img`
  width: 64px;
  height: 64px;
`

export const TokenomicsVestingGraph = styled.img`
  width: 100%;
  margin-top: 40px;
`

export const TokenomicsCardSection = styled.div`
  margin-top: 170px;
  margin-bottom: 50px;

  @media (max-width: 1023px) {
    div > h2,
    span {
      text-align: left;
    }
  }
`

export const TokenomicsCardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
`

export const TokenomicsCardLegend = styled.p`
  margin-top: 20px;
  font-size: 12px;
  line-height: 18px;
`
