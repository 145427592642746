import styled from 'styled-components/macro'

export const BlogStyled = styled.div``

export const BlogArticlesTitle = styled.div`
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
  margin-bottom: 40px;

  p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;

    color: ${({ theme }) => theme.subTextColor};

    margin-top: 12px;
  }
`

export const BlogArticles = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const BlogArticlesButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
`
