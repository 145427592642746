import { useSelector } from 'react-redux'
import { State } from 'reducers'

import { SocialStyled } from './Social.style'

export const Social = () => {
  const isDarkMode = useSelector((state: State) => state.darkMode)

  return (
    <SocialStyled isDarkMode={isDarkMode}>
      <a href="https://discord.gg/hTSWWButZR" target="_blank" rel="noreferrer">
        <img src={isDarkMode ? '/icons/dark-discord.svg' : '/icons/light-discord.svg'} alt="Discord" />
      </a>
      <a href="https://cardashift.medium.com/" target="_blank" rel="noreferrer">
        <img src={isDarkMode ? '/icons/dark-medium.svg' : '/icons/light-medium.svg'} alt="Medium" />
      </a>
      <a href="https://twitter.com/cardashift" target="_blank" rel="noreferrer">
        <img src={isDarkMode ? '/icons/dark-twitter.svg' : '/icons/light-twitter.svg'} alt="Twitter" />
      </a>
      <a href="https://www.linkedin.com/company/cardashift/" target="_blank" rel="noreferrer">
        <img src={isDarkMode ? '/icons/dark-linkedin.svg' : '/icons/light-linkedin.svg'} alt="Linkedin" />
      </a>
    </SocialStyled>
  )
}
