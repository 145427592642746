import { Title } from 'app/App.components/Title/Title.view'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { State } from 'reducers'

import { Button } from '../Button/Button.view'
import {
  FundingStagesButtonsContainer,
  FundingStagesImageContainer,
  FundingStageCard,
  FundingStageTitle,
  FundingStageCardBlocksContainer,
  FundingStageCardBlock,
  FundingStageSubtitle,
  FundingStagesShape,
  FundingStagesStyled,
  FundingStageCardBlockTitle,
  FundingStageCardBlockSubtitle,
  FundingStagesTitleContainer,
  FundingStageCardDownbar,
} from './FundingStages.style'

interface FundingStagesProps {
  smallTitle?: boolean
  withTokenomics?: boolean
}
export const FundingStages = ({ smallTitle = false, withTokenomics = true }: FundingStagesProps) => {
  const isDarkMode = useSelector((state: State) => state.darkMode)

  return (
    <FundingStagesStyled>
      <FundingStagesTitleContainer smallTitle={smallTitle}>
        {smallTitle && <Title label="CLAP Token">CLAP Funding Stages</Title>}
        {!smallTitle && (
          <Title label="CLAP Token" fontSize={[48, 26]}>
            CLAP Funding Stages
          </Title>
        )}
      </FundingStagesTitleContainer>
      <FundingStagesImageContainer>
        <FundingStageCard isDarkMode={isDarkMode}>
          <FundingStageTitle>
            <img src="/images/confetti-left.png" alt="" />
            $10M RAISED
            <img src="/images/confetti-right.png" alt="" />
          </FundingStageTitle>
          <FundingStageSubtitle>ICO</FundingStageSubtitle>
          <FundingStageCardBlocksContainer>
            <FundingStageCardBlock height={61} isDarkMode={isDarkMode}>
              <FundingStageCardBlockTitle>$1.5M raised</FundingStageCardBlockTitle>
              <FundingStageCardBlockSubtitle>Private Sale</FundingStageCardBlockSubtitle>
            </FundingStageCardBlock>
            <FundingStageCardBlock height={61} isDarkMode={isDarkMode}>
              <FundingStageCardBlockTitle>$1.4M raised</FundingStageCardBlockTitle>
              <FundingStageCardBlockSubtitle>Flash Sale</FundingStageCardBlockSubtitle>
            </FundingStageCardBlock>
            <FundingStageCardBlock height={61} isDarkMode={isDarkMode}>
              <FundingStageCardBlockTitle>$7M+ raised</FundingStageCardBlockTitle>
              <FundingStageCardBlockSubtitle>Public Sale</FundingStageCardBlockSubtitle>
            </FundingStageCardBlock>
            <FundingStageCardDownbar color={'blue'}></FundingStageCardDownbar>
          </FundingStageCardBlocksContainer>
        </FundingStageCard>
      </FundingStagesImageContainer>
      <FundingStagesShape
        src={isDarkMode ? '/images/tokenomics-right-shape-dark.png' : '/images/tokenomics-right-shape.png'}
        alt=""
      />
      <FundingStagesButtonsContainer>
        <a
          href="https://cardashift.medium.com/cardashift-raises-10m-to-foster-impact-investing-through-cardano-blockchain-9834c4415c36"
          target="_blank"
          rel="noreferrer"
        >
          <Button appearance="primary" icon="arrow">
            See&nbsp;announcement
          </Button>
        </a>
        {withTokenomics && (
          <Link to="/tokenomics">
            <Button appearance="tertiary" icon="arrow_white">
              CLAP&nbsp;tokenomics
            </Button>
          </Link>
        )}
      </FundingStagesButtonsContainer>
    </FundingStagesStyled>
  )
}
