import styled from 'styled-components/macro'

export const BannerStyled = styled.div`
  /* background-color: ${({ theme }) => theme.primaryColor}; */
  padding-top: 120px;
  padding-bottom: 120px;
  text-align: center;
  position: relative;

  > video {
    width: 1800px;
    position: absolute;
    top: 0;
    right: calc(50vw - 900px);
    z-index: -1;
  }

  > div {
    z-index: 1;
  }
`

export const BannerText = styled.div`
  color: ${({ theme }) => theme.backgroundTextColor};
  font-weight: 900;
  font-family: Nizzoli;
  font-size: 40px;
  line-height: 62px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 820px;
  max-width: 80vw;
  z-index: 1;

  @media (max-width: 900px) {
    width: 90%;
    font-size: 26px;
    line-height: 33px;
  }
`

export const BannerLegend = styled.div`
  color: ${({ theme }) => theme.backgroundTextColor};
  font-weight: 900;
  font-size: 14px;
  margin-top: 20px;
  text-transform: uppercase;
  z-index: 1;

  @media (max-width: 1023px) {
    font-size: 12px;
  }
`
