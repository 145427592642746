import { PopupBg, PopupButton, PopupContainer, PopupStyled } from './Popup.style'

interface PopupViewProps {
  hideCallback: () => void
}

export const PopupView = ({ hideCallback }: PopupViewProps) => {
  return (
    <PopupStyled>
      <PopupBg />
      <PopupContainer>
        <svg onClick={() => hideCallback()}>
          <use xlinkHref="/icons/sprites.svg#close" />
        </svg>
        <pre>
        Discover and vote for 
        <br/>
        our enhanced roadmap 
        </pre>
        <a href="https://dao.cardashift.com/home" target="_blank" rel="noreferrer">
          <PopupButton appearance="secondary">Vote for our roadmap</PopupButton>
        </a>
      </PopupContainer>
    </PopupStyled>
  )
}
