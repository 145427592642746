import { Provider } from 'react-redux'
import { configureStore } from './App.store'
import { AppRouter } from './App.router'
import ReactGA from 'react-ga4'

export const store = configureStore({})

ReactGA.initialize('G-83BJMYNL1C')
ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search })

export const App = () => (
  <Provider store={store}>
    <AppRouter />
  </Provider>
)
