import styled from 'styled-components/macro'

export const CommunityContainer = styled.div`
  background-image: url('/images/left-community-bubbles.png');
  background-position: top left;
  background-repeat: no-repeat;
  background-size: auto 500px;

  @media (max-width: 1023px) {
    background: none;
  }
`
export const CommunityResponsiveBubbles = styled.img`
  display: none;

  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  @media (max-width: 1023px) {
    display: block;
  }
`

export const CommunityStyled = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding: 0 56px;
  max-width: 1440px;

  @media (max-width: 1023px) {
    padding: 0 20px;
  }
`

export const CommunityTitle = styled.div`
  padding-top: 190px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  p {
    line-height: 24px;
    text-align: center;
  }

  @media (max-width: 1023px) {
    h2,
    span,
    p {
      text-align: left;
    }
  }
`

export const CommunitySocialGrid = styled.div`
  margin-top: 140px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
  }

  justify-content: space-between;
`

export const CommunitySocial = styled.a<{ isDarkMode: boolean }>`
  background-color: ${({ theme }) => theme.backgroundColor};
  background-blend-mode: soft-light, normal;
  border: ${({ isDarkMode }) => (!isDarkMode ? '2px solid rgba(255, 255, 255, 0.4)' : 'none')};
  box-sizing: border-box;
  box-shadow: -10px -10px 20px ${({ theme }) => theme.primaryShadowColor},
    10px 10px 20px ${({ theme }) => theme.secondaryShadowColor};
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  font-weight: 600;
  font-size: 18px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 163px;
  img {
    position: relative;
    top: 8px;
    width: 38px;
    margin-bottom: 30px;
  }

  span {
    display: block;
  }
`

export const CommunitySection = styled.section<{ noMarginTop?: boolean }>`
  margin-top: ${(props) => (props.noMarginTop ? '0px' : '140px')};
`

export const CommunitySectionImage = styled.img`
  width: 64px;
  height: 64px;
`

export const CommunitySectionGrid = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const CommunitySectionGridFlags = styled(CommunitySectionGrid)`
  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

export const CommunitySectionElement = styled.div<{ isDarkMode: boolean }>`
  background-color: ${({ theme }) => theme.backgroundColor};
  background-blend-mode: soft-light, normal;
  border: ${({ isDarkMode }) => (!isDarkMode ? '2px solid rgba(255, 255, 255, 0.4)' : 'none')};
  box-sizing: border-box;
  box-shadow: -10px -10px 20px ${({ theme }) => theme.primaryShadowColor},
    10px 10px 20px ${({ theme }) => theme.secondaryShadowColor};
  border-radius: 8px;
  padding: 20px;
  text-align: center;

  img {
    position: relative;
    top: 8px;
    height: 50px;
    margin-bottom: 30px;
  }

  span {
    font-family: Inter;
    font-style: normal;
    line-height: 23px;
    display: block;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 18px;
  }

  a {
    display: block;
    background-color: ${({ theme }) => theme.backgroundColor};
    background-blend-mode: soft-light, normal;
    border: ${({ isDarkMode }) => (!isDarkMode ? '2px solid rgba(255, 255, 255, 0.4)' : 'none')};
    box-sizing: border-box;
    box-shadow: -10px -10px 20px ${({ theme }) => theme.primaryShadowColor},
      10px 10px 20px ${({ theme }) => theme.secondaryShadowColor};
    border-radius: 30px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    width: fit-content;
    padding: 12px 20px;
    margin-left: auto;
    margin-right: auto;
  }
`
export const BlogContainer = styled.div`
  > div > div:first-child {
    margin-top: 16px;
  }
`

export const CommunityResponsiveBr = styled.div`
  display: none;
  @media (max-width: 1023px) {
    display: block;
  }
`
