// prettier-ignore
import { PressVideoContainer, PressVideoLeft, PressVideoRight, PressVideoSubTitle, PressVideoThumbnail, PressVideoTitle } from './PressVideo.style'

interface PressVideoProps {
  isDarkMode: boolean
  title: string
  subTitle: string
  link: string
}

export const PressVideo = ({ isDarkMode, title, subTitle, link }: PressVideoProps) => {
  return (
    <PressVideoContainer isDarkMode={isDarkMode} onClick={() => window.open(link, '_blank')?.focus()}>
      <PressVideoLeft>
        <PressVideoTitle>{title}</PressVideoTitle>
        <PressVideoSubTitle>{subTitle}</PressVideoSubTitle>
      </PressVideoLeft>
      <PressVideoRight>
        <PressVideoThumbnail src={'https://img.youtube.com/vi/' + link.split('?v=')[1] + '/maxresdefault.jpg'} alt="" />
      </PressVideoRight>
    </PressVideoContainer>
  )
}
