export type Partner = {
  id: number
  image: string
  imageDark: string
  description: string
}

export const partners: Partner[] = [
  {
    id: 1,
    image: '/images/partners/orange-light.svg',
    imageDark: '/images/partners/orange-dark.svg',
    description: 'A DAO of Y Combinator alumni',
  },
  {
    id: 2,
    image: '/images/partners/founders-one.svg',
    imageDark: '/images/partners/founders-one-dark.svg',
    description: 'Impact Tech Foundation',
  },
  {
    id: 3,
    image: '/images/partners/haskell-foundation.svg',
    imageDark: '/images/partners/haskell-foundation-dark.svg',
    description: 'Independent, non-profit organization dedicated to global Haskell adoption',
  },
  {
    id: 4,
    image: '/images/partners/poa.png',
    imageDark: '/images/partners/poa-dark.png',
    description: 'Cardano Stake Pool Operator',
  },
  {
    id: 5,
    image: '/images/partners/exaion.png',
    imageDark: '/images/partners/exaion-dark.png',
    description: 'Technical Infrastructure Partner',
  },
  {
    id: 6,
    image: '/images/partners/eic.png',
    imageDark: '/images/partners/eic-dark.png',
    description: 'Technical Infrastructure Partner',
  },
  {
    id: 7,
    image: '/images/partners/blockchain-cameroon.png',
    imageDark: '/images/partners/blockchain-cameroon.png',
    description: 'Impact Project Sourcing Partner',
  },
  {
    id: 8,
    image: '/images/partners/msa.png',
    imageDark: '/images/partners/msa.png',
    description: 'MSA - Partner',
  },
]
