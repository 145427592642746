import styled from 'styled-components/macro'

export const RoadmapContainer = styled.div<{ isDarkMode: boolean }>`
  position: relative;
  background-image: ${({ isDarkMode }) =>
    isDarkMode ? "url('/images/dark-right-waves.png')" : "url('/images/light-right-waves.png')"};
  background-position: top right;
  background-repeat: no-repeat;
  background-size: auto 800px;
  padding-top: 200px;
  @media (max-width: 1023px) {
    padding-top: 150px;
    background: none;
  }
`

export const RoadmapResponsiveShapes = styled.img`
  display: none;
  position: absolute;
  width: 250px;
  top: 0px;
  right: 0px;
  z-index: -1;
  @media (max-width: 1023px) {
    display: block;
  }
`
export const RoadmapStyled = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding: 0 56px;
  max-width: 1440px;

  @media (max-width: 1023px) {
    padding: 20px;
  }
`

export const RoadmapTitle = styled.div`
  margin-bottom: 60px;

  @media (max-width: 1023px) {
    margin-bottom: 50px;
  }
`

export const RoadmapElements = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 900px) {
    display: block;
  }
`

export const DesktopRoadmap = styled.div`
  margin: 80px auto 50px auto;

  img {
    width: 100%;
  }

  @media (max-width: 700px) {
    display: none;
  }
`

export const MobileRoadmap = styled.div`
  display: none;
  margin: 50px auto 100px auto;

  img {
    width: 100%;
  }

  @media (max-width: 700px) {
    display: block;
  }
`
