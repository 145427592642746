import { TitleContainer, TitleLabel, TitleElement } from './Title.style'
import { ReactNode } from 'react'

interface TitleProps {
  label?: string
  labelAbove?: boolean
  centered?: boolean
  fontSize?: [number, number]
  children: ReactNode
}

export const Title = ({ label, labelAbove = true, centered = false, fontSize = [32, 26], children }: TitleProps) => (
  <TitleContainer centered={centered}>
    {label && labelAbove ? <TitleLabel labelAbove={labelAbove}>{label}</TitleLabel> : null}
    <TitleElement fontSize={fontSize}>{children}</TitleElement>
    {label && !labelAbove ? <TitleLabel labelAbove={labelAbove}>{label}</TitleLabel> : null}
  </TitleContainer>
)
