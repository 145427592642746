import { combineReducers } from 'redux'

import { darkMode, DarkModeState } from './darkMode'
import { icoState, icoStateState } from './icoState'
import { loading, LoadingState } from './loading'
import { modal, ModalState } from './modal'
import { popup, PopupState } from './popup'
import { progressBar, ProgressBarState } from './progressBar'
import { toaster, ToasterState } from './toaster'

export const reducers = combineReducers({
  loading,
  progressBar,
  darkMode,
  toaster,
  popup,
  icoState,
  modal
})

export interface State {
  loading: LoadingState
  progressBar: ProgressBarState
  darkMode: DarkModeState
  toaster: ToasterState
  popup: PopupState
  icoState: icoStateState,
  modal: ModalState
}
