import styled from 'styled-components/macro'

export const PressContainer = styled.div``

export const PressBubblesContainer = styled.div`
  position: relative;
`

export const PressStyled = styled.div`
  margin-top: 200px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  padding: 0 56px;

  @media (max-width: 1023px) {
    padding: 0 20px;
  }
`

export const PressFirstContainer = styled.div`
  display: flex;
  margin-top: 200px;
  margin-bottom: 130px;
  align-items: flex-start;
  flex-direction: column;
  > video {
    width: 70%;
    max-width: 836px;
    border-radius: 20px;
  }

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: flex-start;
    > video {
      width: 100%;
    }
  }
`

export const SubTitle = styled.p`
  margin: 0;
`

export const PressVideoContainer = styled.div`
  margin-top: 80px;
`

export const PressVideosGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 32px;

  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
  }
`
export const PressInquiryCardContainer = styled.div`
  width: 650px;
  margin: 160px auto 0px auto;
  @media (max-width: 1200px) {
    width: 100%;
    margin: 120px auto 0px auto;
  }
`
export const PressInquiryForm = styled.div`
  padding: 48px 110px;
  cursor: default !important;
  @media (max-width: 1023px) {
    padding: 40px 24px;
  }
`

export const PressInquirySubText = styled.p`
  margin: 32px 0 0 0;
  text-align: center;
`

export const PressInquiryTitle = styled.div`
  font-family: Nizzoli;
  font-style: normal;
  font-weight: 900;
  font-size: 26px;
  line-height: 130%;
  text-align: center;
  color: ${({ theme }) => theme.textColor};

  @media (max-width: 1023px) {
  }
`

export const PressInquiryButtonRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;

  @media (max-width: 1023px) {
    justify-content: center;
    margin-top: 24px;
  }
`

export const PressTopBubbles = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 1100px;

  @media (max-width: 1023px) {
    display: none;
  }
`

export const PressTopBubblesResponsive = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 80%;
  z-index: -1;

  display: none;

  @media (max-width: 1023px) {
    display: block;
  }
`

export const PressInquiryBubbles = styled.img`
  position: absolute;
  right: 0;
  bottom: -120px;
  z-index: -1;
  @media (max-width: 1023px) {
    display: none;
  }
`

export const PressInquiryBubblesResponsive = styled.img`
  position: absolute;
  right: 0;
  bottom: -20px;
  display: none;
  z-index: -1;
  @media (max-width: 1023px) {
    display: block;
  }
`
