import { Button } from 'app/App.components/Button/Button.view'
import { GetClap } from 'app/App.components/GetClap/GetClap.controller'
import { ClapTable } from 'app/App.components/ClapTable/ClapTable.view'
import { Footer } from 'app/App.components/Footer/Footer.view'
import { FundingStages } from 'app/App.components/FundingStages/FundingStages.view'
import { JoinCommunity } from 'app/App.components/JoinCommunity/JoinCommunity.view'
import { Title } from 'app/App.components/Title/Title.view'
import { Utility } from 'app/App.components/Utility/Utility.view'
import { Link } from 'react-router-dom'

// prettier-ignore
import { FirstSectionVesting, TokenomicsButtons, TokenomicsCardLegend, TokenomicsCardSection, TokenomicsCenteredParagraph, TokenomicsCenteredSection, TokenomicsClassicButton, TokenomicsClassicButtonResponsive, TokenomicsContainer, TokenomicsGrid, TokenomicsIllustrationSection, TokenomicsImageResponsive, TokenomicsLaunchpadImage, TokenomicsLaunchpadInformation, TokenomicsLaunchpadParagraph, TokenomicsLaunchpadSection, TokenomicsLaunchpadTitle, TokenomicsSectionIcon, TokenomicsStyled, TokenomicsTokenDesktop, TokenomicsTokenIllustration, TokenomicsTokenMobile, TokenomicsTokenSection, TokenomicsTokenTitle, TokenomicsVestingGraph, TokenomicsVestingIllustration, TokenomicsVestingImage, TokenomicsVestingSection } from './Tokenomics.style'

interface TokenomicsViewProps {
  isDarkMode: boolean
  showModal: (title: string, children: JSX.Element) => void
}

export const TokenomicsView = ({ isDarkMode, showModal }: TokenomicsViewProps) => {
  return (
    <TokenomicsContainer>
      <TokenomicsStyled>
        <TokenomicsIllustrationSection>
          <section>
            <img src="/images/cute.png" alt="" />
          </section>
          <section>
            <TokenomicsImageResponsive src="/images/cute_responsive.png" alt=""></TokenomicsImageResponsive>
            <Title label="Giving the Cardashift ecosystem the fuel to thrive" centered>
              CLAP Tokenomics
            </Title>
            <TokenomicsCenteredParagraph>
              CLAP token powers the Cardashift product ecosystem.The token is engineered to evolve as the ecosystem
              develops, thus making it dynamic and increasingly valuable.
            </TokenomicsCenteredParagraph>
            <TokenomicsButtons>
              <Button
                appearance="secondary"
                icon="logo-clap.png"
                clickCallback={() => showModal('GET CLAP', <GetClap />)}
              >
                Get&nbsp;CLAP
              </Button>
            </TokenomicsButtons>
          </section>
          <section>
            <img src="/images/love.png" alt="" />
          </section>
        </TokenomicsIllustrationSection>
        <TokenomicsCenteredSection>
          <TokenomicsSectionIcon src={isDarkMode ? '/icons/clap-dark.svg' : '/icons/clap-light.svg'} alt="" />
          <Title label="Become a Cardashift builder" centered>
            CLAP token utilities
          </Title>
          <TokenomicsCenteredParagraph>
            CLAP token holders are empowered with a variety of utilities that make them active stakeholders in the
            governance of the Cardashift ecosystem.
          </TokenomicsCenteredParagraph>
        </TokenomicsCenteredSection>
        <TokenomicsGrid>
          <Utility
            icon={isDarkMode ? '/icons/selection-dark.svg' : '/icons/selection-light.svg'}
            title="Participate in project selection"
            points={[
              'Voting rights in project selection process',
              'Ability to stake tokens is support of projects',
              'Guide and track project progress',
            ]}
          />
          <Utility
            icon={isDarkMode ? '/icons/dex-dark.svg' : '/icons/dex-light.svg'}
            title="Participate in Initial Dex Offering (IDOs)"
            legend="Privileged access for CLAP holders"
            points={['No fees', 'Automatically whitelisted', 'Exclusive community rights']}
          />
          <Utility
            icon={isDarkMode ? '/icons/token-dark.svg' : '/icons/token-light.svg'}
            title="Token valuation"
            points={[
              'Constant reduction of circulating supply (1% burn rate)',
              'Dynamic token value',
              'CLAP is locked in liquidity pools to retain value',
            ]}
          />
        </TokenomicsGrid>
        <TokenomicsButtons>
          <Link to="/launchpad">
            <Button appearance="primary">Discover Launchpad</Button>
          </Link>
        </TokenomicsButtons>
        <TokenomicsLaunchpadSection>
          <div>
            <img src={isDarkMode ? 'icons/more-dark.svg' : 'icons/more-light.svg'} alt="" />
            <TokenomicsLaunchpadTitle>
              <Title label="Construct the technological commons of tomorrow">Hold CLAP token for the long-run</Title>
            </TokenomicsLaunchpadTitle>
            <TokenomicsLaunchpadParagraph>
              As the product portfolio within the Cardashift ecosystem grows, the CLAP token will be able to derive more
              utilities. Thus, reinforcing the technological commons of tomorrow.
            </TokenomicsLaunchpadParagraph>
            <TokenomicsLaunchpadInformation isDarkMode={isDarkMode}>
              <span>New products</span>
              Dex, Universal Wallet, STO platform DAO launcher and many more
            </TokenomicsLaunchpadInformation>
            <TokenomicsLaunchpadInformation isDarkMode={isDarkMode}>
              <span>Investment opportunities</span>
              Liquidity Pool, Stake-to-Earn and AirDrop
            </TokenomicsLaunchpadInformation>
            <TokenomicsClassicButton>
              <Link to="/roadmap">
                <Button appearance="primary">View our roadmap</Button>
              </Link>
            </TokenomicsClassicButton>
          </div>
          <div>
            <TokenomicsLaunchpadImage
              src={isDarkMode ? '/images/launchpad-dark.png' : '/images/launchpad-light2x.png'}
              alt=""
            />
          </div>
        </TokenomicsLaunchpadSection>
      </TokenomicsStyled>
      <TokenomicsTokenIllustration isDarkMode={isDarkMode}>
        <TokenomicsStyled>
          <TokenomicsTokenTitle>
            <img src={isDarkMode ? '/icons/allocation-dark.svg' : '/icons/allocation-light.svg'} alt="" />
            <Title centered={false}>Initial token allocation</Title>
          </TokenomicsTokenTitle>
          <TokenomicsTokenSection>
            <div></div>
            <div>
              <ClapTable
                appearance="primary"
                data={[
                  { label: 'Ticker', value: 'CLAP' },
                  { label: 'Standard', value: 'Cardano' },
                  { label: 'Total supply incl. reserve', value: '1&nbsp;000&nbsp;000&nbsp;000' },
                  { label: 'Token launch price', value: '0,042$ (1 ₳ ⇔ 30 CLAP)' },
                  { label: 'Min cap:<br />Max cap:', value: '200 ADA<br />50 000 ADA' },
                  { label: 'Initial market cap', value: '8 505 000$' },
                ]}
              />
            </div>
            <div>
              <TokenomicsTokenDesktop>
                <img
                  alt=""
                  src={isDarkMode ? '/images/dark-distrib-desktop.svg' : '/images/light-distrib-desktop.svg'}
                />
              </TokenomicsTokenDesktop>
              <TokenomicsTokenMobile>
                <img alt="" src={isDarkMode ? '/images/dark-distrib-mobile.svg' : '/images/light-distrib-mobile.svg'} />
              </TokenomicsTokenMobile>
            </div>
          </TokenomicsTokenSection>
        </TokenomicsStyled>
        <TokenomicsVestingIllustration>
          <TokenomicsStyled>
            <TokenomicsVestingSection>
              <FirstSectionVesting>
                <TokenomicsVestingImage
                  src={isDarkMode ? '/icons/vesting-dark.svg' : '/icons/vesting-light.svg'}
                  alt=""
                />
                <Title>Vesting</Title>
                <TokenomicsClassicButton>
                  <a href="/cardashift_whitepaper.pdf" title="Whitepaper">
                    <Button appearance="primary">Details&nbsp;in&nbsp;our&nbsp;whitepaper</Button>
                  </a>
                </TokenomicsClassicButton>
              </FirstSectionVesting>
              <section>
                <TokenomicsVestingGraph
                  alt=""
                  src={isDarkMode ? '/images/vesting-dark.png' : '/images/vesting-light.png'}
                ></TokenomicsVestingGraph>
              </section>
              <TokenomicsClassicButtonResponsive>
                <a href="/cardashift_whitepaper.pdf" title="Whitepaper">
                  <Button appearance="primary">Details in our whitepaper</Button>
                </a>
              </TokenomicsClassicButtonResponsive>
            </TokenomicsVestingSection>
            <TokenomicsCardSection>
              <FundingStages smallTitle={true} withTokenomics={false} />
              <TokenomicsCardLegend></TokenomicsCardLegend>
            </TokenomicsCardSection>
          </TokenomicsStyled>
        </TokenomicsVestingIllustration>
      </TokenomicsTokenIllustration>
      <JoinCommunity />
      <Footer />
    </TokenomicsContainer>
  )
}
