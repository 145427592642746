import styled, { css } from 'styled-components/macro'

export const TeamStyled = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
  margin-bottom: 100px;
  padding: 0 56px;
  max-width: 1440px;

  @media (max-width: 1023px) {
    padding: 20px;
    margin-top: 132px;
  }
`

export const TeamTitle = styled.div`
  margin-bottom: 100px;

  @media (max-width: 1023px) {
    margin-bottom: 0px;
  }
`

export const TeamCategories = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 100px;
  margin-bottom: 60px;

  @media (max-width: 1200px) {
    display: block;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

export const TeamCategoriesResponsiveContainer = styled.div`
  position: relative;
  display: none;
  @media (max-width: 1023px) {
    display: block;
  }
`

export const TeamCategoriesResponsive = styled.select`
  margin-top: 24px;
  margin-bottom: 91px;
  height: 64px;
  width: 100%;
  color: #000000;
  background: linear-gradient(135.37deg, rgba(0, 0, 0, 0.4) 4.29%, rgba(255, 255, 255, 0.4) 95.6%), #ebecf0;
  background-blend-mode: soft-light, normal;
  /* Neumorphic light/Stroke/White */

  border: 2px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: inset -5px -5px 10px #fafbff, inset 5px 5px 10px rgba(166, 171, 189, 0.5);
  border-radius: 8px;

  padding: 0px 16px 0px 26px;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
`

export const TeamCategoriesResponsiveArrow = styled.img`
  width: 20px;
  height: 12px;
  position: absolute;
  top: 52px;
  right: 26px;
`

export const TeamCategory = styled.div<{ active: boolean }>`
  padding: 10px 20px;
  cursor: pointer;
  ${(props) =>
    props.active &&
    css`
      font-weight: 500;
      background: ${({ theme }) => theme.backgroundColor};
      background-blend-mode: soft-light, normal;
      box-shadow: inset -5px -5px 10px ${({ theme }) => theme.primaryShadowColor},
        inset 5px 5px 10px ${({ theme }) => theme.secondaryShadowColor};
      border-radius: 20px;
    `};
`

export const TeamAnchorContainer = styled.div`
  position: relative;
`

export const TeamAnchor = styled.div`
  height: 1px;
  position: absolute;
  top: -132px;
  left: 0;
  right: 0;
`
