export enum Category {
    Founders = 'founders',
    Blockchain = 'blockchain',
    ImpactAssessment = 'impactAssessment',
    Growth = 'growth',
    Product = 'product',
    Advisors = 'advisors',
    Ambassadors = 'ambassadors',
}

export type Person = {
    name: string
    slug: string
    category: Category
    function: string
    linkedin: string
    image: string
    bio: string
}