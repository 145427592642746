import { useSelector } from 'react-redux'
import { State } from 'reducers'

import { partners } from '../../../common/partners'
import { Partner, PartnersStyled } from './Partners.style'

export const Partners = () => {
  const isDarkMode = useSelector((state: State) => state.darkMode)

  return (
    <PartnersStyled>
      {partners.map((partner) => (
        <Partner key={partner.id} isDarkMode={isDarkMode}>
          <img src={isDarkMode ? partner.imageDark : partner.image} alt="" />
          <div>{partner.description}</div>
        </Partner>
      ))}
    </PartnersStyled>
  )
}
