import styled from 'styled-components/macro'

export const PartnersStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -10px;
  margin-right: -10px;
`

export const Partner = styled.div<{ isDarkMode: boolean }>`
  background-color: ${({ theme }) => theme.backgroundColor};
  background-blend-mode: soft-light, normal;
  border: ${({ isDarkMode }) => (!isDarkMode ? '2px solid rgba(255, 255, 255, 0.4)' : 'none')};
  box-sizing: border-box;
  box-shadow: -10px -10px 20px ${({ theme }) => theme.primaryShadowColor},
    10px 10px 20px ${({ theme }) => theme.secondaryShadowColor};
  border-radius: 8px;
  padding: 10px;
  height: 146px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-basis: calc(100% / 4 - 20px);
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;

  img {
    width: 80%;
    height: 48px;
    object-fit: contain;
    margin-bottom: 16px;
  }

  > div {
    text-align: center;
  }
  @media (max-width: 1200px) {
    flex-basis: calc(100% / 3 - 20px);
  }

  @media (max-width: 900px) {
    flex-basis: calc(100% / 2 - 20px);
  }

  @media (max-width: 700px) {
    flex-basis: 100%;
  }
`
