import styled from 'styled-components/macro'

export const UtilityStyled = styled.div<{ isDarkMode: boolean }>`
  background-color: ${({ theme }) => theme.backgroundColor};
  background-blend-mode: soft-light, normal;
  border: ${({ isDarkMode }) => (!isDarkMode ? '1px solid rgba(255, 255, 255, 0.4)' : 'none')};
  box-sizing: border-box;
  box-shadow: -5px -5px 10px ${({ theme }) => theme.primaryShadowColor},
    5px 5px 10px ${({ theme }) => theme.secondaryShadowColor};
  border-radius: 12px;
  padding: 20px;
`

export const UtilityIcon = styled.img`
  width: 48px;
  height: 48px;
  margin-right: 22px;
`

export const UtilityTitle = styled.div`
  padding-bottom: 16px;
  margin-bottom: 24px;
  font-weight: 600;
  border-bottom: 1px solid ${({ theme }) => theme.selectedColor};
  display: flex;
  align-items: center;
`

export const UtilityLegend = styled.span`
  font-weight: 600;
  font-size: 12px;
`

export const UtilityPoint = styled.span`
  display: block;
  margin-bottom: 16px;
  font-size: 14px;

  &:last-child {
    margin-bottom: 0;
  }
`
