import styled from 'styled-components/macro'

export const FooterStyled = styled.footer`
  background-color: ${({ theme }) => theme.footerColor};
  color: ${({ theme }) => theme.backgroundTextColor};
  padding: 40px 80px;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-gap: 150px;

  @media (max-width: 1025px) {
    grid-gap: 50px;
  }
  @media (max-width: 1023px) {
    display: block;
    padding: 40px;
    a {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media (max-width: 321px) {
    padding: 40px 20px;
  }
`

export const FooterCol = styled.div<{ isDarkMode: boolean }>`
  > a {
    color: ${({ theme }) => theme.backgroundTextColor};
    text-decoration: underline;
  }

  &:nth-child(1) {
    display: flex;
    img {
      align-self: center;
      width: 230px;
      @media (min-width: 1440px) {
        width: 230px;
      }
    }
  }

  &:nth-child(2) {
    text-align: center;
    line-height: 22px;
  }

  &:nth-child(3) {
    div {
      display: flex;
      justify-content: right;

      &:last-child {
        margin-top: 16px;
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${({ isDarkMode }) => (!isDarkMode ? '#24317b' : 'rgba(255, 255, 255, 0.1)')};
      width: 40px;
      height: 40px;
      border-radius: 40px;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }

      img {
        width: 24px;
        border-radius: 2px;
      }
    }
  }

  @media (max-width: 900px) {
    &:nth-child(2),
    &:nth-child(3) {
      margin-top: 20px;
      text-align: left;
    }

    &:nth-child(3) {
      div {
        justify-content: left;
      }
    }
  }
`
