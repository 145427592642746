import { GET_ICO_STATE } from 'app/App.actions'
export type icoStateState = number

const icoStateInitialState: icoStateState = 0

export function icoState(state = icoStateInitialState, action: any) {
  switch (action.type) {
    case GET_ICO_STATE: {
      return action.state;
    }
    default:
      return state;
  }
}
