import styled from 'styled-components/macro'

export const GetClapStyled = styled.div``

export const GetClapTxt = styled.div`
  margin-top: 24px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  text-align: center;
  letter-spacing: -0.02em;

  /* Neumorphic light/Filled/Blanc */

  color: #ffffff;
`

export const GetClapButtonsContainer = styled.div`
  margin-top: 27px;
  margin-bottom: 19px;
  display: grid;
  width: 500px;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
  justify-content: center;
  padding: 0px 60px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    width: 100%;
  }
`

export const GetClapButton = styled.a`
  text-align: center;

  background: #ebecf0;
  /* Neumorphic light/Stroke/White */

  border: 2px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: -10px -10px 20px rgba(8, 21, 45, 0.2), 5px 5px 15px rgba(5, 12, 27, 0.4);
  border-radius: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  height: 70px;
  > img {
    width: 100%;
    padding: 14px 20px;
  }
`

export const LearHowToGetClapContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
`
