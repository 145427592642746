import { ReactNode } from 'react'

import { ButtonStyled } from './Button.style'

interface ButtonProps {
  appearance?: 'primary' | 'secondary' | 'tertiary' | 'fourth'
  children: ReactNode
  clickCallback?: () => void
  icon?: string
  withIcon?: boolean
  width?: string
  withShadow?: boolean
}

export const Button = ({
  children,
  clickCallback = () => {},
  appearance = 'primary',
  icon = 'arrow',
  withIcon = true,
  width = 'fit-content',
  withShadow = true,
}: ButtonProps) => {
  let iconObj = (
    <svg>
      <use xlinkHref={`/icons/sprites.svg#${icon}`} />
    </svg>
  )
  if (icon.split('.').length > 1) {
    iconObj = <img src={'icons/' + icon} alt="" style={{ height: 26, width: 26 }} />
  }
  return (
    <ButtonStyled onClick={() => clickCallback()} appearance={appearance} width={width} withShadow={withShadow}>
      {children}
      {withIcon && iconObj}
    </ButtonStyled>
  )
}
